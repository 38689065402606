<app-date-field [ngModel]="bunkerVisit.etc" id="bunker-etc-{{bunkerVisit.bunkeringId}}"
                readonly="true"></app-date-field>
<div *ngIf="possibleDelay(bunkerVisit, berthVisit)" style="text-align: end">
  <span class="fa fa-warning" style="color: orange;"></span>
  ETC within 30 min of ETD, may cause delay
</div>
<div *ngIf="certainDelay(bunkerVisit, berthVisit)" style="text-align: end">
  <span class="fa fa-warning" style="color: orange;"></span>
  ETC after ETD, bunkering will cause delay
</div>
