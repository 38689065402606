<div *ngIf="movement">
  <div *ngIf="harbourMasterRemarks">
    <app-status-alert type="info">Harbour master remarks: <i>{{harbourMasterRemarks}}</i></app-status-alert>
  </div>
  <app-ekh-clearance [arrivalBerthVisitId]="getNextBerthVisitId()" [departureBerthVisitId]="getPreviousBerthVisitId()"></app-ekh-clearance>
  <div class="row">
    <!-- COLUMN 1 -->
    <div class="col-md">
      <!--Vessel draft-->
      <div *ngIf="context.exceedsVesselsDraught(movement.vesselDraft)">
        <app-status-alert type="warning">Vessel draft can not exceed {{ context.getVesselsDraught() }}m.
        </app-status-alert>
      </div>
      <div class="validatable">
        <app-form-group *ngIf="context.visit.portOfCall.paDeclarationRequired">
          <label [attr.for]="id + '-vesselDraft'"
                 [ngClass]="{'required': (isFirstMovement || hasActiveOrder) && !context.hasDeparted()}">Vessel
            draft</label>
          <div class="input-group">
            <input *ngIf="!berthVisitInfo?.harbourMasterInfo?.vesselDraught" [(ngModel)]="movement.vesselDraft"
                   [maxDecimals]="2" [required]="(isFirstMovement || hasActiveOrder) && !context.hasDeparted()"
              (ngModelChange)="onVesselDraftChanged()" [id]="id + '-vesselDraft'" appDecimalNumber class="form-control">
            <input *ngIf="!!berthVisitInfo?.harbourMasterInfo?.vesselDraught" readonly
                   [ngModel]="berthVisitInfo.harbourMasterInfo.vesselDraught" [appCompare]="movement.vesselDraft"
                   comparePreamble="Actual draft by HM. You reported: " appDecimalNumber class="form-control">
            <span class="input-group-text">m</span>
          </div>
          <span *ngIf="vesselDraftTooHigh()" class="ng-invalid invalid-feedback mb-2">Vessel draft must be less than 100
            m</span>
        </app-form-group>
      </div>
      <!--Vessel master-->
      <app-form-group>
        <label [attr.for]="id + '-vesselMasterName'"
          [ngClass]="{'required': (isFirstMovement || hasActiveOrder) && !context.hasDeparted()}">Vessel master</label>
        <input [(ngModel)]="movement.vesselMasterName"
               [required]="(isFirstMovement || hasActiveOrder) && !context.hasDeparted()" [id]="id + '-vesselMasterName'"
               type="text" maxlength="35" class="form-control">
      </app-form-group>
      <!--Number of crew-->
      <app-form-group>
        <label [attr.for]="id + '-numberOfCrew'"
          [ngClass]="{'required': (isFirstMovement || isLastMovement || hasActiveOrder) && !context.hasDeparted()}">Crew</label>
        <div class="input-group">
          <input [(ngModel)]="movement.numberOfCrew"
            [required]="(isFirstMovement || isLastMovement || hasActiveOrder) && !context.hasDeparted()"
            [id]="id + '-numberOfCrew'" type="number" step="1" class="form-control">
          <span class="input-group-text">person(s)</span>
        </div>
      </app-form-group>
      <!--Number of passengers-->
      <app-form-group>
        <label [attr.for]="id + '-numberOfPassengers'"
          [ngClass]="{'required': (isFirstMovement || hasActiveOrder) && !context.hasDeparted()}">Passengers</label>
        <div class="input-group">
          <input [(ngModel)]="movement.numberOfPassengers"
                 [required]="(isFirstMovement || hasActiveOrder) && !context.hasDeparted()" [id]="id + '-numberOfPassengers'"
                 type="number" step="1" class="form-control">
          <span class="input-group-text">person(s)</span>
        </div>
      </app-form-group>
    </div>

    <!-- COLUMN 2 -->
    <div class="col-md">
      <!--Cargo description-->
      <app-form-group>
        <label [attr.for]="id + '-cargo'"
          [ngClass]="{'required': (isFirstMovement || isLastMovement || hasActiveOrder) && !context.hasDeparted()}">Cargo</label>
        <app-select [(ngModel)]="movement.cargo" [addSelectedIfNotExists]="true"
                    [required]="(isFirstMovement || isLastMovement || hasActiveOrder) && !context.hasDeparted()" [options]="cargos"
                    [formatter]="cargoFormatter" [id]="id + '-cargo'"></app-select>
      </app-form-group>
      <!--Pilot Exemption Certificate (PEC) holder-->
      <div *ngIf="context.visit.portOfCall.paDeclarationRequired">
        <app-form-group *ngIf="!movement.pilotExemption || movement.pilotExemption.holderName">
          <app-info class="formGroupLabel">
            <label [attr.for]="id + '-pilotExemption-holder'">PEC holder</label>
            <div class="tooltipContent">Pilotage Exemption Certificate as registered by Port Authority</div>
          </app-info>
          <app-search [(ngModel)]="movement.pilotExemption" dataKey="holderName"
            [inputFormatter]="pilotExemptionInputFormatter" [searchFunction]="searchPilotExemptionHolder"
            [id]="id + '-pilotExemptionHolder'"></app-search>
        </app-form-group>
      </div>
      <!--Pilot Exemption Certificate (PEC) number-->
      <app-form-group *ngIf="!movement.pilotExemption || !movement.pilotExemption.holderName">
        <app-info class="formGroupLabel">
          <label [attr.for]="id + '-pilotExemption-number'">PEC number</label>
          <div class="tooltipContent">Pilotage Exemption Certificate number</div>
        </app-info>
        <input [ngModel]="movement.pilotExemption?.number"
          (ngModelChange)="movement.pilotExemption = $event ? {number : $event} : null"
          [id]="id + '-pilotExemption-number'" type="search" maxlength="10" class="form-control">
      </app-form-group>
      <!--Off standard dimensions-->
      <app-form-group>
        <label [attr.for]="id + '-offStandardBeam-value'">Off standard dimensions</label>
        <div class="input-group">
          <input [ngModel]="movement.offStandardBeam?.value" (ngModelChange)="onBeamValueChange($event)"
            [id]="id + '-offStandardBeam-value'" appDecimalNumber class="form-control">
          <span class="input-group-text">m</span>
        </div>
      </app-form-group>
    </div>

    <!-- COLUMN 3 -->
    <div class="col-md" *ngIf="context.visit.portOfCall.paDeclarationRequired">
      <!--Email for order notifications-->
      <app-form-group>
        <label [attr.for]="id + '-orderEmail'">Email
          <app-info [wide]="true">
            <span class="tooltipContent text-start">
              Email address for notification whenever one of these events occurs:
              <ul>
                <li>The incoming movement of the vessel has been automatically ordered</li>
                <li>The order of the movement has been confirmed</li>
                <li>The order of the movement has been rejected</li>
                <li>The Port Authority has made remarks about the movement of the vessel</li>
                <li>The Port Authority has set the order of the movement to 'On Hold'</li>
              </ul>
            </span>
          </app-info>
        </label>
        <input [value]="movement.orderEmail ? movement.orderEmail : emailAddressFromPreferences" [(ngModel)]="movement.orderEmail" [id]="id + '-orderEmail'" type="text" class="form-control">
      </app-form-group>
      <!--Sms for order notifications-->
      <app-form-group>
        <label [attr.for]="id + '-orderSms'">Phone
          <app-info [wide]="true">
            <span class="tooltipContent text-start">
              An SMS will be sent to this phone number whenever one of these events occurs:
              <ul>
                <li>The incoming movement of the vessel has been automatically ordered</li>
                <li>The order of the movement has been confirmed</li>
                <li>The order of the movement has been rejected</li>
                <li>The Port Authority has made remarks about the movement of the vessel</li>
                <li>The Port Authority has set the order of the movement to 'On Hold'</li>
              </ul>
            </span>
          </app-info>
        </label>
        <input [value]="movement.orderSms ? movement.orderSms : smsNumberFromPreferences" [(ngModel)]="movement.orderSms" [id]="id + '-orderSms'" type="text" pattern="^\+[1-9]\d{10,14}$"
          title="Phonenumber: e.g. +31612345678" class="form-control">
      </app-form-group>
      <!--Off standard dimensions remarks-->
      <div *ngIf="movement.offStandardBeam">
        <app-form-group>
          <label [attr.for]="id + '-offStandardBeam-remarks'">Off standard dimensions remarks</label>
          <textarea [ngModel]="movement.offStandardBeam.remarks"
            (ngModelChange)="movement.offStandardBeam.remarks = $event" [id]="id + '-offStandardBeam-remarks'"
            maxlength="512" class="form-control"></textarea>
        </app-form-group>
      </div>
    </div>
  </div>
</div>
