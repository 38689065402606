import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {AbstractValueAccessorComponent} from "../../component/value-accessor.component";
import {dispatchChangeEvent, uuid} from "../../utils";
import moment, {Moment, MomentInput} from "moment/moment";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {DatePickerComponent, DatePickerRange} from "../date-picker/date-picker.component";
import {roundToNearestQuarterMoment} from "../time-utils";
import {QuarterHourPickerComponent} from "../quarter-hour-picker/quarter-hour-picker.component";

@Component({
  selector: 'app-new-date-field',
  templateUrl: './new-date-field.component.html',
  styleUrls: ['./new-date-field.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NewDateFieldComponent), multi: true}
  ]
})
export class NewDateFieldComponent extends AbstractValueAccessorComponent<string> implements OnInit, AfterViewInit {
  _date: Moment;
  startDate: Moment;
  _calendarStartDate: DatePickerRange;
  _minDate: Moment;

  // The strings displayed in the input widgets
  // kept in sync with _date from [ngModel], input blur and pickers
  inputDate: string = "";
  inputTime: string = "";

  @Input() id = uuid();
  @Input() dateOnly;
  @Input() defaultTime: string = '00:00';
  @Input() noDefaultTime = false;
  @Input() disabled = false;
  @Input() required;
  @Input() yearSpan = 10;
  @Input() readonly;
  @Input() status : 'warning' | 'error';
  @Input() byTheMinute: boolean = false;

  @Input() set minDate(date: Moment | string) {
    this._minDate = typeof date === "string" ? moment(date) : date;
    this.calendarStartDate = date;
  }

  @Input() set calendarStartDate(value: MomentInput) {
    this.startDate = value ? moment(value) : moment();
    this.updateCalendarStartDate();
  }

  private setDate(date: Moment) {
    console.log("set date()", date);

    if (date != null && date.isValid()) {
      this._date = this.byTheMinute ? date : roundToNearestQuarterMoment(date);
      this.inputDate = this._date.format(this.dateFormat);
      this.inputTime = this._date.format(this.timeFormat);
    } else {
      this._date = null;
      this.inputDate = "";
      this.inputTime = "";
    }
    this.updateCalendarStartDate();
  }

  @Output() focus = new EventEmitter();
  @Output() blur = new EventEmitter();

  @ViewChild("picker") pickerEl: ElementRef;
  @ViewChild(DatePickerComponent, {static: false}) pickerComponent: DatePickerComponent;
  @ViewChild(QuarterHourPickerComponent, {static: false}) timePicker: QuarterHourPickerComponent;

  showPicker: boolean;
  private readonly dateFormat = "DD-MM-YYYY";
  private readonly timeFormat = "HH:mm";

  constructor(public elementRef: ElementRef, private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngAfterViewInit() {
    if (this.pickerEl) {
      $(this.pickerEl.nativeElement).on("show.bs.dropdown", () => {
        this.showPicker = true;
        this.pickerComponent?.updateCalendar();
        this.changeDetectorRef.markForCheck();
        return true;
      });
    }
  }

  scrollTimeIntoView() {
    this.timePicker.scrollIntoView();
  }

  updateCalendarStartDate = (): void => {
    this._calendarStartDate = {
      start: this._date || this.startDate,
      end: this._date || this.startDate
    }
  };

  onDatePickerChanged = ($event: DatePickerRange) => {
    let d = $event.start;
    this.updateDate(d);
    this.notifyListeners();
  }

  private updateDate(d: moment.Moment) {
    d = this.validateDate(d);
    if (d) {
      let time = this._date ? this._date : this.getDefaultTime();
      this.setDate(time.year(d.year()).month(d.month()).date(d.date()));
    } else {
      this.setDate(d);
    }
  }

  get hours(): number {
    return this.getTime().hours;
  }

  get minutes(): number {
    return this.getTime().minutes;
  }

  private getTime(): HoursMinutes {
    let time = this._date ? this._date : moment(this.noDefaultTime ? "00:00" : this.defaultTime, this.timeFormat);
    return {hours: time.hour(), minutes: time.minute()};
  }

  onTimePickerChanged($event: HoursMinutes) {
    if (this._date == null) {
      this.inputTime = "";
    } else {
      this.setDate(this._date.hours($event.hours).minutes($event.minutes));
      this.notifyListeners();
    }
  }

  onDateTyped = () => {
    if (this.inputDate == "") {
      this.setDate(null);
    } else {
      this.updateDate(moment(this.inputDate, this.dateFormat));
    }
    this.notifyListeners();
  };

  onTimeTyped = () => {
    if (this._date == null) {
      this.inputTime = "";
    } else {
      let time = this.getDefaultTime();
      this.setDate(this._date.hour(time.hour()).minute(time.minute()));
      this.notifyListeners();
    }
  };

  private getDefaultTime() {
    let input: string;
    if (this.isDateWithoutTime()) {
      input = "00:00";
    } else if (!this.noDefaultTime && this.inputTime == "") {
      input = this.defaultTime;
    } else {
      input = this.inputTime;
    }
    return moment(input, this.timeFormat);
  }

  private isDateWithoutTime() {
    return this.dateOnly && this.noDefaultTime;
  }

  private notifyListeners() {
    this.onModelChange();
    dispatchChangeEvent(this.elementRef.nativeElement);
  }

  getDate() {
    return this._date;
  };

  get value(): string {
    if (!this._date) return null;

    return this.isDateWithoutTime()
      ? this._date.format('YYYY-MM-DD')
      : this._date.toISOString();
  }

  writeValue(value): void {
    this.changeDetectorRef.detectChanges();
    if (value) {
      const m = moment(value);
      this.setDate(m);
    }
  }

  private validateDate = (date: Moment): Moment => {
    if (!date || !date.isValid()) {
      return null;
    }
    if (this.beforeMinDate(date)) {
      date = this._minDate;
    }
    if (!this.fitsInYearSpan(date)) {
      date = null;
    }
    return date;
  };

  private beforeMinDate(date: Moment) {
    return this._minDate && date.isBefore(this._minDate);
  }

  private fitsInYearSpan = (m: Moment) => m != null && m.isValid() && m.isBetween(moment().subtract(this.yearSpan, 'years'), moment().add(this.yearSpan, 'years'));

  ngOnInit(): void {
    if (this.required === '') {
      this.required = true;
    }
    if (this.readonly === '') {
      this.readonly = true;
    }
  }

  inlineDate() {
    return this._date ? this._date.format("DD MMM [’]YY") : "";
  }

  inlineTime() {
    return this._date ? this._date.format(this.timeFormat) : "";
  }
}

export interface HoursMinutes {
  hours: number;
  minutes: number;
}
