<div class="row sticky-top bg-white mb-3" style="top:6.125rem">
  <div class="col-md-12">
    <app-alerting></app-alerting>
  </div>
</div>

<div class="row narrow-gutters always-enabled mb-1 g-2">
  <div class="col-auto">
    <button class="btn"
            [ngClass]="transitFilter.selectedOptions.length > 0 || transitFilter.deselectedOptions.length > 0 ? 'btn-danger' : 'btn-outline-info'"
            (click)="toggleFilter()">
      <span class="fa fa-filter m-0"></span>
    </button>
  </div>
  <div class="col col-md-auto ps-2 pe-2">
    <app-select [options]="transitDateFilterOptions" [(ngModel)]="transitDateFilterType"
                [formatter]="transitDateFilterTypeFormatter"
                (ngModelChange)="onDateTypeChanged($event)">
    </app-select>
  </div>
  <div class="col-3 col-md-auto pe-4" style="min-width: 19rem; height: 35px;">
    <app-date-range (ngModelChange)="onDateSelection($event)" [ngModel]="dateRange" [minDate]="'2023-01-01'">
    </app-date-range>
  </div>
  <div class="col-5 col-md pe-2">
    <app-filter (resultsFound)="renderFilteredItems()" [(ngModel)]="filterTerm" [searchFunction]="reload"
                [showTooltip]="true"
                placeholder="Filter by mrn, container nr, etc..." [appAutoFocus]="true"></app-filter>
  </div>
  <div class="col-auto">
    <div class="dropdown">
      <button class="btn btn-plain text-primary d-inline-flex align-items-center py-1 p-md-2 p-1" data-bs-toggle="dropdown" aria-expanded="false">
        <div class="d-none d-md-block">
          {{sortingFormatter(sortBy)}}
          <div class="d-inline-block"><i class="fa fa-fw fa-light fa-chevron-down ms-1"></i></div>
        </div>
        <div class="d-block d-md-none">
          <div class="d-inline-block">
            <i class="fa fa-fw fa-light fa-long-arrow-up"></i>
            <i class="fa fa-fw fa-light fa-long-arrow-down" [style.margin-left.rem]="-0.7"></i>
          </div>
        </div>
      </button>
      <ul class="dropdown-menu">
        <ng-container *ngFor="let property of sortingProperties">
          <li>
            <button class="dropdown-item d-flex justify-content-between px-3"
                    [ngClass]="sortBy === property ? 'text-primary' : ''"
                    (click)="changeSorting(property)">
              <span>{{sortingFormatter(property)}}</span>
              <ng-container *ngIf="sortBy === property">
                <i class="fa fa-fw fa-light fa-check"></i>
              </ng-container>
            </button>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  <div class="col-auto" *ngIf="appContext.isTransitEditor() || appContext.isAdmin()">
    <div class="btn-group">
      <button class="btn btn-info" data-bs-toggle="modal" data-bs-target="#newContainerManifestDeclaration">
        <span class="pe-2 fa fa-plus-circle"></span>New container manifest declaration
      </button>
      <button type="button" class="btn btn-info dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown"></button>
      <div class="dropdown-menu dropdown-menu-end">
        <button class="dropdown-item" tabindex="-1" data-bs-toggle="modal"
                data-bs-target="#newManifestDeclarationContainerImport">New container manifest declaration (Excel import)
        </button>
        <button class="dropdown-item" tabindex="-1" data-bs-toggle="modal"
                data-bs-target="#newBulkManifestDeclaration">New bulk manifest declaration
        </button>
        <button (click)="addNewContainerDeclaration()" class="dropdown-item" tabindex="-1"
                style="border-top: 1px solid #dee2e6;">New blank container
          declaration
        </button>
        <button (click)="addNewBulkDeclaration()" class="dropdown-item" tabindex="-1">New blank bulk declaration
        </button>
      </div>
    </div>
  </div>
</div>
<div class="row narrow-gutters my-3" [hidden]="!showFilter">
  <app-transit-filter class="px-0" (changed)="applyTransitFilters($event)" [transitDeclarations]="getFilteredItems()"></app-transit-filter>
</div>

<ng-container *ngIf="visibleDeclarations as declarations">
  <app-pagination #pagination [items]="transitDeclarations"
                  [class]="'accordion ' + (selectedItems.length > 0 ? 'items-selected' : '')" id="transitAccordion">
    <ng-container *ngFor="let declaration of pagination.getItemsOnPage(); let index = index; trackBy : trackByLrn;">

      <ng-container *ngIf="declaration.terminal && (index === 0)">
        <div class="mt-3 row g-0 align-items-center">
          <div class="col-auto me-2 invisible">
            <div class="form-check form-check-inline m-0" style="height: 2.5rem">
              <input class="form-check-input m-0" type="checkbox"></div>
          </div>
          <div class="col py-1 ps-3 border border-white" style="border-width: 2px!important;">
            <span class="fa fa-fw fa-map-marker-alt text-portbase-blue pe-2 me-2"></span>
            <span class="ms-1 fw-bold text-dark"></span>
          </div>
        </div>
      </ng-container>

      <div class="row g-0">
        <div class="col-auto me-2">
          <div class="form-check form-check-inline m-0 always-enabled" style="height: 2rem">
            <input [(ngModel)]="declaration['selected']" class="always-enabled form-check-input m-0" type="checkbox"></div>
        </div>
        <div class="col">
          <app-collapse #transitCollapse [collapse]="collapz" [showOnLoad]="!declaration.status.phase"
                        [deleteHandle]="!declaration.status.phase && removeNewDeclaration(declaration)">
            <div class="row g-0 py-1 px-3 align-items-center text-dark">
              <div class="col-2">
                {{declaration.status.phase ? (declaration.data.containerNumber || 'bulk') : 'New declaration'}}
              </div>
              <div class="col-1 small text-secondary cut-text" style="min-width: 12rem" [title]="declaration.data.dischargeTerminal ?
        declaration.data.dischargeTerminal?.terminalCode + ' - ' + declaration.data.dischargeTerminal?.terminalName :
        declaration.data.departureOffice?.unCode + ' - ' + declaration.data.departureOffice?.name">
                <span class="fa fa-sign-out-alt"></span>
                {{declaration.data.dischargeTerminal ? declaration.data.dischargeTerminal?.terminalName : declaration.data.departureOffice?.name}}
              </div>
              <div class="col-1 small text-secondary cut-text" style="min-width: 12rem"
                   [title]="declaration.data.destinationsOffice?.unCode + ' - ' + declaration.data.destinationsOffice?.name">
                <span class="fa fa-sign-in-alt"></span>
                {{declaration.data.destinationsOffice?.name}}
              </div>
              <div *ngIf="!declaration.status.inspectionStatus && !declaration.status.sendDate"
                   class="col-auto small text-secondary" style="min-width: 12rem">&nbsp;
              </div>
              <ng-container *ngIf="declaration.status.release">
                <div class="col-auto small text-secondary" style="min-width: 12rem"
                     [title]="determineStatusTooltip(declaration.status)">
                  <span>
                    {{declaration.status.sent?.date || declaration.status.sendDate | date:'dd-MM-YYYY'}}
                    / {{declaration.transportExpiryDate | date:'dd-MM-YYYY'}}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngIf="!declaration.status.release">
                <div *ngIf="!!declaration.status.inspectionStatus || !!declaration.status.sendDate"
                     class="col-auto small text-secondary" style="min-width: 12rem"
                     [title]="determineStatusTooltip(declaration.status)">
                  <span *ngIf="declaration.status.sendOnAta && !declaration.status.sent?.date">
                    on ATA / + {{transportTermToDays(declaration.data.transportTerm)}} days
                  </span>
                  <span *ngIf="declaration.status.sent?.date">
                    {{declaration.status.sent.date | date:'dd-MM-YYYY'}}
                    / {{calculateTransportExpiryDate(declaration.status.sent.date, declaration.data.transportTerm)}}
                  </span>
                  <span *ngIf="!declaration.status.sent?.date && declaration.status.sendDate">
                      {{declaration.status.sendDate | date:'dd-MM-YYYY'}}
                    / {{calculateTransportExpiryDate(declaration.status.sendDate, declaration.data.transportTerm)}}
                  </span>
                </div>
              </ng-container>
              <div *ngIf="!declaration.status.mrn" class="col-auto small text-secondary" style="min-width: 10rem">&nbsp;
              </div>
              <div *ngIf="!!declaration.status.mrn" class="col-auto small text-secondary" style="min-width: 10rem">
                {{declaration.status?.mrn}}
              </div>
              <div class="col-auto d-none d-md-block small text-secondary" style="min-width: 7rem"
                   *ngIf="appContext.isAdmin()">
                <span class="fa fa-fw fa-users"></span>
                <span class="ms-1">{{declaration.declarantShortName}}</span>
              </div>
              <div class="text-end col d-none d-md-block text-dark">
                <app-transit-summary [setModel]="declaration"
                                     [keepTooltipOpen]="transitCollapse.showing"></app-transit-summary>
              </div>
            </div>
          </app-collapse>
        </div>
      </div>

      <ng-template #collapz>
        <div class="collapse" data-bs-parent="#transitAccordion">
          <div class="py-3 ms-2">
            <app-transit-declaration [declaration]="declaration"  [ngClass]="{'disabled': !appContext.isTransitEditor()}"></app-transit-declaration>
          </div>
        </div>
      </ng-template>

    </ng-container>

    <div class="row g-0 text-secondary">
      <div class="col-auto" *ngIf="declarations.length > 1">
        <label class="mt-3">
          <input [(ngModel)]="declarations.length === selectedItems.length"
                 (click)="toggleSelectAll()" class="always-enabled" type="checkbox">
          <span class="ms-2">Select all</span>
        </label>
      </div>
    </div>
  </app-pagination>

  <div *ngIf="visibleDeclarations.length === 0" class="text-center text-muted py-2">
    <ng-container *ngIf="items.length > 0; else noReleasesYet">
      No matching declarations found.
    </ng-container>
    <ng-template #noReleasesYet>You haven’t added any declarations yet.</ng-template>
  </div>
</ng-container>


<div id=newContainerManifestDeclaration class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
     role="dialog">
  <app-copy-containers-from-consignments [existingDeclarations]="items"></app-copy-containers-from-consignments>
</div>

<div id=newBulkManifestDeclaration class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
     role="dialog">
  <app-copy-bulk-from-consignments [existingDeclarations]="items"></app-copy-bulk-from-consignments>
</div>

<div id=newManifestDeclarationContainerImport class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false"
     tabindex="-1" role="dialog">
  <app-transit-declarations-from-containers [existingDeclarations]="items"></app-transit-declarations-from-containers>
</div>

<div id=preselectedDeclaration class="modal fade" data-bs-backdrop="static" tabindex="-1" role="dialog">
  <app-batch-transit-declaration [items]="preselectedItems"></app-batch-transit-declaration>
</div>


<ng-container *ngIf="selectedItems.length > 0">
  <div class="fixed-bottom row g-0 justify-content-center bg-white py-3 border-top">
    <div class="col-auto mx-1">
      <button class="btn btn-outline-info mx-1" (click)="printSelected(true)" style="min-width: 10rem" type="button">
        Print
      </button>
      <button class="btn btn-outline-info mx-1" (click)="printSelected(false)" style="min-width: 10rem" type="button">
        Print copy
      </button>
      <button class="btn btn-outline-info mx-1" (click)="downloadCsv()" style="min-width: 10rem" type="button">Download
        CSV
      </button>
    </div>
  </div>
</ng-container>
