import {Component, Input} from '@angular/core';
import {IE3ConsignmentProcess} from "@portbase/bezoekschip-service-typescriptmodels";
import {ConsignmentUtils} from "../../consignment.utils";

@Component({
  selector: 'app-consignment-details-alerts',
  templateUrl: './consignment-details-alerts.component.html',
  styleUrls: ['./consignment-details-alerts.component.scss']
})
export class ConsignmentDetailsAlertsComponent {
  consignmentProcess: IE3ConsignmentProcess;
  errors: string[];

  @Input("consignmentProcess")
  set setConsignmentProcess(value: IE3ConsignmentProcess) {
    this.consignmentProcess = value;
    if (value) {
      this.errors = ConsignmentUtils.getMessagesEns(value.ensTaskStatus, value.status)
        .map(s => `ENS: ${s}`)
        .concat(ConsignmentUtils.getTemporaryStorageStatusMessage(value.status?.filingStatusTemporaryStorage)
          .map(s => `Temporary storage: ${s}`));
    }
  }
}
