import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  TemplateRef
} from '@angular/core';
import lodash from "lodash";
import {isDefined} from "../utils";

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent implements AfterViewInit, OnDestroy {

  @Input() label: String;
  @Input() tooltip: String;
  @Input() tooltipTemplateRef: TemplateRef<any>;
  @Input() renderOptional: boolean = true;
  @Input() customClass: string;
  @Input() includeMargin: boolean = true;
  @Input() formatter;
  @Input() emptyReadOnlyValue: string;
  @Input() truncateReadOnly: boolean;
  @Input() separator: string = ", ";
  @Input() compareHeader = "Actual value: ";
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() compareMethod: (value: any, compare: any) => boolean = (value: any, compare: any) =>
    lodash.upperCase(this.getValueFormatted(value)) !== lodash.upperCase(this.getValueFormatted(compare))

  registration : () => void;

  value: any;
  compare: any;

  required: boolean;
  protected _editMode: boolean = true;

  constructor(public element: ElementRef, private ngZone: NgZone) {
  }

  @Input("value")
  set setValue(value: any) {
    this.value = value;
  }

  @Input("compare")
  set setCompare(compare: any) {
    this.compare = compare;
  }

  @Input()
  set editMode(editMode: boolean) {
    this._editMode = editMode;
    setTimeout(() => this.refreshOptionalLabel(), 0);
  }

  protected useCompareValue = () => {
    this.valueChange.emit(this.compare);
    this.element.nativeElement.dispatchEvent(new Event('change', { bubbles: true }));
  }

  get hasValue() {
    return !lodash.isNull(this.value) && !lodash.isUndefined(this.value);
  }

  get readOnlyValue() {
    return this.valueFormatted || this.emptyReadOnlyValue || `–`;
  }

  ngAfterViewInit(): void {
    const el = this.getChildElement();
    this.refreshOptionalLabel();
    if (el) {
      const labels = this.getLabels();
      if (!this.element.nativeElement.querySelector('.invalid-feedback')) {
        const feedbackElement = document.createElement('div');
        el.parentElement.appendChild(feedbackElement);
        feedbackElement.outerHTML = '<div class="invalid-feedback">Please enter ' + (labels ? renderLabel(labels) : 'a value') + '</div>';
      }
    }

    const changeListener = (e) => {
      if (e.some(a => a.attributeName == 'required')) {
        this.refreshOptionalLabel();
      }
    };

    this.ngZone.runOutsideAngular(() => {
      const observer = new MutationObserver(changeListener);
      observer.observe(this.element.nativeElement, {attributes: true, subtree: true});
      this.registration = () => observer.disconnect();
    })

    function renderLabel(labels: NodeList): string {
      let name: string = "";
      labels.forEach(node => name += node.textContent + " and ");
      name = name.slice(0, name.length - 4).toLowerCase();
      switch (name.charAt(0)) {
        case 'a':
        case 'e':
        case 'i':
        case 'o':
        case 'u':
          return 'an ' + name;
      }
      return 'a ' + name;
    }
  }

  ngOnDestroy(): void {
    if (this.registration) {
      this.registration();
    }
  }

  private refreshOptionalLabel = () => {
    const el = this.getChildElement();
    const labels = this.getLabels();
    if (this._editMode && el?.hasAttribute('required')) {
      this.required = true;
      labels.forEach(label => label.classList.add('required'));
    } else {
      this.required = false;
      labels.forEach(label => label.classList.remove('required'));
    }
  };

  private getLabels() {
    return this.element.nativeElement.querySelectorAll('label:not(.btn):not(app-radio label)');
  }

  private getChildElement() {
    return this.element.nativeElement.querySelector('textarea, app-date-field, app-radio, app-multiselect, input, select, app-toggle');
  }

  get compareDifferent() {
    if (!isDefined(this.compare)) {
      return false;
    }
    return this.compareMethod(this.value, this.compare);
  }

  get compareValue() {
    return this.getValueFormatted(this.compare);
  }

  get valueFormatted() {
    return this.getValueFormatted(this.value);
  }

  getValueFormatted(value: string) {
    if (typeof value === "boolean") {
      return this.formatter ? this.formatter(value) : (value ? "Yes" : this.emptyReadOnlyValue || "No");
    }
    return value ? this.formatter ? this.formatter(value) : value : null;
  }
}

