<div class="row">
  <div class="col-md-6">
    <app-form-group>
      <label>Select tanks</label>
      <app-multiselect [(ngModel)]="handling.tankNumbers" [options]="handling.availableTanks"
                       [hideSelected]="true" [enableSelectAll]="true" [autoSelectOnlyOption]="true"
                       placement="top-end" title="Select tanks"></app-multiselect>
    </app-form-group>
    <app-form-group>
      <label>Start date and time</label>
      <app-date-field [ngClass]="{'was-validated': startDateEntered}" required
                     [(ngModel)]="handling.timestamp"
                     (blur)="blurStartDate()"></app-date-field>
    </app-form-group>
    <app-form-group *ngIf="washingBeforeArrivalAtPortOrBerth()" class="ng-invalid was-validated">
      <span *ngIf="washingBeforeArrivalAtPort()" class="ng-invalid invalid-feedback mb-2 mt-0">Washing start date
        should be after expected arrival visit
      </span>
      <span *ngIf="washingBeforeArrivalAtBerth()" class="ng-invalid invalid-feedback mb-2 mt-0">Washing start date
        should be after expected arrival at berth
      </span>
    </app-form-group>
    <ng-container *ngIf="context.startsHandlingAfterDepartureBerthOrPort(handling.timestamp, berthVisitEtd)">
      <div class="alert alert-warning show row align-items-center" role="alert">
        <div class="col-1 ps-2">
          <i class="fa fa-light fa-circle-exclamation" aria-hidden="true" style="font-size: xxx-large"></i>
        </div>
        <div class="col-11">
          <b>Are you sure?</b>
          <p *ngIf="afterEtdBerth()">Washings should preferably start before departure from berth.</p>
          <p *ngIf="!afterEtdBerth()">Washing should preferably start before departure visit.</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="col-md-6">
    <app-form-group>
      <label>Type</label>
      <app-radio required [(ngModel)]="handling.mediumOrCommercialWash"
                 (ngModelChange)="handling.ventilated = $event ? handling.ventilated : null"
                 [formatter]="formatter"
                 [options]="[false, true]" [inline]="true">
      </app-radio>
    </app-form-group>
    <app-form-group>
      <label>Destination</label>
      <app-select required [(ngModel)]="handling.otherDestinationTank"
                 (ngModelChange)="flushWashWater($event)"
                 [formatter]="otherDestinationsFormatter"
                 [options]="otherDestinations()">
      </app-select>
    </app-form-group>
    <app-form-group *ngIf="handling.otherDestinationTank && handling.otherDestinationTank!=='SAME_CARGO_TANK'">
      <label>Ventilated</label>
      <app-yes-no required [(ngModel)]="handling.ventilated"></app-yes-no>
    </app-form-group>
    <app-form-group>
      <label>Remarks</label>
      <textarea [(ngModel)]="handling.remarks" class="form-control"></textarea>
    </app-form-group>
  </div>
</div>
