<ng-container *ngIf="appContext.isSystemlink else portvisit">
  <router-outlet></router-outlet>
</ng-container>

<ng-template #portvisit>
  <div *ngIf="!env.maintenance" id='screen' [ngClass]="{'container-fluid px-0': !appContext.isNewRoute, 'new-look-and-feel': appContext.useNewLookAndFeel() }">
    <!--spinning wheel-->
    <div [hidden]="appContext.pendingProcesses.length === 0" class="position-fixed row h-100 w-100 m-0"
         style="z-index: 15000;">
      <div class="position-absolute bg-secondary h-100 w-100" style="opacity: 0.3"></div>
      <div class="spinner-border mx-auto text-secondary align-self-center" role="status"
           style="width: 5rem; height: 5rem; border-width: 1rem;"></div>
    </div>


    <div *ngIf="appContext.userProfile" [ngClass]="{'admin' : appContext.isAdmin(), 'd-flex flex-column': appContext.isNewRoute,
    'min-vh-100': appContext.isNewRoute && !appContext.routeData?.manualScrolling,
    'vh-100': appContext.isNewRoute && appContext.routeData?.manualScrolling}">
      <!-- Top navbar -->
      <div class="row bg-portbase-blue sticky-top mx-0" style="z-index: 1029;">
        <nav class="page-section col navbar navbar-dark">
          <div class="container-fluid">

            <!-- Portbase logo -->
            <a class="navbar-brand d-flex align-items-center" [attr.href]="s000Url" tabindex="-1">
              <img src="assets/logo-portbase.png" height="30" class="d-inline-block align-top" alt="Portbase Portal">
              <span class="d-none d-sm-inline ms-3 ps-3 border-start">Port Visit</span>
            </a>

            <!-- User profile dropdown (not displayed on mobile) -->
            <div class="btn-group d-none d-sm-block">
              <button class="btn text-white dropdown-toggle" type="button" data-bs-toggle="dropdown" tabindex="-1" id="user-button">
                {{appContext.userProfile.organisation?.contact}}
              </button>
              <div class="dropdown-menu dropdown-menu-end">
                <a [routerLink]="['/preferences']" class="dropdown-item" href="#">Preferences</a>
                <a *ngIf="appContext.isAdmin() || appContext.isCargoImportEditor()" [routerLink]="['/licensebook']"
                   class="dropdown-item">Warehouse licences</a>
                <a *ngIf="!cargoScreenEnabled" [routerLink]="['/addressbook']" class="dropdown-item">Address book</a>
                <a *ngIf="appContext.isTransitViewer()" [routerLink]="['/guarantees']" class="dropdown-item">
                  Transit guarantees</a>
                <a *ngIf="appContext.isTransitViewer()" [routerLink]="['/transitTemplates']" class="dropdown-item">
                  Transit templates</a>
                <a *ngIf="appContext.isTransitViewer()" [routerLink]="['/transitAddressBook']" class="dropdown-item">
                  Transit destinations</a>
                <a *ngIf="appContext.isCommercialReleaseDeclarant() || appContext.isCommercialReleaseEditor() ||
                appContext.isCommercialReleaseViewer()" [routerLink]="['/releaseToParties']" class="dropdown-item">
                  Release-to parties</a>
                <div class="dropdown-divider"></div>
                <ng-container *ngIf="appContext.isAdmin()">
                  <a [routerLink]="['/admin/panel']" class="dropdown-item">Admin panel</a>
                  <a [routerLink]="['/admin/monitoring']" class="dropdown-item">Monitoring</a>
                  <a [routerLink]="['/commercial-release-dashboard']" class="dropdown-item">
                    Commercial release dashboard</a>
                  <a [routerLink]="['/encrypt']" class="dropdown-item">Encrypt</a>
                  <div class="dropdown-divider"></div>
                </ng-container>
                <a href="#" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#logOutModal">Log out</a>
                <div *ngIf="appContext.environment === 'localhost'" class="dropdown-divider"></div>
                <div class="dropdown-item" *ngIf="appContext.environment === 'localhost'">
                  <app-user-menu></app-user-menu>
                </div>
                <div *ngIf="appContext.isAdmin()" class="dropdown-divider"></div>
                <div class="dropdown-item">
                  <div *ngIf="appContext.showNewLookAndFeelSwitch()" class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="useNewLookAndFeelSwitch"
                           [(ngModel)]="useNewLookAndFeel" (ngModelChange)="changeLookAndFeel($event)">
                    <label class="form-check-label pt-1" for="useNewLookAndFeelSwitch">New look and feel</label>
                  </div>
                </div>
                <div class="border-top border-dark col mt-2 d-sm-none" style="min-width:20rem;" *ngIf="visit && !visitContext.isOrganisationOnlyCargoDeclarant() && !appContext.isCustoms()">
                  <app-confirmation-of-purchase></app-confirmation-of-purchase>
                  <app-notification-of-departure></app-notification-of-departure>
                </div>
              </div>
            </div>

            <!-- mobile navbar buttons -->
            <div class="d-sm-none">
              <!-- feedback component -->
              <app-feedback></app-feedback>

              <!-- top menu dropdown -->
              <div class="btn-group">
                <button class="btn btn-outline-light rounded" type="button" data-bs-toggle="dropdown">
                  <span class="fa fa-bars"></span>
                </button>

                <div class="dropdown-menu dropdown-menu-end mobile-menu">
                  <a [routerLink]="['/']" *ngIf="appContext.isAllowedToViewVisits()" class="dropdown-item">Visits</a>
                  <a [routerLink]="['/newVisit']" *ngIf="appContext.isVisitDeclarant()" class="dropdown-item">
                    New visit</a>
                  <a [routerLink]="['/bunkering']" *ngIf="appContext.isBunkerOperator() || appContext.isAdmin()"
                     class="dropdown-item">Bunkering</a>
                  <a class="dropdown-item has-news beamer-button" data-bs-toggle="tab" role="tab" tabindex="-1">News</a>
                  <a href="#" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#logOutModal" tabindex="-1">
                    Log out</a>
                  <div class="border-top border-dark col mt-2" *ngIf="!visitContext.isOrganisationOnlyCargoDeclarant()
                  && !appContext.isCustoms()">
                    <app-confirmation-of-purchase></app-confirmation-of-purchase>
                    <app-notification-of-departure></app-notification-of-departure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <!-- Sub-navbar mobile (XS screen size) -->
      <nav *ngIf="!appContext.isNewRoute" class="navbar navbar-light bg-white d-block d-sm-none sticky-top pb-2 ps-0 mx-0" style="top: 3.5rem; z-index: 1028;">
        <div class="container-fluid px-0">
          <a class="navbar-brand" href="#">
            <app-vessel-details></app-vessel-details>
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
                  aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fa fa-caret-down" aria-hidden="true"></i>
          </button>
          <div class="collapse navbar-collapse" id="navbarText">
            <app-visit-status [mobile]="true"></app-visit-status>
          </div>
        </div>
      </nav>

      <!-- Sub-navbar desktop -->
      <div class="row bg-portbase-blue sticky-top mx-0" style="top: 3.5rem; z-index: 1028;">
        <nav class="page-section col vesselcall-nav">
          <div class="nav nav-tabs navbar-dark border-bottom-0" role="tablist">
            <!-- Everything from this point is not rendered on small screens -->
            <a [routerLink]="['/']" *ngIf="appContext.isAllowedToViewVisits()"
               [ngClass]="{'active': appContext.getUrlPath() === '/'}" id="visit_overview-link" data-bs-toggle="tab"
               role="tab" class="nav-item nav-link d-none d-sm-block" tabindex="-1">Visits</a>

            <a [routerLink]="['/newVisit']" *ngIf="appContext.isVisitDeclarant() && !appContext.useNewLookAndFeel()"
               [ngClass]="{'active': appContext.getUrlPath() === '/newVisit'}"
               class="nav-item nav-link d-none d-sm-block" id="new_visit-link" data-bs-toggle="tab" role="tab"
               tabindex="-1">New visit</a>

            <a [routerLink]="['/cargo']" *ngIf="cargoScreenEnabled"
               [ngClass]="{'active': appContext.getUrlPath() === '/cargo'}"
               class="nav-item nav-link d-none d-sm-block" id="cargo-link" data-bs-toggle="tab" role="tab"
               tabindex="-1">Cargo</a>

            <a [routerLink]="['/container']" *ngIf="cargoScreenEnabled"
               [ngClass]="{'active': appContext.getUrlPath() === '/container'}"
               class="nav-item nav-link d-none d-sm-block" id="container-link" data-bs-toggle="tab" role="tab"
               tabindex="-1">Containers</a>

            <a [routerLink]="['/transit']" *ngIf="appContext.isTransitViewer()"
               [ngClass]="{'active': appContext.getUrlPath() === '/transit'}"
               class="nav-item nav-link d-none d-sm-block"
               id="transit-link" data-bs-toggle="tab" role="tab" tabindex="-1">Transit</a>

          <a [routerLink]="['/transhipment']" *ngIf="appContext.isTranshipmentDeclarant() || appContext.isAdmin()"
             [ngClass]="{'active': appContext.getUrlPath() === '/transhipment'}"
             class="nav-item nav-link d-none d-sm-block"
             id="transhipment-link" data-bs-toggle="tab" role="tab" tabindex="-1">Transhipment</a>

          <a [routerLink]="['/commercial-release']"
             *ngIf="appContext.isCommercialReleaseEditor() || appContext.isCommercialReleaseViewer()"
             [ngClass]="{'active': appContext.getUrlPath() === '/commercial-release'}"
             class="nav-item nav-link d-none d-sm-block"
             id="commercial-release-link" data-bs-toggle="tab" role="tab" tabindex="-1">Commercial Releases</a>

            <a [routerLink]="['/message-overview']" *ngIf="appContext.isAdmin()"
               [ngClass]="{'active': appContext.getUrlPath() === '/message-overview'}"
               class="nav-item nav-link d-none d-sm-block" id="message-overview-link" data-bs-toggle="tab"
               role="tab" tabindex="-1">Outgoing</a>

            <a [routerLink]="['/waste']" *ngIf="appContext.isWasteCollector() || appContext.isAdmin()"
               [ngClass]="{'active': appContext.getUrlPath() === '/waste'}"
               class="nav-item nav-link d-none d-sm-block" id="waste-collector-link" data-bs-toggle="tab" role="tab"
               tabindex="-1">Waste collections</a>

            <a [routerLink]="['/bunkering']" *ngIf="appContext.isBunkerOperator() || appContext.isAdmin()"
               [ngClass]="{'active': appContext.getUrlPath() === '/bunkering'}"
               class="nav-item nav-link d-none d-sm-block" id="bunkering-link" data-bs-toggle="tab" role="tab"
               tabindex="-1">Bunkering</a>

            <a [routerLink]="['/refdata']" *ngIf="appContext.isAdmin()"
               [ngClass]="{'active': appContext.getUrlPath() === '/refdata'}"
               class="nav-item nav-link d-none d-sm-block" id="ref_data-link" data-bs-toggle="tab" role="tab"
               tabindex="-1">Reference data</a>

            <span class="ms-auto"></span>
            <div class="nav-item nav-link d-none d-sm-block has-news beamer-button" style="cursor: pointer;">
              News
            </div>

            <a [href]="appContext.supportPageForTask" [title]="appContext.supportPageTooltip"
               target="_blank" class="nav-item nav-link d-none d-sm-block" id="faq-link" role="tab"
               tabindex="-1">Support</a>

            <span class="d-none d-sm-block">
            <app-feedback></app-feedback>
          </span>
          </div>
        </nav>
      </div>

      <!-- body -->
      <ng-container *ngIf="appContext.isNewRoute else normalApplication">
        <main role="main" class="main d-flex align-self-stretch flex-grow-1" style="min-height: 0">
          <router-outlet></router-outlet>
        </main>
      </ng-container>
      <ng-template #normalApplication>
        <div class="page-section px-3">
          <router-outlet></router-outlet>
        </div>
      </ng-template>
    </div>
  </div>

  <div *ngIf="!env.maintenance" id="logOutModal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Log out</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
          <p>Are you sure you want to log out?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary" (click)="logOut()" data-bs-dismiss="modal">Log out</button>
        </div>
      </div>
    </div>
  </div>

  <app-news *ngIf="!env.maintenance" class="d-none"></app-news>
  <app-send-email-token-component *ngIf="!env.maintenance" [visible]="pcsDown"></app-send-email-token-component>
  <div *ngIf="env.maintenance" class="maintenance-wrapper row">
    <router-outlet></router-outlet>
  </div>
</ng-template>

<app-confirmation-modal></app-confirmation-modal>
<app-edit-modal></app-edit-modal>
