import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HoursMinutes} from "../new-date-field/new-date-field.component";
import moment from "moment";

@Component({
  selector: 'app-quarter-hour-picker',
  templateUrl: './quarter-hour-picker.component.html',
  styleUrls: ['./quarter-hour-picker.component.scss']
})
export class QuarterHourPickerComponent implements OnInit {

  @Input() id: string;
  @Input() hours: number;
  @Input() minutes: number;
  @Input() byTheMinute: boolean;

  @Output() timeChanged: EventEmitter<HoursMinutes> = new EventEmitter<HoursMinutes>();

  @ViewChild("nowButton") nowButton: ElementRef;

  allHours: string[] = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];

  get allMinutes() {
    if (this.byTheMinute) {
      let mins = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"];
      let chosen = (this.minutes < 10 ? "0" : "") + this.minutes;
      if (mins.indexOf(chosen) < 0) {
        mins.push(chosen);
      }
      return mins.sort();
    } else {
      return ["00", "15", "30", "45"];
    }
  };

  ngOnInit() {
    console.log("QHPC", this.hours, this.minutes);
  }

  selectHour(hour: string) {
    this.emitTime(hour, this.minutes);
  }

  selectMinute(minute: string) {
    this.emitTime(this.hours, minute);
  }

  now() {
    let now = moment();
    this.emitTime(now.hours(), now.minutes());
    this.scrollIntoView();
  }

  scrollIntoView() {
    window.setTimeout(() => {
      let smooth: ScrollIntoViewOptions = {block: "nearest", behavior: "smooth"};
      let hr = (this.hours < 10 ? "0" : "") + this.hours;
      document.getElementById("hr" + hr + "-" + this.id)?.scrollIntoView(smooth);
      let min = (this.minutes < 10 ? "0" : "") + this.minutes;
      document.getElementById("min" + min + "-" + this.id)?.scrollIntoView(smooth);
      this.nowButton.nativeElement.scrollIntoView(smooth);
    }, 100)
  }

  ok() {
    $(document.querySelectorAll(".time-dropdown")).removeClass("show")
  }

  private emitTime(hours, minutes) {
    this.timeChanged.emit(<HoursMinutes>{hours: hours, minutes: minutes});
  }
}
