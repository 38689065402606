import {Component, Input} from '@angular/core';
import {
  IE3ConsignmentProcessSummary,
  IE3ConsignmentsResult,
  IE3CustomsProcess,
  IE3CustomsStatus,
  IE3FindConsignments,
  Item,
  TransitDeclaration
} from "@portbase/bezoekschip-service-typescriptmodels";
import {AppContext} from "../../../app-context";
import {lodash, sendQuery, uniqueItems} from "../../../common/utils";
import {Observable} from "rxjs";
import {PortvisitUtils} from "../../../refdata/portvisit-utils";
import {map} from "rxjs/operators";
import {
  ContainerImportItem
} from "../transit-declarations-from-containers/transit-declarations-from-containers.component";
import {ComparatorChain} from "../../../common/comparator-chain";
import {getGoodsItems} from "../../transit.utils";

@Component({
  selector: 'app-copy-containers-from-consignments',
  templateUrl: './transit-copy-containers-from-consignments.component.html',
  styleUrls: ['./transit-copy-containers-from-consignments.component.css']
})
export class TransitCopyContainersFromConsignmentsComponent {
  appContext = AppContext;
  refData = PortvisitUtils;
  containers: ContainerEntity[] = [];
  searchTerm = ''
  selectedContainers: Item[] = [];

  @Input() existingDeclarations: TransitDeclaration[] = [];

  searchFunction = (searchTerm: string): Observable<ContainerEntity[]> => {
    return sendQuery('com.portbase.bezoekschip.common.api.consignments.queries.FindConsignments', <IE3FindConsignments>{
      term: searchTerm
    }).pipe(map((result: IE3ConsignmentsResult) => {
      const perContainer: Map<string, ContainerEntity> = new Map();
      lodash.flatMap(result.consignments, c => mapToContainerEntities(c))
        .filter(c => c.containerNumber)
        .filter(e => !this.existingDeclarations.some(d => d.status.phase !== "cancelled" &&
          d.status.phase !== "writtenOff" && d.data.containerNumber === e.containerNumber))
        .filter(e => e.consignmentProcessSummaries.some(c =>
          c.masterConsignment?.placeOfUnloading?.countryUnCode == null ||
          c.masterConsignment?.placeOfUnloading?.countryUnCode === "NL"))
        .forEach(containerEntity => {
          if (perContainer.has(containerEntity.containerNumber)) {
            perContainer.get(containerEntity.containerNumber).consignmentProcessSummaries
              .push(containerEntity.consignmentProcessSummaries[0]);
          } else {
            perContainer.set(containerEntity.containerNumber, containerEntity);
          }
        })

      return Array.from(perContainer.values());
    }));
  };

  getAllFunction = () => {
    return [] // We do not show anything if you have not entered a search term
  }

  private containerComparator = new ComparatorChain("containerNumber").compare;

  renderContainers = (containers: ContainerEntity[]) => {
    containers.sort(this.containerComparator)
    this.containers = this.selected().concat(containers.filter(
      container => this.selected().filter(
        selected => selected.containerNumber === container.containerNumber).length == 0));
  }

  trackByContainerNumber(index: number, obj: ContainerEntity): any {
    return obj.containerNumber;
  }

  selectContainers() {
    this.selectedContainers = this.selected().map(c => <Item>{
      crn: c.consignmentProcessSummaries[0].crn,
      containerNumber: c.containerNumber
    });
    this.reset();
  }

  selected(): ContainerEntity[] {
    return this.containers.filter(s => !!s['selected'] && !s['hidden']);
  }

  selectableContainers(): ContainerImportItem[] {
    return this.containers
      .filter(c => this.isSelectableContainer(c));
  }

  toggleSelectAll = () => {
    if (this.selected().length === this.selectableContainers().length) {
      this.selectableContainers().forEach(c => c['selected'] = false);
    } else {
      this.selectableContainers().forEach(c => c['selected'] = true);
    }
  };

  reset() {
    this.searchTerm = '';
    this.containers = [];
  }

  renderConsignmentNumbers(containerEntity: ContainerEntity): string {
    return containerEntity.consignmentProcessSummaries
      .map(consignmentProcessSummary => consignmentProcessSummary.masterConsignment.consignmentNumber).join(", ");
  }

  hasSelectedConsignmentWithWarning(): boolean {
    return lodash.flatMap(this.selected(), container => container.consignmentProcessSummaries)
      .map(consignmentProcessSummary => consignmentProcessSummary.masterConsignment)
      .some(consignment => !!consignment.customsStatus || !!consignment.customsProcess);
  }

  isSelectableContainer(containerEntity: ContainerEntity): boolean {
    return !containerEntity.consignmentProcessSummaries
      .map(consignmentProcessSummary => consignmentProcessSummary.masterConsignment)
      .some(consignment => consignment.customsStatus == IE3CustomsStatus.EU_COMMUNITY_GOODS_IN_TRANSHIPMENT
        || consignment.customsProcess == IE3CustomsProcess.SEA_IN_SEA_OUT);
  }
}

export interface ContainerEntity {
  consignmentProcessSummaries: IE3ConsignmentProcessSummary[];
  containerNumber: string;
}

export function mapToContainerEntities(consignment: IE3ConsignmentProcessSummary): ContainerEntity[] {
  const containerNumbers: string[] = uniqueItems(lodash.flatMap(getGoodsItems(consignment.masterConsignment),
    g => g && g.placements ? g.placements.map(p => p.containerIdentificationNumber) : []));

  if (containerNumbers.length == 0) {
    return [<ContainerEntity>{consignmentProcessSummaries: [consignment]}];
  } else {
    return containerNumbers.map(c => <ContainerEntity>{consignmentProcessSummaries: [consignment], containerNumber: c});
  }
}
