import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TransitDeclaration} from "@portbase/bezoekschip-service-typescriptmodels";
import moment from "moment/moment";

@Component({
  selector: 'app-transit-declaration-send-trigger-info',
  templateUrl: './transit-declaration-send-trigger-info.component.html',
  styleUrls: ['./transit-declaration-send-trigger-info.component.css']
})
export class TransitDeclarationSendTriggerInfoComponent {
  @Input() declaration = <TransitDeclaration>{};
  @Input() readonly: boolean = false;
  @Input() sendOnAtaEnabled: boolean = true;
  @Output() changed = new EventEmitter<void>();

  minDate = moment();

  get today(): string {
    return moment().startOf("day").toISOString()
  };

  onToggleSendOnAta = () => {
    if (this.declaration.status.sendOnAta) {
      this.declaration.status.sendDate = null;
    }

    this.changed.emit();
  }

  emitChange() {
    setTimeout(() => {
      this.changed.emit()
    }, 0);
  }
}
