<div class="modal-header">
  <div class="flex-container align-items-center">
    <button type="button" class="btn-close me-2" data-bs-dismiss="modal" aria-label="Close"></button>
    <div>
      <span *ngIf="!data?.berthVisitSelection" class="page-title">New Bunkering</span>
      <ng-container *ngIf="data?.berthVisitSelection">
        <span class="page-title">{{ data.berthVisitSelection.vesselName }}</span>
        <span *ngIf="data.cancelled" class="page-title cancelled">(CANCELLED)</span>
        <br/>
        <span class="regular-caps-12-16">{{ data.berthVisitSelection.berthName }}</span>
      </ng-container>
    </div>
  </div>
</div>

<div class="modal-body">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <h5 class="header-1 col-10">Bunkering details</h5>
        <div class="col-2 dropdown always-enabled" *ngIf="appContext.isAdmin()">
          <button class="btn btn-secondary" type="button" id="adminHamburgerMenu" data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
            <!-- Hamburger menu icon -->
            <i class="fa fa-user-shield" aria-hidden="true"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end" aria-labelledby="adminHamburgerMenu">
            <!-- Messages -->
            <h6 class="dropdown-header" style="font-size: 18px;">Messages</h6>
            <a href="#" (click)="$event.preventDefault(); openMessages()" class="dropdown-item">
              DeclareBunkering to Singlewindow
            </a>
          </div>
        </div>
      </div>

      <ng-container *ngIf="!data.input.license; else licenseSelected">
        <app-form-field label="Service vessel">
          <app-search [(ngModel)]="data.input.license"
                      [searchFunction]="licences"
                      [resultFormatter]="formatLicense"
                      (ngModelChange)="saveLicense($event)"
                      required placeholder="Ship name or ENI"></app-search>
        </app-form-field>
      </ng-container>
      <ng-template #licenseSelected class="form-group">
        <div class="label">Service vessel *</div>
        <div class="card form-group">
          <div class="card-body small py-2">
            <app-selection-license [selection]="data.input.license"></app-selection-license>
            <button type="button" class="btn btn-tertiary p-0" style="position: absolute; right: 0.75rem; top: 0.5rem"
                    (click)="data.input.license = undefined">
              <i *ngIf="data.draft" class="fa fa-light fa-edit"></i>
            </button>
          </div>
        </div>
      </ng-template>

      <ng-container *ngIf="!data.berthVisitSelection; else berthVisitSelected">
        <app-form-field label="Visit" *ngIf="data.input.license">
          <app-search [(ngModel)]="data.berthVisitSelection"
                      [searchFunction]="getBerthVisitsFn()"
                      [resultFormatter]="formatGetBerthVisitsSelection"
                      required placeholder="Ship name or IMO"></app-search>
        </app-form-field>
      </ng-container>
      <ng-template #berthVisitSelected>
        <div class="label">Visit *</div>
        <div class="card form-group">
          <div class="card-body small py-2">
            <app-selection-bunkering-berth-visits [selection]="data.berthVisitSelection">
            </app-selection-bunkering-berth-visits>
            <button type="button" class="btn btn-tertiary p-0" style="position: absolute; right: 0.75rem; top: 0.5rem"
                    (click)="data.berthVisitSelection = undefined">
              <i *ngIf="data.draft" class="fa fa-light fa-edit"></i>
            </button>
          </div>
        </div>
      </ng-template>

      <div class="row">
        <app-form-field label="Supplier" class="col-md-6">
          <app-search [(ngModel)]="data.input.supplier"
                      [searchFunction]="suppliers"
                      [resultFormatter]="formatSupplier"
                      [inputFormatter]="formatSupplier"
                      [disabled]=!data.draft
                      required placeholder="Supplier name"></app-search>
        </app-form-field>
        <div class="col-6">
        </div>
      </div>

      <div class="row">
        <app-form-field label="Estimated start date and time" class="col-md-6">
          <app-new-date-field [(ngModel)]="data.input.ets" [disabled]=data.cancelled required></app-new-date-field>
        </app-form-field>
      </div>
      <ng-container *ngIf="showEtsBeforeWindowWarning()">
        <div class="alert alert-warning show row align-items-center" role="alert">
          <div class="col-1 ps-2">
            <i class="fa fa-light fa-circle-exclamation" aria-hidden="true" style="font-size: xxx-large"></i>
          </div>
          <div class="col-11">
            <b>Planning mismatch.</b><br/>
            Your ETS is before the ETA of the vessel.<br>
            You will probably not be able to start bunkering.<br>
          </div>
        </div>
      </ng-container>
      <div class="row">
        <app-form-field label="Completion date and time" class="col-md-6">
          <app-new-date-field [(ngModel)]="completionTime" [disabled]=data.cancelled [byTheMinute]="!expectedTime" required></app-new-date-field>
        </app-form-field>
        <app-yes-no class="col-md-6 mt-md-5 mb-2" [(ngModel)]="expectedTime" [formatter]="formatExpectedTime" required></app-yes-no>
      </div>

      <ng-container *ngIf="showEtcAfterWindowWarning()">
        <div class="alert alert-warning show row align-items-center" role="alert">
          <div class="col-1 ps-2">
            <i class="fa fa-light fa-circle-exclamation" aria-hidden="true" style="font-size: xxx-large"></i>
          </div>
          <div class="col-11">
            <b>Planning mismatch.</b><br/>
            Your ETC is after the ETD of the vessel.<br>
            You are causing a delay for the departing vessel!<br/>
            Please leave the vessel in time or contact the ship’s agent.<br>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="showEtcWithin30MinutesWarning()">
        <div class="alert alert-warning show row align-items-center" role="alert">
          <div class="col-1 ps-2">
            <i class="fa fa-light fa-circle-exclamation" aria-hidden="true" style="font-size: xxx-large"></i>
          </div>
          <div class="col-11">
            <b>Planning mismatch.</b><br/>
            Your ETC is less than 30 minutes before or equal to the ETD of the vessel.<br>
            You may cause a delay for the departing vessel!<br/>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="showInvalidPeriodWarning()">
        <div class="alert alert-warning show row align-items-center" role="alert">
          <div class="col-1 ps-2">
            <i class="fa fa-light fa-circle-exclamation" aria-hidden="true" style="font-size: xxx-large"></i>
          </div>
          <div class="col-11">
            <b>Start/completion time mismatch.</b><br/>
            The completion time should be after the start time.
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="showAtcInTheFutureWarning()">
        <div class="alert alert-warning show row align-items-center" role="alert">
          <div class="col-1 ps-2">
            <i class="fa fa-light fa-circle-exclamation" aria-hidden="true" style="font-size: xxx-large"></i>
          </div>
          <div class="col-11">
            <b>Actual time of completion is in the future.</b><br/>
            Report the ATC when the bunkering is actually completed.
          </div>
        </div>
      </ng-container>

      <ng-container *ngFor="let item of data.input.items; index as i">
        <div class="row mb-2 gy-3">
          <div class="col-md-6">
            <div class="mx-1 mb-2">Product</div>
            <app-search [(ngModel)]="item.product"
                        [searchFunction]="products"
                        [resultFormatter]="formatProduct"
                        [inputFormatter]="formatProduct"
                        [disabled]=data.cancelled
                        required placeholder="Product type"></app-search>
          </div>
          <div class="col-md-2 col-4">
            <div class="mx-1 mb-2">Quantity</div>
            <input appDecimalNumber maxDecimals="0" min="0" [(ngModel)]="item.quantity" [disabled]=data.cancelled
                   class="form-control" required>
          </div>
          <div class="col-md-2 col-4">
            <div class="mx-1 mb-2">Units</div>
            {{ item.product?.unitOfMeasure }}
          </div>
          <div class="col-2 text-center my-auto">
            <button *ngIf="data.input.items.length > 1" type="button" class="btn btn-tertiary p-0" (click)="deleteItem(i);"
                    [disabled]=data.cancelled><i class="fa fa-light fa-trash-can"></i>
            </button>
          </div>
        </div>
      </ng-container>

      <div class="my-2 text-end">
        <button type="submit" class="btn btn-secondary" style="min-width: 10rem"
                (click)="addProduct()" [disabled]=data.cancelled>
          <i class="fal fa-plus me-2"></i> Add Product
        </button>
      </div>

      <hr/>
      <ng-container>
        <div class="row">
          <app-form-group label="Report to customs?" class="col-12">
            <app-yes-no [disabled]="!data.draft" required [(ngModel)]="data.sendProToCustoms"
                        id="sendToCustoms"></app-yes-no>
          </app-form-group>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div *ngIf="!data.cancelled" class="modal-footer">
  <div class="footer-container" style="display: inline;">
    <button *ngIf="!data.draft" type="button" class="btn btn-outline-danger float-start" style="min-width: 6rem"
            (click)="cancel()">
      Cancel bunkering
    </button>
    <div class="float-end">
      <button type="button" class="btn btn-secondary me-3" style="min-width: 6rem" (click)="close()">Cancel
      </button>
      <button type="button" class="btn btn-primary" style="min-width: 6rem" (click)="submit()">Send
      </button>
    </div>
  </div>
</div>
