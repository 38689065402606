import {Component, Input, ViewChild} from '@angular/core';
import {
  IE3ConsignmentProcessSummary,
  IE3CustomsProcess,
  IE3CustomsStatus,
  Item,
  TransitDeclaration
} from "@portbase/bezoekschip-service-typescriptmodels";
import {lodash, sendQuery, uniqueItems} from "../../../common/utils";
import {map} from "rxjs/operators";
import {TagsComponent} from "../../../common/tags/tags.component";
import moment from "moment";
import {PortvisitUtils} from "../../../refdata/portvisit-utils";
import {getGoodsItems} from "../../transit.utils";

@Component({
  selector: 'app-transit-declarations-from-containers',
  templateUrl: './transit-declarations-from-containers.component.html',
  styleUrls: ['./transit-declarations-from-containers.component.css']
})
export class TransitDeclarationsFromContainersComponent {
  refData = PortvisitUtils;

  containers: ContainerImportItem[] = [];
  selectedContainers: ContainerImportItem[] = [];
  searchTags: string[] = [];
  @Input() existingDeclarations: TransitDeclaration[] = [];
  splitRegex: RegExp = /\r?\n| +/;

  @ViewChild("searchField") searchField: TagsComponent;

  searchFunction(): void {
    this.searchTags = uniqueItems(this.searchTags).sort();
    sendQuery('com.portbase.bezoekschip.common.api.consignments.queries.FindConsignmentsUsingContainers',
      {term: this.searchTags}, {caching: false})
      .pipe(map(c => mapToContainerItemWrapper(this.searchTags, c)))
      .pipe(map(c => lodash.filter<ContainerImportItem>(c, e => !this.existingDeclarations.some(d => d.status.phase !== "cancelled" && d.data.containerNumber === e.containerNumber))))
      .subscribe((results: ContainerImportItem[]) => {
        this.onResults(results);
      });
  }

  onInputSearchTerm(value: string) {
    if (value.match(this.splitRegex)) {
      this.onExecuteSearchTerm(value);
    }
  }

  onPasteSearchTerm(event: ClipboardEvent) {
    this.onExecuteSearchTerm(event.clipboardData.getData('text'));
  }

  onExecuteSearchTerm(valueToAppend: string) {
    const pastedValues = valueToAppend.trim().split(this.splitRegex);
    this.searchTags = uniqueItems(pastedValues.concat(this.searchTags)).filter(t => t !== "").sort();
    setTimeout(() => {
      this.searchField.clearValue();
      this.searchFunction();
    }, 0);
  }

  trackByContainerNumber(index: number, item: ContainerImportItem): any {
    return item.containerNumber;
  }

  selected(): ContainerImportItem[] {
    return this.containers.filter(s => !!s['selected'] && !s['hidden']);
  }

  toggleSelectAll = () => {
    if (this.selected().length === this.selectableContainers().length) {
      this.selectableContainers().forEach(c => c['selected'] = false);
    } else {
      this.selectableContainers().forEach(c => c['selected'] = true);
    }
  }

  selectableContainers(): ContainerImportItem[] {
    return this.containers
      .filter(c => this.isSelectableContainer(c));
  }

  selectContainers() {
    this.selectedContainers = this.selected();
    this.reset();
  }

  hasSelectedConsignmentWithWarning(): boolean {
    return lodash.flatMap(this.selected(), container => container.consignmentProcessSummaries)
      .map(consignmentProcessSummary => consignmentProcessSummary.masterConsignment)
      .some(consignment => !!consignment.customsStatus || !!consignment.customsProcess);
  }

  isSelectableContainer(containerEntity: ContainerImportItem): boolean {
    return !containerEntity.consignmentProcessSummaries
      .map(consignmentProcessSummary => consignmentProcessSummary.masterConsignment)
      .some(consignment => consignment.customsStatus == IE3CustomsStatus.EU_COMMUNITY_GOODS_IN_TRANSHIPMENT
        || consignment.customsProcess == IE3CustomsProcess.SEA_IN_SEA_OUT);
  }

  reset() {
    this.searchTags = [];
    this.containers = [];
  }

  private onResults(results: ContainerImportItem[]) {
    this.containers = results;
    this.selectableContainers().forEach(c => c['selected'] = true);
  }
}

export interface ContainerImportItem extends Item {
  consignmentProcessSummaries: IE3ConsignmentProcessSummary[];
}

export function mapToContainerItemWrapper(term: string[], consignments: IE3ConsignmentProcessSummary[]):
  ContainerImportItem[] {
  const result: Map<string, IE3ConsignmentProcessSummary> = new Map();

  consignments.forEach(consignment => getGoodsItems(consignment.masterConsignment)
    .filter(g => g && g.placements)
    .forEach(g => g.placements
      .filter(p => term.includes(p.containerIdentificationNumber))
      .forEach(p => {
        const existingConsignment = result.get(p.containerIdentificationNumber);

        if (!existingConsignment || moment(consignment.etaPort) > moment(existingConsignment.etaPort)) {
          result.set(p.containerIdentificationNumber, consignment);
        }
      })));

  return Array.from(result, ([key, value]) => <ContainerImportItem>{
    crn: value.crn,
    consignmentNumber: value.masterConsignment.consignmentNumber,
    containerNumber: key,
    consignmentProcessSummaries: [value]
  });
}
