<div class="fieldset" [ngClass]="{'disabled' :disabled}" *ngIf="!readonly; else ro;">
  <div class="row g-1 g-md-4 datetime">
    <div #picker class="col resizable-input-group dropdown">
      <input [(ngModel)]="inputDate"
             [required]="required" (focus)="this.focus.emit($event)" (blur)="onDateTyped()" [disabled]="disabled"
             [ngClass]="status === 'warning' ? 'warning' : status === 'error' ? 'error' : null"
             [id]="id ? id + '-date' : null" autocomplete="off" class="form-control validatable" placeholder="dd-mm-yyyy">
      <button class="btn btn-outline-secondary fa fa-calendar-alt rounded-end"
              data-bs-toggle="dropdown" data-bs-auto-close="true" (focus)="this.focus.emit($event)" type="button" tabIndex="-1"></button>
      <div class="dropdown-menu dropdown-menu-end p-3" onclick="event.stopPropagation()">
        <app-date-picker *ngIf="showPicker" [date]="_calendarStartDate" [minDate]="_minDate"
                         (dateChanged)="onDatePickerChanged($event)"></app-date-picker>
      </div>
    </div>
    <div class="col-5 time resizable-input-group dropdown" *ngIf="!dateOnly">
      <input [(ngModel)]="inputTime" [placeholder]="'hh:mm'" class="h-100 form-control validatable" size="5"
             (blur)="onTimeTyped()" [id]="id ? id + '-time' : null"
             [required]="required" (focus)="this.focus.emit($event)" [disabled]="disabled"
             [ngClass]="status === 'warning' ? 'warning' : status === 'error' ? 'error' : null">
      <button class="btn btn-outline-secondary fa-regular fa-clock rounded-end" (click)="scrollTimeIntoView()"
              data-bs-toggle="dropdown" data-bs-auto-close="outside" (focus)="this.focus.emit($event)" type="button" tabIndex="-1"></button>
      <app-quarter-hour-picker
        class="dropdown-menu dropdown-menu-end p-3 time-dropdown"
        style="box-shadow: 0px 2px 32px 0px #00000026"
        onclick="event.stopPropagation()"
        [id]="id"
        [hours]="hours" [minutes]="minutes" [byTheMinute]="byTheMinute"
        (timeChanged)="onTimePickerChanged($event)">
      </app-quarter-hour-picker>
    </div>
  </div>
</div>
<ng-template #ro>
  <span class="me-2 fa fa-clock text-secondary"></span>
  <span [ngClass]="{'text-secondary' : !dateOnly}">{{inlineDate()}}</span>
  <span class="ms-1 text-secondary" *ngIf="!dateOnly">{{inlineTime()}}</span>
</ng-template>
