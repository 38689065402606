<div class="time-picker container-fluid p-0">
  <div class="row">
    <div class="col" style="max-height: 16rem; overflow-y: auto">
      <div *ngFor="let hour of allHours" class="row px-2">
        <span id="hr{{hour}}-{{id}}" class="dropdown-item px-3 {{hour == hours ? 'selected' : ''}}" (click)="selectHour(hour)" [ngClass]="">{{ hour }}</span>
      </div>
    </div>
    <div class="col" style="max-height: 16rem; overflow-y: auto">
      <div *ngFor="let quarter of allMinutes" class="row px-2">
        <span id="min{{quarter}}-{{id}}" class="dropdown-item px-3 {{quarter == minutes ? 'selected' : ''}}" (click)="selectMinute(quarter)">{{ quarter }}</span>
      </div>
    </div>
  </div>
  <div>
    <button #nowButton class="btn btn-secondary my-2 mx-6" (click)="now()">
      Now
    </button>
  </div>
</div>
