<div *ngIf="declaration.data as data">
  <div class="row">
    <div class="col-md">
      <app-form-group *ngIf="!isTemplate && !isGeneric && isContainerDeclaration()" label="Container number">
        <input [readonly]="!isEditable() || !!declaration.crn" class="form-control" [(ngModel)]="data.containerNumber"
               (change)="data.containerNumber=$event.target?.value?.trim()"
               required
               [appCompare]="declaration.dataFromCustoms.containerNumber" comparePreamble="Customs reported: ">
      </app-form-group>
      <app-form-group *ngIf="!!declaration.crn" label="CRN">
        <input class="form-control" readonly [(ngModel)]="declaration.crn">
      </app-form-group>
      <app-form-group label="Country of export" *ngIf="!isTemplate && !isGeneric">
        <app-search [required]="!isTemplate" [minCharacters]="0"
                    [(ngModel)]="data.countryOfExport"
                    [searchFunction]="refData.findCountries"
                    dataKey="code" [resultFormatter]="refData.countryFormatter"
                    [appCompare]="declaration.dataFromCustoms.countryOfExport" comparePreamble="Customs reported: "
                    [compareFormatter]="refData.countryCompareFormatter"
                    [disabled]="!isEditable()">
        </app-search>
      </app-form-group>
      <app-form-group label="Terminal of discharge" *ngIf="!isTemplate && !isGeneric">
        <app-search [disabled]="!isEditable() || !!declaration.crn" [ngModel]="data.dischargeTerminal"
                    (ngModelChange)="setTerminal($event)" [inputFormatter]="refData.terminalFormatter"
                    [searchFunction]="refData.findTerminals" dataKey="terminalCode" placeholder="Search for terminal">
        </app-search>
      </app-form-group>
      <app-form-group label="Departure office">
        <app-select [(ngModel)]="data.departureOffice" [required]="!isTemplate"
                    [optionsProvider]="refData.getTransitOffices()" [nullOption]="true"
                    [disabled]="!isEditable()"
                    dataKey="unCode" [formatter]="refData.transitOfficesFormatter"
                    [appCompare]="declaration.dataFromCustoms.departureOffice" comparePreamble="Customs reported: "
                    [compareFormatter]="refData.transitOfficesCompareFormatter">
        </app-select>
      </app-form-group>

      <app-form-group label="Place of Loading" *ngIf="!isTemplate && !isGeneric">
        <label [ngClass]="{'required': data.securityActivated}">Place of Loading</label>
        <input class="form-control" placeholder="Enter zip code + number"
               [required]="!isTemplate"
               [(ngModel)]="data.placeOfLoading" [disabled]="!!data.dischargeTerminal?.organisationShortName"
               [readOnly]="!isEditable()" [minlength]="1" [maxlength]="17"
               [appCompare]="declaration.dataFromCustoms.placeOfLoading" comparePreamble="Customs reported: ">
      </app-form-group>
      <app-form-group *ngIf="!isTemplate && !isGeneric && isSimplifiedProcedure">
        <label [ngClass]="{'required': isSimplifiedProcedure}">Authorized location</label>
        <input class="form-control" placeholder="Enter zip code + number"
               [required]="isSimplifiedProcedure && !isTemplate"
               [(ngModel)]="data.authorizedLocation"
               pattern="[1-9][0-9]{3} [a-z | A-Z]{2}( \w*)?"
               title="e.g. '3000 AA 15b', zipCode and number separated with spaces"
               [readOnly]="!isEditable()"
               [appCompare]="declaration.dataFromCustoms.authorizedLocation" comparePreamble="Customs reported: ">
      </app-form-group>
      <app-form-group *ngIf="!isSimplifiedProcedure && !isTemplate && !isGeneric" label="Customs sub place">
        <input class="form-control" placeholder="Enter zip code" [required]="!isTemplate"
               [(ngModel)]="data.customsSubPlace" [disabled]="!!data.dischargeTerminal?.organisationShortName"
               [readOnly]="!isEditable()"
               [appCompare]="declaration.dataFromCustoms.customsSubPlace" comparePreamble="Customs reported: ">
      </app-form-group>
      <app-form-group label="Declaration place" *ngIf="!isTemplate && !isGeneric">
        <input class="form-control" placeholder="e.g. Rotterdam" required
               [(ngModel)]="data.declarationPlace"
               [readOnly]="!isEditable()"
               [appCompare]="declaration.dataFromCustoms.declarationPlace"
               comparePreamble="Customs reported: ">
      </app-form-group>

      <app-form-group label="Transit offices">
        <app-multi-search [(ngModel)]="data.transitOffices" [searchFunction]="refData.findTransitOffices"
                          [disabled]="!isEditable()"
                          [inputFormatter]="refData.transitOfficesFormatter" [minCharacters]="2"
                          placeholder="Find office">
        </app-multi-search>
      </app-form-group>
      <app-form-group label="Destination office">
        <app-search [(ngModel)]="data.destinationsOffice" [required]="!isTemplate"
                    [searchFunction]="refData.findTransitOffices" [resultFormatter]="refData.transitOfficesFormatter"
                    [inputFormatter]="refData.transitOfficesFormatter" [minCharacters]="2"
                    dataKey="unCode"
                    [disabled]="!isEditable()"
                    [appCompare]="declaration.dataFromCustoms.destinationsOffice" comparePreamble="Customs reported"
                    [compareFormatter]="refData.transitOfficesCompareFormatter">
        </app-search>
      </app-form-group>
      <app-form-group label="Country of destination">
        <app-search [required]="!isTemplate" [minCharacters]="0"
                    [(ngModel)]="data.countryOfDestination"
                    [searchFunction]="refData.findCountries"
                    dataKey="code" [resultFormatter]="refData.countryFormatter"
                    [disabled]="!isEditable()"
                    [appCompare]="declaration.dataFromCustoms.countryOfDestination" comparePreamble="Customs reported: "
                    [compareFormatter]="refData.countryCompareFormatter">
        </app-search>
      </app-form-group>
    </div>

    <div class="col-md">
      <app-form-group *ngIf="!isTemplate && !isGeneric" label="LRN">
        <input class="form-control" readonly [(ngModel)]="declaration.lrn">
      </app-form-group>
      <app-form-group *ngIf="!!declaration.status?.mrn" label="MRN">
        <input class="form-control" readonly [(ngModel)]="declaration.status.mrn">
      </app-form-group>
      <app-form-group label="Declaration type">
        <app-select [required]="!isTemplate" [(ngModel)]="data.type"
                    [appDefaultValue]="{code: 'T1', description: 'External Community Transit'}"
                    [optionsProvider]="refData.transitTypes()" dataKey="code"
                    [disabled]="!isEditable()"
                    [formatter]="refData.transitTypeFormatter" placeholder="– Select a transit type –"
                    [appCompare]="declaration.dataFromCustoms.type" comparePreamble="Customs reported: "
                    [compareFormatter]="refData.transitTypeCompareFormatter">
        </app-select>
      </app-form-group>
      <app-transit-declaration-send-trigger-info *ngIf="!isTemplate && displaySendDate" [declaration]="declaration"
                                                 [readonly]="!isEditable()"
                                                 (changed)="recalculateExpiryDate()"
                                                 [sendOnAtaEnabled]="!!declaration.crn"></app-transit-declaration-send-trigger-info>
      <app-form-group label="Procedure">
        <app-yes-no [(ngModel)]="isSimplifiedProcedure"
                    (ngModelChange)="changeProcedure()"
                    [disabled]="!isEditable()"
                    [formatter]="formatProcedure" required></app-yes-no>
      </app-form-group>
      <app-form-group *ngIf="isSimplifiedProcedure">
        <label>License number
          <app-info [wide]="true">
            <span class="tooltipContent text-start">The license number for the simplified procedure</span>
          </app-info>
        </label>
        <input class="form-control" placeholder="Enter the declarant license number" [readonly]="!isEditable()" required
               [(ngModel)]="data.declarantLicenseNumber"
               [appCompare]="declaration.dataFromCustoms.declarantLicenseNumber" comparePreamble="Customs reported: ">
      </app-form-group>
      <app-form-group *ngIf="isSimplifiedProcedure">
        <label [ngClass]="{'required': isSimplifiedProcedure}">Transport term</label>
        <app-days [(ngModel)]="declaration.data.transportTerm" (change)="recalculateExpiryDate()"
                  [readonly]="!isEditable()"
                  [required]="isSimplifiedProcedure"></app-days>
      </app-form-group>
      <app-form-group
        *ngIf="isSimplifiedProcedure && !declaration.transportExpiryDate && !this.declaration.status.sendOnAta && !isTemplate && !isGeneric"
        label="Requested Expiry date">
        <app-date-field
          [ngModel]="declaration.requestedTransportExpiryDate"
          [disabled]="true" [dateOnly]="true"></app-date-field>
      </app-form-group>
      <app-form-group *ngIf="declaration.transportExpiryDate && !isTemplate && !isGeneric" label="Expiry date">
        <app-date-field
          [ngModel]="transportExpiryDate" [appCompare]="requestedTransportExpiryDate"
          comparePreamble="You reported: "
          [disabled]="true" [dateOnly]="true"></app-date-field>
      </app-form-group>
      <app-form-group label="Conveyance reference" *ngIf="!isTemplate && !isGeneric">
        <input class="form-control" placeholder="e.g. vessel name"
               [readonly]="!isEditable()"
               [(ngModel)]="data.conveyanceReference" [appCompare]="declaration.dataFromCustoms.conveyanceReference"
               comparePreamble="Customs reported: ">
      </app-form-group>
      <app-form-group label="Transit route">
        <input class="form-control" [(ngModel)]="data.transitRoute"
               [readonly]="!isEditable()"
               [appCompare]="declaration.dataFromCustoms.transitRoute"
               comparePreamble="Customs reported: ">
      </app-form-group>
      <app-form-group *ngIf="!isTemplate && !isGeneric">
        <label [ngClass]="{'required': data.containerNumber && isSimplifiedProcedure && !isTemplate}">Seals</label>
        <app-tags [(ngModel)]="data.seals" placeholder="Add Seal"
                  [readonly]="!isEditable()"
                  [required]="data.containerNumber && isSimplifiedProcedure && !isTemplate"></app-tags>
      </app-form-group>
      <app-form-group label="Representative">
        <input class="form-control" placeholder="e.g. full name" [(ngModel)]="data.representative"
               [appCompare]="declaration.dataFromCustoms.representative"
               [readonly]="!isEditable()"
               comparePreamble="Customs reported: ">
      </app-form-group>
      <app-form-group label="Representative EORI">
        <input class="form-control" placeholder="The EORI number of the representative" [(ngModel)]="data.representativeEori"
               [appCompare]="declaration.dataFromCustoms.representativeEori"
               [readonly]="!isEditable()"
               comparePreamble="Customs reported: ">
      </app-form-group>
      <app-form-group label="Security indicator">
        <app-yes-no [(ngModel)]="data.securityActivated" (ngModelChange)="setSecurity($event)"
                    [disabled]="!isEditable()"></app-yes-no>
      </app-form-group>

      <app-form-group *ngIf="data.securityActivated" label="Itinerary">
        <ng-container *ngFor="let country of data.itinerary; let index = index; let last = last">

          <app-search class="mb-2" [ngModel]="country"
                      (ngModelChange)="$event?.code ? data.itinerary[index] = $event.code : data.itinerary.splice(index, 1)"
                      [searchFunction]="refData.findCountries" [resultFormatter]="refData.countryFormatter"
                      [minCharacters]="0"
                      [disabled]="!isEditable()"
                      [required]="data.securityDetails?.circumstances !== 'victualling_of_ships_and_aircraft' && !isTemplate">
          </app-search>
          <ng-container *ngIf="last">
            <app-search [ngModel]="null" placeholder="Add other"
                        (ngModelChange)="($event?.code ? data.itinerary.push($event.code) : null);"
                        [searchFunction]="refData.findCountries" [resultFormatter]="refData.countryFormatter"
                        [disabled]="!isEditable()"
                        [minCharacters]="0">
            </app-search>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="data.itinerary && data.itinerary.length === 0">
          <app-search [ngModel]="null" [placeholder]="data.itinerary.length === 0 ? '' : 'Add other'"
                      (ngModelChange)="($event?.code ? data.itinerary.push($event.code) : null);"
                      [searchFunction]="refData.findCountries" [resultFormatter]="refData.countryFormatter"
                      [minCharacters]="0"
                      [disabled]="!isEditable()"
                      [required]="data.securityDetails?.circumstances !== 'victualling_of_ships_and_aircraft' && !isTemplate">
          </app-search>
        </ng-container>
      </app-form-group>

      <app-form-group label="Carrier remarks">
        <textarea class="form-control" [(ngModel)]="data.carrierRemarks"
                  [readonly]="!isEditable()"></textarea>
      </app-form-group>
    </div>
  </div>

  <div *ngIf="data.securityActivated">
    <div class="declaration-block my-4">
      <h2>Security details</h2>
    </div>
  </div>
  <div *ngIf="data.securityActivated" class="row">

    <div class="col-md">
      <app-form-group label="Circumstances">
        <app-select [(ngModel)]="data.securityDetails.circumstances"
                    [disabled]="!isEditable()"
                    [options]="refData.securityCircumstances" [formatter]="refData.enumFormatter"></app-select>
      </app-form-group>
      <app-form-group label="Payment method">
        <app-select [(ngModel)]="data.securityDetails.paymentMethod"
                    [disabled]="!isEditable()"
                    [options]="refData.securityPaymentMethods" [formatter]="refData.enumFormatter"></app-select>
      </app-form-group>
    </div>

    <div class="col-md">
      <app-form-group label="Place of unloading">
        <input class="form-control" placeholder="e.g. Rotterdam"
               [readonly]="!isEditable()"
               [(ngModel)]="data.securityDetails.placeOfUnloading">
      </app-form-group>
      <app-form-group label="Commercial reference">
        <input class="form-control"
               [readonly]="!isEditable()"
               [(ngModel)]="data.securityDetails.commercialReference">
      </app-form-group>
    </div>
  </div>

  <div class="row">
    <div class="col-md">
      <div class="declaration-block">
        <h2>Departure transport</h2>
      </div>
      <app-form-group>
        <label>
          Transport mode
          <app-info *ngIf="showModeOfTransportWarning(data.departureTransport.modeOfTransport)"
                    [wide]="true"
                    iconClass="fa-triangle-exclamation"
                    style="color: #ffcc00;">
            <span class="tooltipContent text-start">Setting transport mode to "{{refData.enumFormatter("NOT_SPECIFIED")}}
              " requires a manual call to the customs to specify the transport mode when the transit is in accepted state</span>
          </app-info>
        </label>
        <app-select [(ngModel)]="data.departureTransport.modeOfTransport"
                    [options]="refData.transitModesOfTransport" [formatter]="refData.enumFormatter"
                    (ngModelChange)="departureModeOfTransportChanged()"
                    [appCompare]="declaration.dataFromCustoms.departureTransport.modeOfTransport"
                    [disabled]="!isEditable()"
                    [required]="true"
                    comparePreamble="Customs reported: " [compareFormatter]="refData.enumFormatter"></app-select>
      </app-form-group>
      <app-form-group label="Nationality" *ngIf="departureNationalityAllowed()">
        <app-search [(ngModel)]="data.departureTransport.nationality" [minCharacters]="0"
                    [searchFunction]="refData.findCountries"
                    dataKey="code" [resultFormatter]="refData.countryFormatter"
                    [appCompare]="declaration.dataFromCustoms.departureTransport.nationality"
                    [disabled]="!isEditable()" required
                    comparePreamble="Customs reported: " [compareFormatter]="refData.countryCompareFormatter">
        </app-search>
      </app-form-group>
      <app-form-group>
        <label [ngClass]="{'required': !!data.departureTransport.modeOfTransport}">Reference</label>
        <input class="form-control" [(ngModel)]="data.departureTransport.reference"
               [required]="!!data.departureTransport.modeOfTransport"
               [readonly]="!isEditable()"
               [appCompare]="declaration.dataFromCustoms.departureTransport.reference"
               [maxlength]="27"
               comparePreamble="Customs reported: ">
      </app-form-group>
    </div>

    <div class="col-md">
      <div class="declaration-block">
        <h2>Border transport</h2>
      </div>
      <app-form-group label="Transport mode">
        <app-select [(ngModel)]="data.borderTransport.modeOfTransport"
                    [options]="refData.transitModesOfTransport" [formatter]="refData.enumFormatter"
                    (ngModelChange)="borderModeOfTransportChanged()"
                    [appCompare]="declaration.dataFromCustoms.borderTransport.modeOfTransport"
                    [disabled]="!isEditable()"
                    comparePreamble="Customs reported: " [compareFormatter]="refData.enumFormatter"></app-select>
      </app-form-group>
      <app-form-group label="Nationality" *ngIf="data.borderTransport.modeOfTransport && borderNationalityAllowed()">
        <app-search [(ngModel)]="data.borderTransport.nationality" [minCharacters]="0"
                    [searchFunction]="refData.findCountries"
                    dataKey="code" [resultFormatter]="refData.countryFormatter"
                    [appCompare]="declaration.dataFromCustoms.borderTransport.nationality"
                    [disabled]="!isEditable()" required
                    comparePreamble="Customs reported: " [compareFormatter]="refData.countryCompareFormatter">
        </app-search>
      </app-form-group>
      <app-form-group label="Reference" *ngIf="!!data.borderTransport.modeOfTransport" required>
        <input class="form-control" [(ngModel)]="data.borderTransport.reference"
               [required]="!!data.borderTransport.modeOfTransport"
               [readonly]="!isEditable()"
               [appCompare]="declaration.dataFromCustoms.borderTransport.reference"
               [maxlength]="27"
               comparePreamble="Customs reported: ">
      </app-form-group>
      <app-form-group label="Customs office">
        <app-search [(ngModel)]="data.borderTransport.customsOffice" [searchFunction]="refData.findTransitOffices"
                          [disabled]="!isEditable()"
                          [inputFormatter]="refData.transitOfficesFormatter" [minCharacters]="2"
                          placeholder="Find office">
        </app-search>
      </app-form-group>
    </div>

  </div>

  <div>
    <div class="declaration-block my-4">
      <h2>Parties</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-md">
      <app-form-group label="Consignee">
        <app-search [(ngModel)]="data.consignee" [required]="!isTemplate"
                    [minCharacters]="0" [newValueTemplate]="newTransitParty" valueType="transit party"
                    [disabled]="!isEditable()"
                    [searchFunction]="findTransitParties" [inputFormatter]="refData.transitPartyFormatter">
        </app-search>
      </app-form-group>
    </div>

    <div class="col-md">
      <app-form-group label="Consignee (Security)" *ngIf="data.securityActivated">
        <app-search [(ngModel)]="data.securityConsignee" [required]="data.securityActivated && !isTemplate"
                    [minCharacters]="0" [newValueTemplate]="newTransitParty" valueType="transit party"
                    [disabled]="!isEditable()"
                    [searchFunction]="findTransitParties" [inputFormatter]="refData.transitPartyFormatter">
        </app-search>
      </app-form-group>
      <app-form-group label="Carrier (Security)" *ngIf="data.securityActivated">
        <app-search [(ngModel)]="data.carrier" [minCharacters]="1"
                    [disabled]="!isEditable()"
                    [searchFunction]="refData.findContainerOperators"
                    [inputFormatter]="refData.containerOperatorFormatter">
        </app-search>
      </app-form-group>
    </div>

  </div>

  <!--loop over all guarantees-->
  <div>
    <div class="declaration-block my-4">
      <h2>Transit guarantees</h2>
    </div>
  </div>

  <div *ngFor="let guaranteeData of data.guarantees; trackBy : trackByIndex; let idx = index;" class="my-2">
    <app-collapse [collapse]="guaranteeTemplate" [showOnLoad]="!guaranteeData.type"
                  [deleteHandle]="isEditable() ? removeGuarantee(guaranteeData) : null"
                  [validationModel]="guaranteeData">
      <div class="row  py-1 px-3 align-items-center text-dark">
        <div class="col-auto d-none d-md-block" style="min-width: 16rem">
          <span class="d-inline-block align-top text-truncate"
                [title]="guaranteeData.guaranteeReferenceNumber ? 'Guarantee reference number' : 'other reference'">
            {{guaranteeData.guaranteeReferenceNumber || guaranteeData.otherReference || 'New document'}}
          </span>
        </div>
        <div *ngIf="guaranteeData.accessCode" class="col-auto d-none d-md-block">
          <span class="ms-1" title="Access code">{{guaranteeData.accessCode}}</span>
        </div>
        <div class="text-end col d-none d-md-block">
          <span class="mx-2" title="Customs amount">
            {{guaranteeData.customsValue?.amount | number: '1.2-2'}} {{guaranteeData.customsValue?.currency?.unCode}}
          </span>
        </div>
      </div>
    </app-collapse>

    <ng-template #guaranteeTemplate>
      <div class="collapse">
        <div class="mx-3 pt-3">
          <div class="row">
            <div class="col-md">
              <app-form-group label="Guarantee">
                <app-select [ngModel]="guaranteeData" (ngModelChange)="setTransitGuarantee($event, idx)"
                            [formatter]="transitGuaranteeFormatter" [options]="allTransitGuarantees"
                            [dataKey]="guaranteeData.guaranteeReferenceNumber ? 'guaranteeReferenceNumber' : (guaranteeData.otherReference ? 'otherReference' : null)"
                            [disabled]="!isEditable()"
                            placeholder="– Select a transit guarantee –" [required]="!isTemplate"
                            id="transitguarantee">
                </app-select>
              </app-form-group>
              <app-form-group *ngIf="guaranteeData.guaranteeReferenceNumber" label="Access code">
                <input class="form-control" readonly [(ngModel)]="guaranteeData.accessCode">
              </app-form-group>
            </div>
            <div class="col-md">
              <app-form-group label="Customs amount">
                <div class="input-group">
                  <div class="me-1">
                    <app-select [ngModel]="guaranteeData.customsValue?.currency"
                                (ngModelChange)="guaranteeData.customsValue = (guaranteeData.customsValue || $any({})); guaranteeData.customsValue.currency = $event"
                                dataKey="unCode" [required]="!!guaranteeData.customsValue?.amount"
                                [optionsProvider]="refData.getCurrencies"
                                [disabled]="!isEditable()"
                                [formatter]="refData.currenciesFormatter"
                                [selectedFormatter]="refData.compactCurrenciesFormatter"
                                style="max-width: 5rem">
                    </app-select>
                  </div>
                  <input [ngModel]="guaranteeData.customsValue?.amount" placeholder="Balance amount"
                         (ngModelChange)="guaranteeData.customsValue = (guaranteeData.customsValue || $any({})); guaranteeData.customsValue.amount = $event"
                         [required]="!!guaranteeData.customsValue?.currency"
                         [readonly]="!isEditable()"
                         appDecimalNumber [maxDecimals]="2" class="form-control rounded">
                </div>
              </app-form-group>
              <app-form-group *ngIf="guaranteeData && guaranteeData.country" label="Country">
                <div class="input-group">
                  <div class="me-1">
                    <input class="form-control" readonly [(ngModel)]="guaranteeData.country.code">
                  </div>
                  <input class="form-control" readonly [(ngModel)]="guaranteeData.country.name">
                </div>
              </app-form-group>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="row align-items-center" *ngIf="isEditable()">
    <div class="col-auto ms-auto">
      <ul class="nav">
        <li class="nav-item">
          <a href="#" class="nav-link p-0" (click)="$event.preventDefault(); addGuarantee()">
            <span class="pe-2 fa fa-plus-circle"></span>Add transit guarantee</a>
        </li>
      </ul>
    </div>
  </div>
  <div>
    <div class="declaration-block my-4">
      <h2>notifications</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-md">
      <app-form-group>
        <label>MRN sent to terminal</label>
        <input class="form-control" placeholder="Enter e-mail address"
               [readonly]="!isEditable()"
               [(ngModel)]="data.emailAddressMrnToTerminal">
      </app-form-group>
      <app-form-group>
        <label>MRN sent to terminal (copy)</label>
        <app-multi-input [(ngModel)]="data.emailAddressesMrnToTerminalForCopy"
                         [disabled]="!isEditable()"
                         placeholder="Enter e-mail address"></app-multi-input>
      </app-form-group>
      <app-form-group>
        <label>Container is in transit</label>
        <input class="form-control" placeholder="Enter e-mail address"
               [readonly]="!isEditable()"
               [(ngModel)]="data.emailAddressContainerInTransit">
      </app-form-group>
      <app-form-group>
        <label>Container is in transit (copy)</label>
        <app-multi-input [(ngModel)]="data.emailAddressesContainerInTransitForCopy"
                         [disabled]="!isEditable()"
                         placeholder="Enter e-mail address"></app-multi-input>
      </app-form-group>
    </div>
    <div class="col-md">
      <app-form-group>
        <label>Transport term expires
          <app-info [wide]="true">
            <span class="tooltipContent text-start">
              Email for notification when the transport term expires within the number of days given below
            </span>
          </app-info>
        </label>
        <app-multi-input [(ngModel)]="data.emailAddressesForExpiryReminder"
                         [disabled]="!isEditable()"
                         placeholder="Enter e-mail address"></app-multi-input>
      </app-form-group>
      <app-form-group label="Reminder number of days before expiry">
        <input class="form-control"
               [readonly]="!isEditable()"
               [required]="data.emailAddressesForExpiryReminder && data.emailAddressesForExpiryReminder.length > 0"
               type="number" [(ngModel)]="data.expiryReminderNumberOfDaysBefore"/>
      </app-form-group>
    </div>
  </div>
  <ng-template #newTransitParty let-transitParty="model">
    <app-transit-party [model]="transitParty"></app-transit-party>
  </ng-template>
  <!--  {{data|json}}-->
</div>
