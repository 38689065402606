<div *ngIf="model.guaranteeData as data">
  <div class="declaration-block mt-1 row justify-content-end">
    <app-guarantee-admin class="row narrow-gutters mb-2" *ngIf="appContext.isAdmin()"
                         [guarantee]="model"></app-guarantee-admin>
    <div class="col col-auto text-end">
      <div class="btn-group">
        <button (click)="save()" class="btn btn-outline-info" tabindex="-1">Save</button>
        <button *ngIf="model.status !== 'DECLARED'" type="button" class="btn btn-outline-info dropdown-toggle dropdown-toggle-split"
                data-bs-toggle="dropdown"></button>
        <div *ngIf="model.status !== 'DECLARED'" class="dropdown-menu dropdown-menu-end">
          <button class="dropdown-item text-danger" data-bs-toggle="modal" data-bs-target="#cancelTransit"><i
            class="fa fa-trash" aria-hidden="true"></i> Delete
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="ms-4 position-relative">
    <app-tab-panel>
      <app-tab-item [templateRef]="detailsRef">
        <span class="tab-item" [ngClass]="{'ng-invalid text-invalid' : !!model['ngDetailsInvalid']}">Guarantee details</span>
        <ng-template #detailsRef>
          <div class="p-3 border border-top-0 rounded-bottom" [appValidator]="model"
               validatorProperty="ngDetailsInvalid">
            <div class="row">
              <div class="col">
                <app-form-group label="Type">
                  <app-select required [disabled]="!!model.status" [(ngModel)]="data.type"
                              [refreshProvider]="refData.getGuaranteeTypes" dataKey="code"
                              [formatter]="refData.guaranteeTypeFormatter"
                              placeholder="– Select a guarantee type –"
                              class="input-group">
                  </app-select>
                </app-form-group>
                <app-form-group *ngIf="requiresGrn | async" label="Reference number">
                  <input class="form-control" [disabled]="!!model.status" required
                         [(ngModel)]="data.guaranteeReferenceNumber"
                         (change)="data.guaranteeReferenceNumber=$event.target.value.toUpperCase()">
                </app-form-group>
                <app-form-group *ngIf="canHaveOtherReference | async" label="Other reference">
                  <input class="form-control" [disabled]="!!model.status" [(ngModel)]="data.otherReference"
                         (change)="data.otherReference=$event.target.value.toUpperCase()" [required]="requiresOtherReference | async">
                </app-form-group>
                <app-form-group *ngIf="requiresGrn | async" label="Access code">
                  <input class="form-control" required [disabled]="!!model.status" [(ngModel)]="data.accessCode"
                         (change)="data.accessCode=$event.target.value.toUpperCase()">
                </app-form-group>
              </div>
              <div class="col">
                <app-form-group label="Country">
                  <app-search required [(ngModel)]="data.country" [searchFunction]="refData.findCountries"
                              dataKey="name" [resultFormatter]="refData.countryFormatter"
                              [appDefaultValue]="{code: 'NL', name: 'Netherlands'}">
                  </app-search>
                </app-form-group>
                <app-form-group label="Customs amount">
                  <div class="input-group">
                    <div class="me-1">
                      <app-select [ngModel]="data.customsValue?.currency"
                                  (ngModelChange)="data.customsValue = (data.customsValue || $any({})); data.customsValue.currency = $event"
                                  dataKey="unCode" [required]="!!data.customsValue?.amount"
                                  [optionsProvider]="refData.getCurrencies"
                                  [formatter]="refData.currenciesFormatter"
                                  [selectedFormatter]="refData.compactCurrenciesFormatter"
                                  style="max-width: 5rem" [appDefaultValue]="{unCode: 'EUR', description: 'Euro'}">
                      </app-select>
                    </div>
                    <input [ngModel]="data.customsValue?.amount" placeholder="Customs amount"
                           (ngModelChange)="data.customsValue = (data.customsValue || $any({})); data.customsValue.amount = $event"
                           [required]="!!data.customsValue?.currency"
                           appDecimalNumber [maxDecimals]="2" class="form-control rounded">
                  </div>
                </app-form-group>
                <app-form-group label="Valid from">
                  <app-date-field required [(ngModel)]="data.validFrom"
                                 dateOnly="true" [calendarStartDate]="data.validFrom"></app-date-field>
                </app-form-group>
                <app-form-group label="Valid to">
                  <app-date-field [(ngModel)]="data.validTo" dateOnly="true"
                                 [calendarStartDate]="data.validTo"></app-date-field>
                </app-form-group>
              </div>
            </div>
            <div class="col text-end" *ngIf="sendable | async">
              <button [disabled]="(model.balanceQueryCount >= 5 && lastQueryDateIsToday) || model.status === 'DECLARED' || expired"
                      (click)="requestBalance()" id="request_balance" type="button"
                      class="btn btn-info" tabindex="-1">Request balance
              </button>
            </div>
          </div>
        </ng-template>
      </app-tab-item>
      <app-tab-item *ngIf="model.status" [templateRef]="balanceDetailsRef">
        <span class="tab-item" [ngClass]="{'ng-invalid text-invalid' : !!model['ngGoodsInvalid']}">Balance details</span>
        <ng-template #balanceDetailsRef>
          <div class="p-3 border border-top-0 rounded-bottom" [appValidator]="model"
               validatorProperty="ngDetailsInvalid">
            <div *ngIf="sendable | async" class="row">
              <div class="col">
                <app-form-group *ngIf="model.balance" label="Balance amount">
                  <div class="input-group">
                    <div class="me-1">
                      <app-select disabled [ngModel]="model.balance.currency" [nullOption]="true"
                                  dataKey="unCode" [required]="!!model.balance?.amount"
                                  [optionsProvider]="refData.getCurrencies"
                                  [formatter]="refData.currenciesFormatter"
                                  [selectedFormatter]="refData.compactCurrenciesFormatter"
                                  style="max-width: 5rem">
                      </app-select>
                    </div>
                    <input disabled [ngModel]="model.balance.amount" placeholder="Balance amount"
                           [required]="!!model.balance?.currency"
                           appDecimalNumber [maxDecimals]="2" class="form-control rounded">
                  </div>
                </app-form-group>
              </div>
              <div class="col">
                <app-form-group label="Last balance query">
                  <app-date-field disabled [ngModel]="model.timestamp" defaultTime="12:00"
                                 [calendarStartDate]="model.timestamp"></app-date-field>
                </app-form-group>
                <app-form-group label="Balance query count">
                  <div class="input-group">
                    <input class="form-control" disabled [(ngModel)]="model.balanceQueryCount" min="0"
                           [appDefaultValue]="0" type="number">
                    <span class="input-group-text">#</span>
                  </div>
                </app-form-group>
              </div>
            </div>
          </div>
        </ng-template>
      </app-tab-item>
      <app-tab-item [templateRef]="historyRef">
        <span class="tab-item">History</span>
        <ng-template #historyRef>
          <div class="p-3 border border-top-0 rounded-bottom">
            <app-transit-guarantee-history [id]="model.id"></app-transit-guarantee-history>
          </div>
        </ng-template>
      </app-tab-item>
    </app-tab-panel>
  </div>
</div>
<div id="cancelTransit" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Delete transit guarantee</h5>
      </div>
      <div class="modal-body">
        <p>Are you sure that you want to delete this transit guarantee?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">No
        </button>
        <button type="button" class="btn btn-primary" (click)="delete()" data-bs-dismiss="modal">Yes
        </button>
      </div>
    </div>
  </div>
</div>
