import {Injectable} from '@angular/core';
import {QueryHandler} from '../common/query-handler';
import pilotStations from '@portbase/bezoekschip-service-typescriptmodels/refdata/pilotstations.json';
import accessPoints from '@portbase/bezoekschip-service-typescriptmodels/refdata/accesspoints.json';
import clearanceAttentionPoints
  from '@portbase/bezoekschip-service-typescriptmodels/refdata/clearance-attention-points.json';
import guaranteeTypes from '@portbase/bezoekschip-service-typescriptmodels/refdata/transit-guaranteetypes.json';
import transitTypes from '@portbase/bezoekschip-service-typescriptmodels/refdata/transit-declarationtypes.json';
import transitPreviousDocumentTypes
  from '@portbase/bezoekschip-service-typescriptmodels/refdata/transit-previousdocumenttypes.json';
import transitTransportDocumentTypes
  from '@portbase/bezoekschip-service-typescriptmodels/refdata/transit-transportdocumenttypes.json';
import transitProducedDocumentTypes
  from '@portbase/bezoekschip-service-typescriptmodels/refdata/transit-produceddocumenttypes.json';
import customsOffices from '@portbase/bezoekschip-service-typescriptmodels/refdata/customsoffices.json';
import transitOffices from '@portbase/bezoekschip-service-typescriptmodels/refdata/transit-offices.json';
import isscIssuers from '@portbase/bezoekschip-service-typescriptmodels/refdata/isscissuers.json';
import pilots from '@portbase/bezoekschip-service-typescriptmodels/refdata/pilots.json';
import tugboats from '@portbase/bezoekschip-service-typescriptmodels/refdata/tugboats.json';
import boatmen from '@portbase/bezoekschip-service-typescriptmodels/refdata/boatmen.json';
import wasteCollectors from '@portbase/bezoekschip-service-typescriptmodels/refdata/wastecollectors.json';
import wasteTypesSsn from '@portbase/bezoekschip-service-typescriptmodels/refdata/waste-types-ssn.json';
import packageTypes from '@portbase/bezoekschip-service-typescriptmodels/refdata/packagetypes.json';
import cargoPackageTypes from '@portbase/bezoekschip-service-typescriptmodels/refdata/cargopackagetypes.json';
import radionuclides from '@portbase/bezoekschip-service-typescriptmodels/refdata/radionuclides.json';
import currencies from '@portbase/bezoekschip-service-typescriptmodels/refdata/currencies.json';
import countries from '@portbase/bezoekschip-service-typescriptmodels/refdata/countries.json';
import euralCodes from '@portbase/bezoekschip-service-typescriptmodels/refdata/euralcodes.json';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {
  BezoekschipOrganisation,
  CargoPackageType,
  CertifyingOrganisation,
  FindRadionuclides,
  GetCargoPackageTypes,
  GetCountries,
  GetCurrencies,
  GetCustomsOffices,
  GetPackageTypes,
  GetTransitOffices,
  GetTransitOfficesOfDeparture,
  GetWasteCollectors,
  PackageType,
  PilotStation
} from '@portbase/bezoekschip-service-typescriptmodels';
import {cloneObject, lodash} from '../common/utils';
import {ComparatorChain} from '../common/comparator-chain';
import {AppContext} from "../app-context";


@Injectable()
export class HardcodedRefdataQueryHandler extends QueryHandler {

  constructor() {
    super();
  }

  'com.portbase.bezoekschip.common.api.visit.GetCustomsOffices' = (query: GetCustomsOffices) => {
    return query && query.countryCode ?
      of(customsOffices.filter(customsOffice => customsOffice['unCode'].substring(0, 2) === query.countryCode)) :
      of(customsOffices);
  };

  'com.portbase.bezoekschip.common.api.visit.GetTransitOfficesOfDeparture' = (query: GetTransitOfficesOfDeparture) => {
    return of(transitOffices.filter(office => office['unCode'].indexOf("NL") !== -1));
  };

  'com.portbase.bezoekschip.common.api.visit.GetTransitOffices' = (query: GetTransitOffices) => {
    return of(transitOffices);
  };

  'com.portbase.bezoekschip.common.api.visit.GetCountriesWithCustomsOffices' = () => {
    const unCodes = lodash.uniq(customsOffices.map(office => office['unCode'].substring(0, 2)));
    return of(lodash.sortBy(countries.filter(country => unCodes.indexOf(country.code) !== -1), c => c.name));
  };

  'com.portbase.bezoekschip.common.api.visit.GetGuaranteeTypes' = () => {
    return of(guaranteeTypes);
  };

  'com.portbase.bezoekschip.common.api.visit.GetTransitTypes' = () => {
    return of(transitTypes);
  };

  'com.portbase.bezoekschip.common.api.visit.GetTransitPreviousDocumentTypes' = () => {
    return of(transitPreviousDocumentTypes);
  };

  'com.portbase.bezoekschip.common.api.visit.GetTransitTransportDocumentTypes' = () => {
    return of(transitTransportDocumentTypes);
  };

  'com.portbase.bezoekschip.common.api.visit.GetTransitProducedDocumentTypes' = () => {
    return of(transitProducedDocumentTypes);
  };

  'com.portbase.bezoekschip.common.api.visit.GetWasteTypesSsn' = (query) => {
    return of(wasteTypesSsn);
  };

  'com.portbase.bezoekschip.common.api.waste.GetEuralCodes' = (query) => {
    return of(euralCodes[query.wasteType]);
  };

  'com.portbase.bezoekschip.common.api.visit.GetPilotStations' = (query) => {
    if (query.isOrderIncomingMovement) {
      return of(pilotStations[query.portUnCode].filter((pilotstation: PilotStation) => {
        return pilotstation.atSea === query.atSea;
      }));
    } else {
      return of(pilotStations[query.portUnCode]);
    }
  };
  'com.portbase.bezoekschip.common.api.visit.GetAccessPoints' = (query) => {
    const accessPointList = accessPoints[query.portUnCode];

    if (!accessPointList) {
      return of([]);
    } else {
      return of(accessPointList.filter(accesspoint => {
        if (query.atSea === null || query.atSea === undefined) {
          return true;
        } else {
          return accesspoint.atSea === undefined || accesspoint.atSea === query.atSea;
        }
      }));
    }
  };

  'com.portbase.bezoekschip.common.api.visit.GetEtaLocations' = (query) => {
    return of({
      pilotStations: pilotStations[query.portUnCode],
      entryPoints: accessPoints[query.portUnCode]
    });
  };

  'com.portbase.bezoekschip.common.api.security.GetIsscIssuers' = (query) => {
    return of(isscIssuers);
  };

  'com.portbase.bezoekschip.common.api.security.FindIsscIssuer' = (query) => {
    return of(isscIssuers).pipe(map((issuers: CertifyingOrganisation[]) => {
      const matchingValues = issuers.filter(i => i.name === query.issuerName);
      return matchingValues && matchingValues.length > 0 ? matchingValues[0] : null;
    }));
  };

  'com.portbase.bezoekschip.common.api.visit.GetPilots' = (query) => {
    return of(pilots[query.portUnCode]);
  };

  'com.portbase.bezoekschip.common.api.visit.GetTugboats' = (query) => {
    return of(tugboats[query.portUnCode]);
  };

  'com.portbase.bezoekschip.common.api.visit.GetBoatmen' = (query) => {
    return of(boatmen[query.portUnCode]);
  };

  'com.portbase.bezoekschip.common.api.waste.GetWasteCollectors' = (query: GetWasteCollectors): Observable<BezoekschipOrganisation[]> => {
    return of(wasteCollectors).pipe(map((wasteCollectors: any[]) => {
      const allOrganisations: BezoekschipOrganisation[] = [];
      const organisationsForAnnex: BezoekschipOrganisation[] = [];
      let environment = AppContext.environment != "localhost" ? AppContext.environment : "prod" // use the prod config for local testing
      wasteCollectors.filter(w => w["environments"].includes(environment)).forEach(w => {
        const id = w.authorityIdPerPort[query.portUnCode];
        if (id) {
          const organisation = <BezoekschipOrganisation>cloneObject(w.organisation);
          organisation.portAuthorityId = id;
          allOrganisations.push(organisation);
          const annexes: number[] = w.annexPerPort[query.portUnCode];
          if (annexes && annexes.indexOf(query.annex) >= 0) {
            organisationsForAnnex.push(organisation);
          }
        }
      });
      return organisationsForAnnex.length > 0 ? organisationsForAnnex : allOrganisations;
    }));
  };


  'com.portbase.bezoekschip.common.api.waste.GetWasteCollector' = (query) => {
    return of(wasteCollectors).pipe(map(wasteCollectors => {
      return wasteCollectors.filter(v => v.organisation.shortName === query.collectorShortName)[0];
    }));
  };

  'com.portbase.bezoekschip.common.api.visit.GetClearanceAttentionPointTypes' = (query) => {
    return of(clearanceAttentionPoints);
  };

  'com.portbase.bezoekschip.common.api.visit.GetPackageTypes' = (query: GetPackageTypes) => {
    const bulk = ["VY", "VR", "VO", "VS", "VQ", "VG", "VL"];
    const unpacked = ["NE", "NF", "NG"];
    return of(packageTypes).pipe(tap((values: PackageType[]) => {
      values.forEach(v => v.bulk = bulk.indexOf(v.code) >= 0);
      values.forEach(v => v['unpacked'] = unpacked.indexOf(v.code) >= 0);
      values.sort(new ComparatorChain('-bulk', 'code').compare);
    }));
  };

  'com.portbase.bezoekschip.common.api.visit.GetCargoPackageTypes' = (query: GetCargoPackageTypes) => {
    const bulk = ["VY", "VR", "VO", "VS", "VQ", "VG", "VL"];
    return of(cargoPackageTypes).pipe(tap((values: CargoPackageType[]) => {
      values.forEach(v => v.bulk = bulk.indexOf(v.code) >= 0);
      values.sort(new ComparatorChain('-bulk', 'code').compare);
    }));
  };

  'com.portbase.bezoekschip.common.api.visit.GetCurrencies' = (query: GetCurrencies) => {
    return of(lodash.sortBy(currencies, c => c.unCode));
  };

  'com.portbase.bezoekschip.common.api.visit.GetCountries' = (query: GetCountries) => {
    return of(lodash.sortBy(countries, c => c.name));
  };

  'com.portbase.bezoekschip.common.api.dangerousgoods.FindRadionuclides' = (query: FindRadionuclides) => {
    return of(radionuclides.filter(nuke => nuke.name.toLocaleLowerCase().includes(query.term.trim().toLocaleLowerCase())));
  };
}
