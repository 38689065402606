<div class="row">
  <div class="col-md-6">
    <app-form-group>
      <label>Select tanks</label>
      <app-multiselect [(ngModel)]="handling.tankNumbers" [options]="handling.availableTanks"
                       [hideSelected]="true" [enableSelectAll]="true" [autoSelectOnlyOption]="true"
                       placement="top-end" title="Select tanks"></app-multiselect>
    </app-form-group>
    <app-form-group>
      <label>Start date and time</label>
      <app-date-field [ngClass]="{'was-validated': startDateEntered}" required
                     [(ngModel)]="handling.timestamp"
                     (blur)="blurStartDate()"></app-date-field>
    </app-form-group>
    <app-form-group *ngIf="ventilationBeforeArrivalAtPortOrBerth()" class="ng-invalid was-validated">
      <span *ngIf="ventilationBeforeArrivalAtPort()" class="ng-invalid invalid-feedback mb-2 mt-0">Ventilation start date
        should be after expected arrival visit
      </span>
      <span *ngIf="ventilationBeforeArrivalAtBerth()" class="ng-invalid invalid-feedback mb-2 mt-0">Ventilation start date
        should be after expected arrival at berth
      </span>
    </app-form-group>
    <ng-container *ngIf="context.startsHandlingAfterDepartureBerthOrPort(handling.timestamp, berthVisit.etd)">
      <div class="alert alert-warning show row align-items-center" role="alert">
        <div class="col-1 ps-2">
          <i class="fa fa-light fa-circle-exclamation" aria-hidden="true" style="font-size: xxx-large"></i>
        </div>
        <div class="col-11">
          <b>Are you sure?</b>
          <p *ngIf="afterEtdBerth()">Ventilation should preferably start before departure from berth.</p>
          <p *ngIf="!afterEtdBerth()">Ventilation should preferably start before departure visit.</p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="col-md-6">
    <app-form-group>
      <label>Remarks</label>
      <textarea [(ngModel)]="handling.remarks" class="form-control"></textarea>
    </app-form-group>
  </div>
</div>
