<ng-container *ngIf="useCountryObject else asCountryCode">
  <app-form-field label="Country" [renderOptional]="renderOptional" [editMode]="editMode"
                  [value]="country?.name" [formatter]="countryFormatter" [includeMargin]="includeMargin">
    <app-search [(ngModel)]="country" [searchFunction]="portVisitUtils.findCountries"
                [inputFormatter]="countryFormatter" [resultFormatter]="countryFormatter"
                (ngModelChange)="onCountryChange($event)" [required]="required"
                placeholder="Find country by name or country code">
    </app-search>
  </app-form-field>
</ng-container>
<ng-template #asCountryCode>
  <app-form-field label="Country" [renderOptional]="renderOptional" [editMode]="editMode"
                  [(value)]="countryCode" [formatter]="countryFormatter" [includeMargin]="includeMargin">
    <app-search [(ngModel)]="countryCode" [searchFunction]="portVisitUtils.findCountries"
                [inputFormatter]="countryFormatter" [resultFormatter]="countryFormatter"
                (ngModelChange)="onCountryChange($event)" [required]="required"
                placeholder="Find country by name or country code">
    </app-search>
  </app-form-field>
</ng-template>
