import {release} from "../release";
import {Component, ViewEncapsulation} from '@angular/core';
import {AppContext} from './app-context';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {sendQuery} from './common/utils';
import {CookieService} from 'ngx-cookie';
import * as Sentry from '@sentry/browser';
import {VisitContext} from './visit-details/visit-context';
import {Visit} from '@portbase/bezoekschip-service-typescriptmodels';
import {environment} from '../environments/environment';
import newCargoScreenSettings from "./visit-details/new-cargo-screen-settings.json";
import lodash from "lodash";

declare var Beamer;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  env = environment;
  s000Url = AppComponent.getS000Url();
  appContext = AppContext;
  visitContext = VisitContext;
  pcsDown: boolean;
  private beamerFilter = new Set();
  useNewLookAndFeel: boolean;

  constructor(route: ActivatedRoute, private router: Router, private cookieService: CookieService) {
    this.useNewLookAndFeel = AppContext.useNewLookAndFeel();
    AppComponent.startSentry();

    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.setApplicationContext(e);

        AppContext.clearAlerts();
        AppContext.setSupportPageForTask();

        if ((!AppContext.isSystemlink && !AppContext.userProfile) || (AppContext.isSystemlink && this.env.production)) {
          const jwt = router.parseUrl(e.url).queryParamMap.get('jwt');
          if (jwt) {
            cookieService.put("portvisitjwt", jwt);
            router.navigate([], {
              queryParams: {
                jwt: null
              },
              queryParamsHandling: 'merge'
            });
          } else {
            this.routeBasedOnUserProfile(router);
          }
        }
      }
    });
  }

  private setApplicationContext(e: NavigationEnd) {
    this.beamerFilter = new Set(['transit-declaration', 'portvisit', 'melding-schip', 'mli', 'pcs', 'all']);

    Beamer.update({
      user_id: "melding-schip_" + (this.appContext.userProfile?.iamConnectedUserId || this.appContext.userProfile?.userName || "unknown"),
      filter: [...this.beamerFilter].join(';')
    });

    AppContext.isSystemlink = e.url?.startsWith("/systemlink");
  }

  private static startSentry() {
    if (AppContext.environment) {
      Sentry.init({
        release: release,
        environment: AppContext.environment === 'pcs' ? 'prod' : AppContext.environment,
        dsn: environment.sentryUrl
      });
    }
  }

  private routeBasedOnUserProfile = (router: Router, initialPageLoad = true) => {
    sendQuery('com.portbase.bezoekschip.common.api.authorisation.GetUserProfile', {},
      {caching: false, showSpinner: !AppContext.userProfile, hideError: !!AppContext.userProfile}).subscribe(profile => {
      AppContext.userProfile = profile;
      const environmentSettings: { [key: string]: string[] } = newCargoScreenSettings[AppContext.environment] || {};
      if (environmentSettings[AppContext.userProfile?.organisation?.shortName] != null) {
        if (localStorage.getItem("useNewLookAndFeel") != "true") {
          localStorage.setItem("useNewLookAndFeel", "true");
          window.location.reload();
        }
      }
      Sentry.configureScope(function(scope) {
        scope.setUser({
          username: AppContext.userProfile.userName,
          email: AppContext.userProfile.organisation?.emailAddress,
          contact: AppContext.userProfile.organisation?.contact,
          organisationShortName: AppContext.userProfile.organisation?.shortName
        });
      });
			if (initialPageLoad) {
        if (!AppContext.isAllowedToViewVisits()) {
          if (AppContext.isWasteCollector()) {
            AppContext.navigateWithHistory(router,'/waste');
          }
        }
        if (AppContext.isSystemlink) {
          AppContext.navigateWithHistory(router, "/");
        }
			}
      setTimeout(() => this.routeBasedOnUserProfile(router, false), 290_000); //refresh every ±5 minute
    }, error => {
      if (error.status === 401) {
        const headers = error.headers;
        if (headers && headers.get("pcs-down") === 'true') {
          this.onPcsDown();
        } else {
          this.redirectToLoginPage();
        }
      } else {
        setTimeout(() => this.routeBasedOnUserProfile(router), 1_000);
      }
    });
  };

  private onPcsDown() {
    this.pcsDown = true;
  }

  private redirectToLoginPage() {
    if (environment.production) {
      window.location.href = '/api/login';
    }
    else if(environment.docker){
      window.location.href = 'http://localhost:8085/api/login';
    }
  }

  goToBargeRail() {
    window.location.href = AppContext.environment ? 'https://hcn.' + AppContext.environment + '.portbase.com' : 'http://www.nu.nl';
  }

  goToRoad() {
    window.location.href = AppContext.environment ? 'https://hcn.' + AppContext.environment + '.portbase.com/road' : 'http://www.nu.nl';
  }

  logOut = () => {
    if (environment.production) {
      window.location.href = '/api/logout';
    }
    else if(environment.docker){
      window.location.href = 'http://localhost:8085/api/logout';
    }
  }

  private static getS000Url() {
    return AppContext.environment ? 'https://' + AppContext.environment + '.portbase.com' : 'http://wpcs.portbase.test';
  }

  get visit(): Visit {
    return this.visitContext.visit;
  }

  get cargoScreenEnabled(): boolean {
    const enabledForEnvironment = AppContext.isAdmin()
      || (AppContext.isCargoImportViewer() && AppContext.cargoDeclarantUsesNewCargoScreen(AppContext.userProfile.organisation.shortName))
      || AppContext.environment === "kt";
    return enabledForEnvironment && AppContext.useNewLookAndFeel();
  }

  changeLookAndFeel = (useNewLookAndFeel: boolean) => {
    this.useNewLookAndFeel = useNewLookAndFeel;
    if (useNewLookAndFeel) {
      localStorage.setItem("useNewLookAndFeel", "true");
    } else {
      localStorage.setItem("useNewLookAndFeel", "false");
    }
    setTimeout(() => window.location.reload(), 100);
  }
}
