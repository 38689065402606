import {Component, ComponentRef, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef,} from "@angular/core";
import {BerthVisit, BerthVisitInfo, TerminalVisit} from "@portbase/bezoekschip-service-typescriptmodels";
import {LabeledDateTimeComponent} from "./labeled-date-time.component";
import {DateFieldComponent} from "../../../../common/date/date-field/date-field.component";
import {BerthVisitDetailsComponent, DateTimeEntry} from "./berth-visit-details.component";
import {VisitContext} from "../../../visit-context";
import moment from "moment/moment";
import {BunkeringEtcComponent} from "./bunkering-etc.component";
import {RegisterAtaComponent} from "../../locations/edit-berth/register-ata/register-ata.component";
import {RegisterAtdComponent} from "../../locations/edit-berth/register-atd/register-atd.component";
import {AppContext} from "../../../../app-context";
import {roundToNearestQuarterMoment} from "../../../../common/date/time-utils";

@Component({
  selector: 'app-berth-visit-times',
  templateUrl: './berth-visit-times.component.html'
})
export class BerthVisitTimesComponent implements OnInit {
  index = 1;

  @Input("berthVisit") berthVisit: BerthVisit;
  @Input("terminalVisit") terminalVisit: TerminalVisit;
  @Input("firstBerth") firstBerth: boolean;
  @Input() isBerthOfTransfer: boolean;

  // Desperately need {read, static}
  @ViewChild("container", {read: ViewContainerRef, static: true}) container: ViewContainerRef;
  @ViewChild("eta", {read: TemplateRef, static: true}) etaTemplate: TemplateRef<any>;
  @ViewChild("etd", {read: TemplateRef, static: true}) etdTemplate: TemplateRef<any>;
  @ViewChild("lastEtd", {read: TemplateRef, static: true}) lastEtdTemplate: TemplateRef<any>;

  oldEta: string;
  oldEtdSet: boolean = false;  // etd and therefore oldEtd can be null
  oldEtd: string;
  private planningBasedForced: boolean = false;

  constructor(private appRef: ViewContainerRef) {
  }

  ngOnInit() {
    let entries: DateTimeEntry[] = []

    let etaInfo = this.basedOnFirstLine() ? "Estimated Time of Arrival, based on 1st line secured " : "Estimated Time of Arrival";
    if (VisitContext.planningBasedOnPilotBoarding()) {
      etaInfo = this.basedOnFirstLine() ? etaInfo : "Estimated Time of Arrival, based on ETA pilot boarding place"
    };
    entries.push({
      dateTimeTemplate: this.etaTemplate,
      id: this.berthVisit.id + "-eta",
      label: "Estimated Arrival",
      info: etaInfo,
      time: this.berthVisit.eta,
      onChanged: this.onEtaChanged,
      disabled: this.editEtaDisabled,
      required: () => this.isZeelandPort() || this.isPlanningBasedOnFirstBerth() || this.berthVisit.terminalPlanningEnabled,
    })

    if (this.berthVisit && (this.isAtaAtdModifiable() || this.berthVisit.ata)) entries.push({
      dateTimeType: RegisterAtaComponent,
      id: this.berthVisit.id + "-ata",
      label: "Actual Arrival",
      info: "Actual Time of Arrival",
      time: this.berthVisit.ata,
      disabled: !this.isAtaAtdModifiable(),
      required: () => false,
      init: (instance: ComponentRef<RegisterAtaComponent>) => {
        instance.setInput("berthVisit", this.berthVisit);
        instance.setInput("id", this.berthVisit.id + "-register-ata");
      }
    })

    if (this.haveTerminalRta) entries.push({
      dateTimeType: DateFieldComponent,
      id: this.berthVisit.id + "-rta",
      label: "Requested Arrival",
      subLabel: this.terminalVisit.organisationShortName,
      info: "Requested Time of Arrival, based on 1st line secured",
      time: this.terminalVisit.info.rta,
      readonly: true,
      required: () => false
    });

    if (this.haveTerminalEtc) entries.push({
      dateTimeType: DateFieldComponent,
      id: this.berthVisit.id + "etc",
      label: "Estimated Completion",
      subLabel: this.terminalVisit.organisationShortName,
      info: "Estimated Time of Completion, based on 1st line released",
      time: this.terminalVisit.info.etc,
      readonly: true,
      required: () => false
    });
    this.bunkerVisits().filter(bv => !bv.cancelled).forEach(bv => {
      entries.push({
        dateTimeType: DateFieldComponent,
        id: "bunker-ets-" + bv.bunkeringId,
        label: "Estimated Start",
        subLabel: bv.license.bunkerVessel.name,
        info: "Estimated Time of Starting of Bunkering, " + bv.operator.fullName,
        time: bv.ets,
        readonly: true,
        required: () => false
      });
      if (bv.atc) {
        entries.push({
          dateTimeType: DateFieldComponent,
          id: "bunker-atc-" + bv.bunkeringId,
          label: "Actual Completion",
          subLabel: bv.license.bunkerVessel.name,
          info: "Actual Time of Completion, " + bv.operator.fullName,
          time: bv.atc,
          readonly: true,
          required: () => false
        })
      } else {
        entries.push({
          dateTimeType: BunkeringEtcComponent,
          id: "bunker-etc-" + bv.bunkeringId,
          label: "Estimated Completion",
          subLabel: bv.license.bunkerVessel.name,
          info: "Estimated Time of Completion of Bunkering, " + bv.operator.fullName,
          time: bv.etc,
          readonly: true,
          required: () => false,
          init: (instance: ComponentRef<BunkeringEtcComponent>) => {
            instance.setInput("bunkerVisit", bv);
            instance.setInput("berthVisit", this.berthVisit);
          }
        });
      }
    })

    if (this.pilotOnBoard) {
      entries.push({
        dateTimeType: DateFieldComponent,
        id: this.berthVisit.id + "-pilotOnBoardDeparture",
        label: "Pilot on board",
        time: this.getBerthVisitInfo().harbourMasterInfo.pilotOnBoard,
        readonly: true,
        required: () => false
      });
    }

    if (this.isBerthOfTransfer && VisitContext.isOrganisationNextDeclarant()) {
      entries.push({
        dateTimeTemplate: this.lastEtdTemplate,
        id: "lastBerthVisit",
        label: "Estimated Departure",
        classes: "always-enabled validate-for-next-declarant",
        disabled: !!this.berthVisit.atd,
        required: () => this.berthVisit.nextMovement.order,
        info: this.basedOnFirstLine() ? "Estimated Time of Departure, based on 1st line released" : "Estimated Time of Departure",
        time: this.berthVisit.etd,
      })
    } else {
      entries.push({
        dateTimeTemplate: this.etdTemplate,
        id: this.berthVisit.id + "-etd",
        label: "Estimated Departure",
        classes: "fieldset",
        required: () => this.berthVisit.nextMovement.order,
        info: this.basedOnFirstLine() ? "Estimated Time of Departure, based on 1st line released" : "Estimated Time of Departure",
        time: this.berthVisit.etd,
      })
    }

    if (this.berthVisit && (this.isAtaAtdModifiable() || this.berthVisit.atd)) entries.push({
      dateTimeType: RegisterAtdComponent,
      id: this.berthVisit.id + "-atd",
      label: "Actual Departure",
      info: "Actual Time of Departure",
      time: this.berthVisit.atd,
      disabled: !this.isAtaAtdModifiable(),
      required: () => false,
      init: (instance: ComponentRef<RegisterAtdComponent>) => {
        instance.setInput("berthVisit", this.berthVisit);
        instance.setInput("id", this.berthVisit.id + "-register-ata");
      }
    })

    const compareDate = (d1, d2) => (d1 === d2 ? 0 : d1 > d2 ? 1 : -1)
    let sortedEntries = entries.sort((e1, e2) => e1.time && e2.time ? compareDate(e1.time, e2.time) : 0);
    sortedEntries.forEach((entry, index) => {
      // Appends in the container, so elements come *after* <app-berth-visit-times/>
      let dateField = this.appRef.createComponent(LabeledDateTimeComponent);
      entry.columns = "grid-template-columns: auto 1fr;";
      dateField.instance.info = entry;
      dateField.instance.index = index + 1;
    });
  }

  private basedOnFirstLine() {
    return VisitContext.isVisitingRotterdam() && AppContext.showFirstLine();
  }

  isAtaAtdModifiable = () => {
    return VisitContext.isAtaAtdModifiable()
  };

  get editEtaDisabled(): boolean {
    return !!(this.berthVisit.ata || this.berthVisit.atd || (
      this.firstBerth && (VisitContext.planningBasedOnPilotBoarding() || VisitContext.isWaitingForOrders())
    ))
  }

  get editEtdDisabled(): boolean {
    return !!this.berthVisit.atd
  }

  isZeelandPort() {
    return VisitContext.isVlissingenOrTerneuzenVisit();
  }

  isPlanningBasedOnFirstBerth() {
    return VisitContext.visit.visitDeclaration.portVisit.portEntry &&
      VisitContext.visit.visitDeclaration.portVisit.portEntry.baseForPlanning === 'FIRST_BERTH' &&
      VisitContext.visit.visitDeclaration.portVisit.berthVisits.filter(bv => bv.berth)[0] === this.berthVisit;
  }

  // use () => {} syntax to enforce lexical 'this'
  onEtaChanged = (eta: string)=> {
    VisitContext.shiftTime(this.berthVisit, 'eta', eta);
    if (this.firstBerth && VisitContext.visit.orderIncomingMovement) {
      VisitContext.updateCalculatedEtas();
    }
    this.checkBerthTimesAreChronological(eta);
    if (!VisitContext.isBetweenQuarterRange(eta, this.terminalVisit?.info.rta)) {
      this.oldEta = this.berthVisit.eta;
    }
  }

  // use () => {} syntax to enforce lexical 'this'
  onEtdChanged = (etd: string)=>  {
    VisitContext.shiftTime(this.berthVisit, 'etd', etd);
    this.checkBerthTimesAreChronological(etd);
    if (!VisitContext.isBetweenQuarterRange(etd, this.terminalVisit?.info.etc)) {
      this.oldEtdSet = true;
      this.oldEtd = this.berthVisit.etd;
    }
  }

  get pilotOnBoard(): string {
    let berthVisitInfo = this.getBerthVisitInfo();
    return berthVisitInfo && berthVisitInfo.harbourMasterInfo
      && berthVisitInfo.harbourMasterInfo.pilotOnBoard;
  }

  lastBerthVisitDeparture() {
    return VisitContext.visit.nextVisitDeclaration.lastBerthVisitDeparture
  }

  checkBerthTimesAreChronological(newTime: string) {
    if (newTime && !VisitContext.visit.terminalPlanningEnabled) {
      this.berthVisit['ngInvalid'] = BerthVisitDetailsComponent.previousBerthVisitNotChronological(this.firstBerth, this.berthVisit, this.terminalVisit) || BerthVisitDetailsComponent.berthVisitNotChronological(this.berthVisit);
      let berthVisits = VisitContext.visit.visitDeclaration.portVisit.berthVisits;
      let nextBerthVisit = berthVisits[berthVisits.indexOf(this.berthVisit) + 1];
      if (nextBerthVisit) {
        nextBerthVisit['ngInvalid'] = !BerthVisitDetailsComponent.isChronological(this.berthVisit, nextBerthVisit);
      }
    }
  }

  showUseTerminalRtaRequest() {
    if (!AppContext.showPortCallOptimization()) return false;
    if (this.editEtaDisabled) return false;
    if (!this.terminalVisit?.info?.rta) return false;
    if (this.firstBerth && VisitContext.isAutoOrdered()) return false;

    return !!this.oldEta || !this.etaMatchesRta();
  }

  useTerminalRta(value: boolean) {
    if (value) {
      this.oldEta = this.berthVisit.eta;
      this.onEtaChanged(this.roundToNearestQuarter(this.terminalVisit.info.rta));
    } else {
      this.onEtaChanged(this.oldEta);
    }
  }

  showUseTerminalEtcRequest() {
    if (!AppContext.showPortCallOptimization()) return false;
    if (this.editEtdDisabled) return false;
    if (!this.terminalVisit?.info?.etc) return false;

    return !!this.oldEtdSet || !this.etdMatchesEtc();
  }

  useTerminalEtc(value: boolean) {
    if (value) {
      this.oldEtdSet = true;
      this.oldEtd = this.berthVisit.etd;
      this.onEtdChanged(this.roundToNearestQuarter(this.terminalVisit.info.etc));
    } else {
      this.onEtdChanged(this.oldEtd);
      this.oldEtdSet = false;
    }
  }

  roundToNearestQuarter(dateTime: string): string {
    return roundToNearestQuarterMoment(moment(dateTime)).toISOString();
  }

  get rtaOrEtaQuarterHour() {
    if (VisitContext.planningBasedOnPilotBoarding() || (this.firstBerth && VisitContext.isAutoOrdered())) {
      // silence the comparator, so field isn't yellow; field is not editable
      return this.berthVisit.eta;
    }

    if (this.berthVisit.ata || this.berthVisit.atd) return this.berthVisit.eta;

    if (this.terminalVisit?.info?.rta) {
      if (this.etaMatchesRta()) {
        return this.berthVisit.eta;
      } else {
        return this.terminalVisit.info.rta;
      }
    } else {
      return this.berthVisit.eta;
    }
  }

  formatDate(date: string) {
    return BerthVisitDetailsComponent.formatDate(date);
  }

  etaMatchesRta() {
    return VisitContext.rtaMatchesEta(this.berthVisit, this.terminalVisit);
  }

  etdMatchesEtc() {
    if (!this.terminalVisit) return true;
    if (!this.berthVisit.etd && this.terminalVisit?.info?.etc) return false;
    return VisitContext.etcMatchesEtd(this.berthVisit, this.terminalVisit);
  }

  getBerthVisitInfo(): BerthVisitInfo {
    return VisitContext.savedVisit.berthVisitInfos[this.berthVisit.id];
  }

  previousBerthVisitNotChronological() {
    return BerthVisitDetailsComponent.previousBerthVisitNotChronological(this.firstBerth, this.berthVisit, this.terminalVisit);
  }

  berthVisitNotChronological() {
    return BerthVisitDetailsComponent.berthVisitNotChronological(this.berthVisit);
  }

  get haveTerminalRta() {
    return AppContext.showPortCallOptimization() && !!this.terminalVisit?.info?.rta;
  }

  get haveTerminalEtc() {
    return AppContext.showPortCallOptimization() && !!this.terminalVisit?.info?.etc;
  }

  bunkerVisits() {
    return VisitContext.getBunkerVisits(this.berthVisit);
  }
}
