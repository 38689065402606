import {Observable, Subscriber} from 'rxjs';
import {InjectorProvider} from './injector-provider';
import {QueryGateway, QueryOptions} from './query-gateway';
import {CommandGateway} from './command-gateway';
import _ from 'lodash';
import {AppContext} from '../app-context';
import {ElementRef} from '@angular/core';
import {v4 as uuidv4} from 'uuid';
import {TitleCasePipe} from '@angular/common';
import {environment} from '../../environments/environment';
import {isSafeNumber, parse} from 'lossless-json';
import {customAlphabet} from "nanoid";
import {EventGateway} from "./event-gateway";
import {ConfirmationModalComponent, ConfirmationModalEvent} from "./confirmation-modal/confirmation-modal.component";
import {EditModalEvent, EditModalOptions, EditModalStackOptions} from "./edit-modal/edit-modal.component";
import moment from "moment";
import {Berth} from "@portbase/bezoekschip-service-typescriptmodels";

export const lodash = _;

export function cloneObject<T, V extends T>(obj: T): V {
  return <V>lodash.cloneDeep(obj);
}

export function $try(f: Function) {
  try {
    return f();
  } catch (e) {
    return undefined;
  }
}

export function nonNull(a): boolean {
  return !!a;
}

export function objectValuesNull(o: any): boolean {
  return lodash.isEmpty(o) || !Object.values(o).filter(nonNull).length;
}

export function filterByTerm(filterTerm: string, excludedFilterFields: string[] = []): (item) => boolean {
  return filterTerm
    ? filterByTermArray(filterTerm.split(' '), excludedFilterFields)
    : () => true;
}

export function filterByTermArray(filterTerms: string[], excludedFilterFields: string[] = []): (item) => boolean {
  return item => {
    let jsonValues = '';
    JSON.stringify(item, (key, value) => {
      if (excludedFilterFields.indexOf(key) == -1) {
        jsonValues += value + ' ';
        return value;
      }
      return undefined;
    });
    jsonValues = jsonValues.toLowerCase();
    return filterTerms.filter(t => !!t).some(term => jsonValues.indexOf(term.toLowerCase()) >= 0);
  };
}

export function parseNumberIfSafe(value: string) {
  return isSafeNumber(value) ? parseFloat(value) : value;
}

export function parseJson(json: string): unknown {
  return parse(json, null, parseNumberIfSafe);
}

export function stripNumber(number): number {
  return number ? Number((parseFloat(String(number)).toPrecision(12))) : 0;
}

export function extractValue<T>(option: T, key: string): any {
  let result = option;
  if (key) {
    const splitKey = key.split('.');
    splitKey.forEach(k => result = result && result[k]);
  }
  return result;
}

export function newObjectFromValue(value: any, path: string): any {
  if (!path) {
    return value || null;
  }
  if (!value) {
    return null;
  }
  const result = {};
  let last = result;
  const parts = path.split(".");
  const length = parts.length;
  for (let i = 0; i < length; i++){
    const p = parts[i];
    if (i + 1 < length) {
      last = last[p] = {};
    } else {
      last[p] = value;
    }
  }
  return result;
}

export function scrollToTop() {
  $('html, body').animate({scrollTop: 0}, 'fast');
}

export function isSameSet(arr1, arr2) {
  return $(arr1).not(arr2).length === 0 && $(arr2).not(arr1).length === 0;
}

export function sendQuery(type: string, payload: any, options ?: QueryOptions): Observable<any> {
  if (!InjectorProvider.injector) {
    return new Observable((subscriber: Subscriber<any>) => {
      InjectorProvider.injector.get(QueryGateway).send(type, payload, options).subscribe(subscriber);
    });
  }
  return InjectorProvider.injector.get(QueryGateway).send(type, payload, options);
}

export function sendCommand(type: string, payload: any, successHandler?: (value: any) => void, errorHandler?: (error: any) => void) {
  AppContext.clearVisitAlerts();
  return AppContext.waitForProcess(InjectorProvider.injector.get(CommandGateway).send(type, payload))
    .subscribe(successHandler ? successHandler : () => {
      },
      errorHandler ? errorHandler : (error) => AppContext.registerError(error));
}

export function sendCommandAndWait(type: string, payload: any): Observable<any> {
  AppContext.clearVisitAlerts();
  return AppContext.waitForProcess(InjectorProvider.injector.get(CommandGateway).send(type, payload));
}

export function publishEvent(type: string, payload?: any) {
  InjectorProvider.injector.get(EventGateway).publish(type, payload || {});
}

export function checkValidity(e: ElementRef | HTMLElement, shouldClearAlerts: boolean = true): boolean {
  const element: HTMLElement = e['nativeElement'] || e;
  if (shouldClearAlerts) AppContext.clearVisitAlerts();
  if (element.querySelector('.ng-invalid')) {
    element.classList.add('was-validated');
    const alert = AppContext.registerError('Please review the fields with errors.');
    const jElement = $(element);
    const handler = () => {
      AppContext.closeAlerts(alert);
      jElement.off('change', handler);
    };
    jElement.on('change', handler);
    return false;
  }
  element.classList.remove('was-validated');
  return true;
}

export function clearValidation(elementRef: ElementRef) {
  elementRef.nativeElement.classList.remove('was-validated');
}

export function toWebsocketUrl(urlPath: string): string {
  return (window.location.protocol === 'https:' ? 'wss://' : 'ws://')
    + (environment.production ? window.location.host : "localhost:8085") + urlPath;
}

export function removeIf<T>(array: T[], callbackfn: (value: T, index: number, array: T[]) => boolean): boolean {
  let i = array.length;
  let removed = false;
  while (i--) {
    if (callbackfn(array[i], i, array)) {
      array.splice(i, 1);
      removed = true;
    }
  }
  return removed;
}

export function removeItem<T>(array: T[], value: T): T[] {
  removeIf(array, v => v === value);
  return array;
}

export function removeAll<T>(array: T[], toRemove: T[]): T[] {
  removeIf(array, v => toRemove.indexOf(v) >= 0);
  return array;
}

export function replaceItem<T>(array: T[], oldValue: T, newValue: T): T[] {
  if (oldValue) {
    if (newValue) {
      array.splice(array.indexOf(oldValue), 1, newValue);
    } else {
      removeItem(array, oldValue);
    }
  } else if (newValue) {
    array.push(newValue);
  }
  return array;
}

export function toMap<K, V>(array: V[], keyFunction: (value: V) => K): Map<K, V> {
  const result: Map<K, V> = new Map();
  array.forEach(v => {
    const k = keyFunction(v);
    if (k) {
      result.set(k, v);
    }
  });
  return result;
}

export function invertMap<K, V>(map: Map<K, V>): Map<V, K> {
  const result = new Map<V, K>();
  map.forEach((v: V, k: K) => result.set(v, k));
  return result;
}

export function uniqueItems<T>(array: T[], fieldFn?: (value: T) => string) {
  if (!fieldFn) {
    return Array.from(new Set<T>(array));
  }
  const map = new Map<string, T>();
  array.forEach(v => {
    if (v) {
      const key = fieldFn(v);
      key ? map.set(key, v) : null;
    }
  });
  return Array.from(map.values());
}

export function isEqual(t1, t2): boolean {
  return lodash.isEqual(t1, t2);
}

const isEmpty = (value?: any) => value == null || (Array.isArray(value) && !value.length)
  || (typeof value === "object" && Object.keys(value).length == 0);

export function isEqualJson(t1, t2, keysToIgnore?: string[]): boolean {
  let replacer = (key, value) => isEmpty(value) ? undefined : value;
  if (!!keysToIgnore) {
    replacer = (key, value) => keysToIgnore.indexOf(key) !== -1 || isEmpty(value) ? undefined : value;
  }
  return JSON.stringify(t1, replacer) === JSON.stringify(t2, replacer);
}

export function uuid(): string {
  return uuidv4();
}

export const nanoId = customAlphabet("ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",22);

export function cast<T>(val): T {
  return <T>val;
}

export function dispatchChangeEvent(element: HTMLElement) {
  let event;
  if (typeof (Event) === 'function') {
    event = new Event('change', {bubbles: true, cancelable: true});
  } else {
    event = document.createEvent("HTMLEvents");
    event.initEvent("change", true, true);
  }
  element.dispatchEvent(event);
  element.dispatchEvent(event);
}

export function toTitleCase(value: string): string {
  if (!titleCasePipe) {
    titleCasePipe = InjectorProvider.injector.get(TitleCasePipe);
  }
  return value ? titleCasePipe.transform(value) : '';
}

let titleCasePipe;

export function wrapCommand(command: any, uploadedXls: String): any {
  return {
    "@class": "io.fluxcapacitor.javaclient.common.Message",
    "payload": command,
    "metadata": {
      "upload": uploadedXls
    }
  };
}


export function openConfirmationModal(component, data?) {
  publishEvent('openConfirmationModal', <ConfirmationModalEvent>{component: component, data: data });
}

export function openConfirmationModalWithCallback(callback: (boolean, string?) => any, component?, data?, backdrop?: boolean | 'static') {
  publishEvent('openConfirmationModal', <ConfirmationModalEvent>{
    component: component || ConfirmationModalComponent,
    data: data,
    backdrop: backdrop,
    modalSize: data?.modalSize,
    callback: callback });
}

export function closeConfirmationModal() {
  publishEvent('closeConfirmationModal');
}


export function openEditModal(component, data?, options?: EditModalOptions) {
  publishEvent('openEditModal', <EditModalEvent>{component: component, data: data, options: options});
}

export function closeEditModal() {
  publishEvent('closeEditModal');
}

export function formDataSaved() {
  publishEvent('formDataSaved');
}

export function showPreviousInStack(options: EditModalStackOptions) {
  publishEvent('showPreviousInStack', options);
}

export function berthInputFormatter(berth: Berth) {
  return berth && berth.code
    ? berth.name + ' – ' + (berth.shortName ? berth.shortName : berth.code) +
      (berth.bollards ? ' (' + berth.bollards.start + '-' + berth.bollards.end + ')' : '')
    : '';
}

export function formatDateString(dateString: string) {
  return !!dateString ? (moment().isSame(dateString, 'day')
    ? 'Today, ' + moment(dateString).format('HH:mm') : moment(dateString).format('ddd D MMM YYYY, HH:mm')) : ""
}

export function mergeIfNotExists(destination, source) {
  return destination !== undefined ? destination : source;
}

export function isDefined(value: any) {
  return value !== null && value !== undefined;
}

export enum CheckboxSelectionState {
  unselected,
  selected,
  indeterminate
}
