import {Component, OnInit} from '@angular/core';
import {VisitContext} from '../../visit-context';
import {AppContext} from '../../../app-context';
import {lodash, sendCommand, uniqueItems} from '../../../common/utils';
import {
  BezoekschipOrganisation,
  BreakBulk,
  Container,
  DangerousGoods,
  DangerousGoodsDeclaration,
  DeclarationType,
  Discharge,
  Loading,
  StowageUnion
} from '@portbase/bezoekschip-service-typescriptmodels';
import {DangerousGoodsModel} from "../dangerous-goods.model";

@Component({
  selector: 'app-dangerous-goods-overview',
  templateUrl: './dangerous-goods-overview.component.html',
  styleUrls: ['./dangerous-goods-overview.component.css']
})
export class DangerousGoodsOverviewComponent implements OnInit {
  DANGEROUS_GOODS = DeclarationType.DANGEROUS_GOODS;

  context = VisitContext;
  appContext = AppContext;

  declarations: DgDeclaration[] = [];
  nextDeclarations: DgDeclaration[] = null;

  ngOnInit(): void {
    let nextDeclaration = VisitContext.visit.nextDangerousGoodsDeclaration;
    if (nextDeclaration && this.context.isOrganisationNextDeclarant()) {
      this.nextDeclarations = [<DgDeclaration>{
        cargoAgent: VisitContext.visit.nextOwner,
        completed: false,
        verified: false,
        dangerousGoodsSummary: new DgDangerousGoodsSummary(nextDeclaration.dangerousGoods, VisitContext.visit.portOfCall.port.locationUnCode, VisitContext.visit.nextOwner.shortName)
      }];
    }

    let cargoDeclarant: boolean = VisitContext.isOrganisationCargoDeclarant() && !VisitContext.isOrganisationCurrentVisitDeclarant();
    if (cargoDeclarant) {
      let d = VisitContext.visit.dangerousGoodsDeclarations.find(d => d.cargoDeclarant.shortName === AppContext.userProfile.organisation.shortName);
      this.declarations = [<DgDeclaration>{
        cargoAgent: d ? d.cargoDeclarant : AppContext.userProfile.organisation,
        completed: d ? d.completed : false,
        verified: d ? d.declared : false,
        dangerousGoodsSummary: new DgDangerousGoodsSummary(d ? d.dangerousGoods : {
          goods: [],
          handlings: [],
          stowageAtArrival: []
        }, VisitContext.visit.portOfCall.port.locationUnCode, d ? d.cargoDeclarant.shortName : AppContext.userProfile.organisation.shortName)
      }];
      return
    }
    this.declarations = VisitContext.visit.cargoDeclarants.map(agent => {
      const agentShortName = agent?.shortName;
      let declaration = VisitContext.visit.dangerousGoodsDeclarations
        .find(d => (d.cargoDeclarant?.shortName === agentShortName)
          && (VisitContext.isOrganisationCurrentVisitDeclarant() || AppContext.isAdmin() || d.completed));

      const isOwnDeclarationOfVisitDeclarant = VisitContext.isOrganisationCurrentVisitDeclarant
        && (VisitContext.visit.owner?.shortName === agentShortName
          || VisitContext.visit.declarant?.shortName === agentShortName);
      const isOwnDeclarationOfCargoDeclarant = VisitContext.isOrganisationCargoDeclarant()
        && agentShortName === AppContext.userProfile.organisation.shortName;
      if (!declaration && (isOwnDeclarationOfVisitDeclarant || isOwnDeclarationOfCargoDeclarant)) {
        declaration = <DangerousGoodsDeclaration>{
          completed: false,
          cargoDeclarant: agent,
          visitOwner: VisitContext.visit.owner,
          visitDeclarant: VisitContext.visit.declarant,
          declared: false,
          dangerousGoods: {goods: [], handlings: [], stowageAtArrival: []}
        };
      }
      return <DgDeclaration>{
        cargoAgent: agent,
        completed: !!declaration && declaration.completed,
        verified: !!declaration && declaration.declared,
        dangerousGoodsSummary: new DgDangerousGoodsSummary(declaration && declaration.dangerousGoods,
          VisitContext.visit.portOfCall.port.locationUnCode, agentShortName)
      };
    });

    VisitContext.visit.dangerousGoodsDeclarations
      .filter(d => !this.declarations.some(d2 => d2.cargoAgent.shortName === d.cargoDeclarant.shortName))
      .filter(d => {
        const user = AppContext.userProfile.organisation?.shortName;
        return AppContext.isAdmin() || user === d.cargoDeclarant.shortName
          || user === d.visitDeclarant.shortName || user === d.visitOwner.shortName;
      })
      .forEach(d => this.declarations.push(<DgDeclaration>{
        cargoAgent: d.cargoDeclarant,
        completed: d.completed,
        verified: d.declared,
        dangerousGoodsSummary: new DgDangerousGoodsSummary(d.dangerousGoods, VisitContext.visit.portOfCall.port.locationUnCode, d.cargoDeclarant.shortName)
      }))

  }

  sendAllowed = (): boolean => {
    return VisitContext.isOrganisationCurrentVisitDeclarant()
      && VisitContext.hasBeenAcceptedAtLeastOnce(DeclarationType.VISIT)
      && VisitContext.visit.dangerousGoodsDeclarations.some(d => d.completed && !d.declared)
      && (VisitContext.findLatestDeclaration(DeclarationType.DANGEROUS_GOODS) != null || somethingToDeclare());

    function somethingToDeclare(): boolean {
      return VisitContext.visit.dangerousGoodsDeclarations.some(d =>
        d.dangerousGoods.goods.length || d.dangerousGoods.stowageAtArrival.length || d.dangerousGoods.handlings.length);
    }

  };

  sendDeclaration = () => {
    sendCommand('com.portbase.bezoekschip.common.api.dangerousgoods.DeclareDangerousGoods',
      {crn: VisitContext.visit.crn}, () => {
        this.context.visit.dangerousGoodsDeclarations.forEach(dg => dg.completed ? dg.declared = true : null);
        this.context.replaceVisit(this.context.visit);
        this.ngOnInit();
        AppContext.registerSuccess('The declaration was sent successfully.');
      });
  };
}

interface DgDeclaration {
  cargoAgent: BezoekschipOrganisation,
  dangerousGoodsSummary: DgDangerousGoodsSummary,
  completed: boolean,
  verified: boolean,
  mayView: boolean
}

class DgDangerousGoodsSummary {
  loadings: number = 0;
  discharges: number = 0;
  transits: number = 0;
  missingPositions: number = 0;

  hasDeclaration: boolean = true;

  constructor(dangerousGoods: DangerousGoods, locationUnCode: string, agent: string) {
    function hasMissingPosition(value: StowageUnion) {
      switch (value.type) {
        case 'tank':
        case 'hold':
          return lodash.isEmpty(value.stowageNumber);
        case 'container':
          return lodash.isEmpty((<Container>value).position);
        case 'breakBulk':
          return lodash.isEmpty((<BreakBulk>value).position);
      }

      return undefined;
    }

    if (!dangerousGoods) {
      this.hasDeclaration = false;
    } else {
      dangerousGoods = dangerousGoods['exitPoint'] ? dangerousGoods : new DangerousGoodsModel(dangerousGoods, agent);
      try {
        let loadingHandlings = dangerousGoods && dangerousGoods.handlings.filter(handling => handling.type === 'loading');
        let dischargeHandlings = dangerousGoods && dangerousGoods.handlings.filter(handling => handling.type === 'discharge');

        let loadingStowages = loadingHandlings.map(loading => (<Loading>loading).stowages).reduce((previousValue, currentValue) => previousValue.concat(currentValue), []);
        let dischargeStowages = dischargeHandlings.map(discharge => (<Discharge>discharge).stowages).reduce((previousValue, currentValue) => previousValue.concat(currentValue), []);
        let loadingStowageNumbers = uniqueItems(loadingStowages.map(sto => sto.stowageNumber));
        let dischargeStowageNumbers = uniqueItems(dischargeStowages.map(sto => sto.stowageNumber));

        let nonTransitHandlings = loadingStowageNumbers.concat(dischargeStowageNumbers);
        let transitStowages = uniqueItems(dangerousGoods.stowageAtArrival.map(stowage => stowage.stowageNumber))
          .filter(value => nonTransitHandlings.indexOf(value) === -1);

        let partlyDischargePartlyTransit = dangerousGoods["exitPoint"].stowageAtArrival
          .filter(stowage =>
            nonTransitHandlings.indexOf(stowage.stowageNumber) > -1
            && stowage.portOfDischarge && stowage.portOfDischarge.locationUnCode === locationUnCode
            && stowage.weight > 0);

        this.loadings = loadingStowageNumbers.length;
        this.discharges = dischargeStowageNumbers.length;
        this.transits = transitStowages.length + partlyDischargePartlyTransit.length;
        let missingPositionsAtArrival = uniqueItems(dangerousGoods.stowageAtArrival.filter(value => hasMissingPosition(value)).map(value => value.stowageNumber)).length;
        let missingPositionsAtLoadings = uniqueItems(loadingStowages.filter(value => hasMissingPosition(value)).map(value => value.stowageNumber)).length;
        this.missingPositions = missingPositionsAtArrival + missingPositionsAtLoadings;
      } catch (e) {
        //
      }
    }
  }
}
