import {NgModule} from "@angular/core";
import {VisitOverviewComponent} from "./visit-overview/visit-overview.component";
import {RouterModule, Routes} from "@angular/router";
import {CookieModule} from "ngx-cookie";
import {BrowserModule} from "@angular/platform-browser";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {DragulaModule} from "ng2-dragula";
import {NgChartsModule} from "ng2-charts";
import {TypeaheadWindowComponent} from "../common/typeahead/typeahead-window.component";
import {PopoverComponent} from "../common/popover/popover.component";
import {VisitOverviewItemComponent} from "./visit-overview/visit-overview-item/visit-overview-item.component";
import {PortVisitCommonModule} from "../common/port-visit-common.module";
import {NewRouteGuard} from "./new-route.guard";
import {
  VisitOverviewServiceStatusComponent
} from './visit-overview/visit-overview-item/visit-overview-service-status/visit-overview-service-status.component';
import {RouteData} from "../route-data";
import {HighlightComponent} from "../common/typeahead/highlight/highlight.component";
import {OverviewComponent} from './common/overview/overview.component';
import {FacetDateRangeComponent} from './common/facets/facet-date-range/facet-date-range.component';
import {
  TerminalVisitOverviewServiceStatusComponent
} from "./visit-overview/visit-overview-item/visit-overview-service-status/terminal-visit-overview-service-status/terminal-visit-overview-service-status.component";
import {ConsignmentsOverviewComponent} from './consignments/consignments-overview/consignments-overview.component';
import {
  MasterConsignmentOverviewItemComponent
} from './consignments/consignments-overview/consignment-overview-item/master-consignment-overview-item.component';
import {
  ConsignmentEquipmentOverviewItemComponent
} from './consignments/consignments-overview/consignment-overview-item/consignment-equipment-overview-item/consignment-equipment-overview-item.component';
import {
  MasterConsignmentDetailsComponent
} from './consignments/details/master-consignment-details/master-consignment-details.component';
import {
  MasterConsignmentRouteComponent
} from './consignments/details/master-consignment-details/master-consignment-route/master-consignment-route.component';
import {
  ConsignmentPartyDetailsComponent
} from './consignments/details/master-consignment-details/consignment-party-details/consignment-party-details.component';
import {
  HouseConsignmentDetailsComponent
} from './consignments/details/house-consignment-details/house-consignment-details.component';
import {
  EquipmentConsignmentDetailsComponent
} from './consignments/details/equipment-consignment-details/equipment-consignment-details.component';
import {
  GoodsConsignmentDetailsComponent
} from './consignments/details/goods-consignment-details/goods-consignment-details.component';
import {GoodsPackagingComponent} from './consignments/details/goods-packaging/goods-packaging.component';
import {FacetFilterComponent} from './common/facets/facet-filter/facet-filter.component';
import {FacetFilterValueComponent} from './common/facets/facet-filter/facet-filter-value/facet-filter-value.component';
import {
  FacetFilterBooleanValueComponent
} from './common/facets/facet-filter/facet-filter-boolean-value/facet-filter-boolean-value.component';
import {StatusIndicatorComponent} from './common/status-indicator/status-indicator.component';
import {
  ConsignmentSupplementaryDeclarantComponent
} from './consignments/details/master-consignment-details/consignment-supplementary-declarant/consignment-supplementary-declarant.component';
import {
  AdditionalSupplyChainActorDetailsComponent
} from './consignments/details/master-consignment-details/additional-supply-chain-actor-details/additional-supply-chain-actor-details.component';
import {ContainersOverviewComponent} from "./containers/containers-overview/containers-overview.component";
import {
  ContainerOverviewItemComponent
} from "./containers/containers-overview/consignment-overview-item/container-overview-item.component";
import {
  ContainerConsignmentOverviewItemComponent
} from "./containers/containers-overview/consignment-overview-item/container-consignment-overview-item/container-consignment-overview-item.component";
import {
  ConsignmentGoodsTableComponent
} from "./consignments/details/tables/consignment-goods-table/consignment-goods-table.component";
import {
  ConsignmentEquipmentsTableComponent
} from "./consignments/details/tables/consignment-equipments-table/consignment-equipments-table.component";
import {
  HouseConsignmentsTableComponent
} from "./consignments/details/tables/house-consignments-table/house-consignments-table.component";
import {
  ConsignmentEquipmentsLinkTableComponent
} from "./consignments/details/tables/consignment-equipments-link-table/consignment-equipments-link-table.component";
import {
  ConsignmentGoodsColumnComponent
} from './consignments/details/tables/consignment-goods-column/consignment-goods-column.component';
import {
  ConsignmentHouseColumnComponent
} from './consignments/details/tables/consignment-house-column/consignment-house-column.component';
import {FacetsOverviewComponent} from './common/facets/facets-overview/facets-overview.component';
import {
  ConsignmentVehiclesTableComponent
} from './consignments/details/tables/consignment-vehicles-table/consignment-vehicles-table.component';
import {
  ConsignmentBulkGoodsOverviewItemComponent
} from './consignments/consignments-overview/consignment-overview-item/consignment-bulk-goods-overview-item/consignment-bulk-goods-overview-item.component';
import {
  ConsignmentCarrierDetailsComponent
} from "./consignments/details/master-consignment-details/consignment-carrier-details/consignment-carrier-details.component";
import {
  ConsignmentHistoryDetailsComponent
} from './consignments/details/consignment-history-details/consignment-history-details.component';
import {EstimatedActualFieldComponent} from './common/estimated-actual-field/estimated-actual-field.component';
import {
  ConsignmentDetailsAlertsComponent
} from './consignments/details/consignment-details-alerts/consignment-details-alerts.component';

const appRoutes: Routes = [
  {
    path: '',
    canActivate: [NewRouteGuard],
    component: VisitOverviewComponent,
    data: <RouteData>{
      manualScrolling: true
    }
  },
  {
    path: 'cargo',
    canActivate: [NewRouteGuard],
    component: ConsignmentsOverviewComponent,
    data: <RouteData>{
      manualScrolling: true
    }
  },
  {
    path: 'cargo/:crn',
    canActivate: [NewRouteGuard],
    component: ConsignmentsOverviewComponent,
    data: <RouteData>{
      manualScrolling: true
    }
  },
  {
    path: 'cargo/:crn/:cargoDeclarantId',
    canActivate: [NewRouteGuard],
    component: ConsignmentsOverviewComponent,
    data: <RouteData>{
      manualScrolling: true
    }
  },
  {
    path: 'container',
    canActivate: [NewRouteGuard],
    component: ContainersOverviewComponent,
    data: <RouteData>{
      manualScrolling: true
    }
  }
];

@NgModule({
  declarations: [
    // Components
    VisitOverviewComponent,
    VisitOverviewItemComponent,
    VisitOverviewServiceStatusComponent,
    TerminalVisitOverviewServiceStatusComponent,
    OverviewComponent,
    FacetDateRangeComponent,
    ConsignmentsOverviewComponent,
    MasterConsignmentOverviewItemComponent,
    ConsignmentEquipmentOverviewItemComponent,
    MasterConsignmentDetailsComponent,
    MasterConsignmentRouteComponent,
    ConsignmentPartyDetailsComponent,
    ConsignmentCarrierDetailsComponent,
    HouseConsignmentDetailsComponent,
    EquipmentConsignmentDetailsComponent,
    GoodsConsignmentDetailsComponent,
    ConsignmentGoodsTableComponent,
    ConsignmentEquipmentsTableComponent,
    GoodsPackagingComponent,
    ContainersOverviewComponent,
    ContainerOverviewItemComponent,
    ContainerConsignmentOverviewItemComponent,
    FacetFilterComponent,
    FacetFilterValueComponent,
    FacetFilterBooleanValueComponent,
    StatusIndicatorComponent,
    ConsignmentSupplementaryDeclarantComponent,
    AdditionalSupplyChainActorDetailsComponent,
    HouseConsignmentsTableComponent,
    ConsignmentEquipmentsLinkTableComponent,
    ConsignmentGoodsColumnComponent,
    ConsignmentHouseColumnComponent,
    FacetsOverviewComponent,
    ConsignmentVehiclesTableComponent,
    ConsignmentBulkGoodsOverviewItemComponent,
    ConsignmentHistoryDetailsComponent,
    EstimatedActualFieldComponent,
    ConsignmentDetailsAlertsComponent,
  ],
  imports: [
    RouterModule.forRoot(appRoutes, {enableTracing: false}),
    CookieModule.forRoot(),
    PortVisitCommonModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    DragulaModule.forRoot(),
    NgChartsModule,
    TypeaheadWindowComponent,
    PopoverComponent,
    HighlightComponent,
    NgOptimizedImage,
  ],
  providers: [
    NewRouteGuard
  ]
})
export class PortVisitModule {

}
