import {AfterViewInit, Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {
  IE3ConsignmentProcess,
  IE3GoodsItem,
  IE3TransportEquipment
} from "@portbase/bezoekschip-service-typescriptmodels";
import {TableField} from "../../../../../common/table/table-view/table-view.component";
import {ConsignmentUtils} from "../../../consignment.utils";

@Component({
  selector: 'app-consignment-equipments-link-table',
  templateUrl: './consignment-equipments-link-table.component.html',
  styleUrls: ['./consignment-equipments-link-table.component.scss']
})
export class ConsignmentEquipmentsLinkTableComponent implements AfterViewInit {
  utils = ConsignmentUtils;

  @Input() consignmentProcess: IE3ConsignmentProcess;
  @Input() houseConsignmentNumber: string;
  @Input() goodsItemNumber: number;
  @Input() term: string;
  fieldsDefinition: TableField[] = [];
  _data: EquipmentWithHouseConsignments[];

  @ViewChild("equipmentRef") equipmentRef: TemplateRef<any>;
  @ViewChild("goodsRef") goodsRef: TemplateRef<any>;
  @ViewChild("actionColumnRef") actionColumnRef: TemplateRef<any>;
  @ViewChild("equipmentLinkModalContent") equipmentLinkModalContent: TemplateRef<any>;

  ngAfterViewInit(): void {
    this.fieldsDefinition = [
      {
        type: "action",
        name: "actionColumn",
        template: this.actionColumnRef,
        cellClass: "p-1 auto-width"
      }, {
        type: "string",
        name: "equipment",
        header: "Equipment",
        template: this.equipmentRef
      }, {
        type: "string",
        name: "goods",
        header: "Goods",
        template: this.goodsRef
      }, {
        type: "string",
        name: "houseConsignments",
        header: "House B/Ls",
        formatter: (value: string[]) => value ? value.join(' | ') : ''
      }
    ];
    this.updateDataSelected();
  }

  @Input()
  set data(data: EquipmentWithHouseConsignments[]) {
    this._data = data;
    this.updateDataSelected();
  }

  private updateDataSelected = () => {
    return this._data?.forEach(d => {
      d['selected'] = this.goodsItemNumber
        ? d.goods.some(g => g.goodsItemNumber === this.goodsItemNumber)
        : d.houseConsignments.includes(this.houseConsignmentNumber)
    });
  };

  trackByContainerNumber = (index: number, record: EquipmentWithHouseConsignments) => record.equipment.containerIdentificationNumber;
  protected readonly ConsignmentUtils = ConsignmentUtils;
}

export interface EquipmentWithHouseConsignments {
  equipment: IE3TransportEquipment;
  houseConsignments: string[];
  goods: IE3GoodsItem[];
}
