import {Component, Input} from '@angular/core';
import {
  CargoCurrency,
  PackageType,
  TransitDeclaration,
  TransitDocumentType,
  TransitGoodsItem,
  TransportDocument
} from '@portbase/bezoekschip-service-typescriptmodels';
import {PortvisitUtils} from "../../../../refdata/portvisit-utils";
import {findTransitClassifications} from "../../../transit.utils";
import {lodash, removeItem} from "../../../../common/utils";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

@Component({
  selector: 'app-transit-good',
  templateUrl: './transit-good.component.html',
  styleUrls: ['./transit-good.component.css']
})
export class TransitGoodComponent {
  refData = PortvisitUtils;
  findClassifications = findTransitClassifications;
  @Input() declaration: TransitDeclaration;
  @Input() item: TransitGoodsItem;
  @Input() itemFromCustoms: TransitGoodsItem;
  @Input() isTemplate: boolean = false;
  @Input() isEditable: boolean = false;

  searchCurrencies(term: string) : Observable<any[]> {
    return PortvisitUtils.getCurrencies
      .pipe(map(all => lodash.filter(all, item => (item as CargoCurrency).unCode.toLowerCase().includes(term.toLowerCase()) ||
        (item as CargoCurrency).description.toLowerCase().includes(term.toLowerCase()))));
  }

  searchPreviousDocumentTypes(term: string) : Observable<any[]> {
    return PortvisitUtils.getTransitPreviousDocumentTypes
      .pipe(map(all => lodash.filter(all, item => (item as TransitDocumentType).code.toLowerCase().includes(term.toLowerCase()) ||
        (item as TransitDocumentType).description.toLowerCase().includes(term.toLowerCase()))));
  }

  searchTransportDocumentTypes(term: string) : Observable<any[]> {
    return PortvisitUtils.getTransitTransportDocumentTypes
      .pipe(map(all => lodash.filter(all, item => (item as TransitDocumentType).code.toLowerCase().includes(term.toLowerCase()) ||
        (item as TransitDocumentType).description.toLowerCase().includes(term.toLowerCase()))));
  }

  searchPackageTypes(term: string) : Observable<any[]> {
    return PortvisitUtils.getPackageTypes
      .pipe(map(all => lodash.filter(all, item =>
        (item as PackageType).code.toLowerCase().includes(term.toLowerCase()) ||
        (item as PackageType).name.toLowerCase().includes(term.toLowerCase()))));
  }

  addTransportDocument() {
    this.item.transportDocuments ||= []
    this.item.transportDocuments.push(<TransportDocument>{});
  }

  removeTransportDocument(transportDocument: TransportDocument) {
    return () => removeItem(this.item.transportDocuments, transportDocument);
  }
}
