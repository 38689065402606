import {Component, ElementRef, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {AppContext} from "../../../../../app-context";
import {AbstractValueAccessorComponent} from "../../../../../common/component/value-accessor.component";
import {LocalMovement} from "@portbase/bezoekschip-service-typescriptmodels";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {CollapseComponent} from "../../../../../common/collapse/collapse.component";
import {VisitContext} from "../../../../visit-context";

@Component({
	selector: 'app-storm-pilotage',
	templateUrl: './storm-pilotage.component.html',
	styleUrls: ['./storm-pilotage.component.css'],
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => StormPilotageComponent), multi: true}
	],
})
export class StormPilotageComponent extends AbstractValueAccessorComponent<LocalMovement> implements OnInit {

	appContext = AppContext;
	context = VisitContext;

	@Input() localMovement: LocalMovement;
	@Input() vesselLength: number;
	@Input() isTanker: boolean;

	@Input() firstMovement: boolean;
	@Input() required;
	@Input() id: string;

	@ViewChild('stormPilotageCollapse') stormPilotageCollapse: CollapseComponent;

	hoistingOrLanding = value => value ? "Hoisting" : "Landing";

	get showHeliQuestions(): boolean {
		return this.vesselLength >= 125;
	}

	get showLoaQuestions(): boolean {
		return this.vesselLength <= this.maxLengthForLoa  && this.vesselDraftLessThan9Meters;
	}

	get isLoaRequired(): boolean {
		return !!this.localMovement.order && this.required && ((this.appContext.stormPilotageRotterdam === 'HALTED_FOR_ALL' && this.vesselLength <= this.maxLengthForLoa
			&& this.vesselDraftLessThan9Meters) ||
			(this.appContext.stormPilotageRotterdam === 'HALTED_FOR_SMALL' && this.vesselLength < 125 && this.vesselDraftLessThan9Meters));
	}

  get hasPECHolder(): boolean {
    return this.localMovement.pilotExemption && !!this.localMovement.pilotExemption.holderName;
  }

  get falseNotAllowedForFirstRemotePilotage(): boolean {
    let firstBerthVisitAta = this.context.visit.visitDeclaration.portVisit.berthVisits[0].ata

    return !firstBerthVisitAta && this.isLoaRequired && !this.hasPECHolder &&
      this.localMovement.stormPilotageInformation.remotePilotage === false && this.firstMovement;
  }

  get falseNotAllowedForLastRemotePilotage(): boolean {
    return this.isLoaRequired && !this.hasPECHolder &&
      this.localMovement.stormPilotageInformation.remotePilotage === false && this.context.isLastMovement(this.localMovement);
  }

	constructor(private elementRef: ElementRef) {
		super();
	}

	get maxLengthForLoa(): number {
		const inboundThreshold = 165;
		const outboundThreshold = 150;
		return this.firstMovement ? inboundThreshold : outboundThreshold;
	}

	ngOnInit(): void {
		this.localMovement.stormPilotageInformation = !this.localMovement.stormPilotageInformation ?
			{
				heliSuitable: null,
				remotePilotage: null,
				imoLoaRegistered: null,
				lowFreeboard: null,
				hoisting: null,
				remarks: null
			}
			: this.localMovement.stormPilotageInformation;
	}

	get vesselDraftLessThan9Meters(): boolean {
		return this.localMovement.vesselDraft <= 9;
	}

	get value(): LocalMovement {
		return this.localMovement;
	}

	writeValue(value: LocalMovement): void {
		this.localMovement = value;
	};

}
