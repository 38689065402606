import {Component, ElementRef, OnInit} from '@angular/core';
import {
  BerthVisitSelection,
  BunkerLicense,
  BunkerProduct,
  BunkerProductSupplier,
  CancelBunkering,
  InputBunkerItem,
  InputDeclareBunkering
} from "@portbase/bezoekschip-service-typescriptmodels";
import {AppContext} from "../../app-context";
import {checkValidity, closeEditModal, formatDateString, sendCommand, uuid} from "../../common/utils";
import {BunkeringService} from "../bunkering.service";
import {Observable} from "rxjs";
import {PortvisitUtils} from "../../refdata/portvisit-utils";
import {LocalBunkering} from "../bunkering.component";
import moment, {Duration} from "moment";

@Component({
  selector: 'app-edit-bunkering',
  templateUrl: './edit-bunkering.component.html',
  styleUrls: ['../bunkering.scss', './edit-bunkering.component.css']
})
export class EditBunkeringComponent implements OnInit {

  constructor(private element: ElementRef) {
  }

  localStorageKey = "bunkering-license";

  appContext = AppContext;

  data: LocalBunkering;
  _expectedTime: boolean;
  get expectedTime() {
    return this._expectedTime;
  }

  set expectedTime(value) {
    this._expectedTime = value;
    this.completionTime = this._expectedTime ? this.data.input.etc : this.data.input.atc ? this.data.input.atc : moment().seconds(0).milliseconds(0).format();
  }

  completionTime?: string;

  ngOnInit() {
    if (!this.data.input.license) {
      const storedLicense = localStorage.getItem(this.localStorageKey);

      if (storedLicense != null) {
        const parsedLicense = JSON.parse(storedLicense);

        if (this.appContext?.userProfile?.organisation?.shortName === parsedLicense?.organisationShortName) {
          this.data.input.license = parsedLicense;
        }
      }
    }

    this.expectedTime = !this.data.input.atc;
  }

  openMessages() {
    PortvisitUtils.fetchProcessIdAndOpenMessageManagement("pro-out-" + this.data.berthVisitSelection.crn + "-" + this.data.bunkeringId);
  }

  licences(term: string): Observable<BunkerLicense[]> {
    return BunkeringService.licenses(term);
  }

  suppliers(term: string): Observable<BunkerProductSupplier[]> {
    return BunkeringService.suppliers(term);
  }

  products(term: string): Observable<BunkerProduct[]> {
    return BunkeringService.products(term);
  }

  formatLicense(license: BunkerLicense) {
    if (!license) {
      return '';
    }
    let id = license.bunkerVessel.eni != null ? license.bunkerVessel.eni : license.bunkerVessel.imo;
    return license.bunkerVessel.name + ' (' + id + ')';
  }

  saveLicense(license: BunkerLicense) {
    localStorage.setItem(this.localStorageKey, JSON.stringify(license))
  }

  formatExpectedTime(value: boolean): string {
    return value ? "Estimated" : "Actual";
  }

  getBerthVisitsFn(): (term: string) => Observable<BerthVisitSelection[]> {
    let licenseId = this.data.input.license.id
    return (term: string) => BunkeringService.berthVisits(term, licenseId);
  }

  formatGetBerthVisitsSelection(selection: BerthVisitSelection) {
    return selection.vesselName + ' (' + selection.imo + ')' + ' - ' + selection.berthName + ' - ' + formatDateString(selection.startTime);
  }

  productsEqual(product: BunkerProduct, other: BunkerProduct) {
    return product != null && other != null && product.id === other.id;
  }

  formatProduct(product: BunkerProduct) {
    return product.type + ' (' + product.provisionType + ') ' + ' (' + product.density + ') ' + product.unitOfMeasure;
  }

  formatSupplier(supplier: BunkerProductSupplier) {
    return supplier.name;
  }

  addProduct() {
    this.data.input.items.push({});
  }

  deleteItem(index: number) {
    this.data.input.items.splice(index, 1)
  }

  close() {
    closeEditModal();
  }

  submit() {
    if (checkValidity(this.element)) {
      let declaration = <InputDeclareBunkering>{
        declarationId: uuid(),
        bunkeringId: this.data.bunkeringId,
        crn: this.data.berthVisitSelection.crn,
        berthVisitId: this.data.berthVisitSelection.berthVisitId,
        ets: this.data.input.ets,
        etc: this.expectedTime ? this.completionTime : this.data.input.etc || this.completionTime,
        atc: !this.expectedTime ? this.completionTime : this.data.input.atc,
        licenseId: this.data.input.license.id,
        supplierId: this.data.input.supplier.id,
        items: this.data.input.items.map(i => <InputBunkerItem>{
          productId: i.product.id,
          quantity: i.quantity
        }),
        sendProToCustoms: this.data.sendProToCustoms
      };

      sendCommand('com.portbase.bezoekschip.common.api.bunkering.input.InputDeclareBunkering', declaration, () => {
        AppContext.registerSuccess("Declaration Bunkers sent successfully");
        if (this.expectedTime) {
          this.data.input.etc = this.completionTime;
        } else {
          this.data.input.atc = this.completionTime;
        }
        closeEditModal();
      });
    }
  }

  cancel() {
    let cancelBunkering = <CancelBunkering>{
      crn: this.data.berthVisitSelection.crn,
      declarationId: uuid(),
      bunkeringId: this.data.bunkeringId,
      berthVisitId: this.data.berthVisitSelection.berthVisitId
    }

    sendCommand('com.portbase.bezoekschip.common.api.bunkering.CancelBunkering', cancelBunkering, () => {
      AppContext.registerSuccess("Cancellation sent successfully");
      closeEditModal();
    });
  }

  showEtsBeforeWindowWarning(): boolean {
    return this.isLastBeforeFirst(this.data.berthVisitSelection?.startTime, this.data.input?.ets)
  }

  showEtcAfterWindowWarning(): boolean {
    return this.expectedTime
      && this.completionTime && this.data.berthVisitSelection?.endTime
      && moment(this.completionTime).isAfter(moment(this.data.berthVisitSelection?.endTime));
  }

  showEtcWithin30MinutesWarning(): boolean {
    return this.expectedTime && !this.showEtcAfterWindowWarning() && this.completionTime
      && this.isLastBeforeFirst(this.completionTime, this.data.berthVisitSelection?.endTime, moment.duration(31, "minutes"));
  }

  showInvalidPeriodWarning(): boolean {
    return this.data.input?.ets && (this.completionTime && !this.isLastBeforeFirst(this.completionTime, this.data.input?.ets));
  }

  showAtcInTheFutureWarning(): boolean {
    return !this.expectedTime && this.completionTime && moment(this.completionTime).isAfter(moment(moment.now()).add(8, "minutes"))
  }

  isLastBeforeFirst(first: string, last: string, leeway?: Duration): boolean {
    if (!first || !last) {
      return false;
    }
    let firstTime = moment(first)
    let lastTime = moment(last)
    if (leeway) {
      firstTime = firstTime.add(leeway)
    }
    if (firstTime.isValid() && lastTime.isValid()) {
      return lastTime.isBefore(firstTime);
    }
    return false;
  }
}
