import {Component, ElementRef, Input} from '@angular/core';
import {
  BulkItem,
  IE3ConsignmentProcessSummary,
  IE3ConsignmentsResult,
  IE3CustomsProcess,
  IE3CustomsStatus,
  IE3FindConsignments,
  IE3GoodsItemSummary,
  TransitDeclaration
} from "@portbase/bezoekschip-service-typescriptmodels";
import {AppContext} from "../../../app-context";
import {checkValidity, lodash, sendQuery} from "../../../common/utils";
import {Observable} from "rxjs";
import {PortvisitUtils} from "../../../refdata/portvisit-utils";
import {map} from "rxjs/operators";
import {cloneDeep} from "lodash";
import {getGoodsItems} from "../../transit.utils";

@Component({
  selector: 'app-copy-bulk-from-consignments',
  templateUrl: './transit-copy-bulk-from-consignments.component.html',
  styleUrls: ['./transit-copy-bulk-from-consignments.component.css']
})
export class TransitCopyBulkFromConsignmentsComponent {
  appContext = AppContext;
  refData = PortvisitUtils;
  bulkEntities: BulkEntity[] = [];
  searchTerm = '';
  selectedConsignments: BulkItem[] = [];

  @Input() existingDeclarations: TransitDeclaration[] = [];

  constructor(private element: ElementRef) {
  }

  searchFunction = (searchTerm: string): Observable<BulkEntity[]> => {
    return sendQuery('com.portbase.bezoekschip.common.api.consignments.queries.FindConsignments', <IE3FindConsignments>{
      term: searchTerm
    }).pipe(map((result: IE3ConsignmentsResult) => lodash
      .flatMap(result.consignments, c => mapToBulkEntities(c))
      .filter(c => this.isBulkConsignment(c))
      .filter(e => !this.existingDeclarations.some(d => d.status.phase !== "cancelled" && d.crn
        === e.consignmentProcessSummary.crn && d.data.goodsItems.some(g => g.previousDocument.documentNumber
          === e.consignmentProcessSummary.masterConsignment.consignmentNumber))))
    );
  };

  private isBulkConsignment(bulkEntity: BulkEntity): boolean {
    return getGoodsItems(bulkEntity.consignmentProcessSummary.masterConsignment)
      .every(g => g.placements && g.placements.every(p => !p.containerIdentificationNumber));
  }

  getAllFunction = () => {
    return [] // We do not show anything if you have not entered a search term
  }

  renderConsignments = (consignments: BulkEntity[]) => {
    this.bulkEntities = this.selected().concat(consignments.filter(
      consignment => this.selected().filter(
        selected => selected.consignmentProcessSummary.masterConsignment.consignmentNumber
          === consignment.consignmentProcessSummary.masterConsignment.consignmentNumber).length == 0));
  }

  trackByConsignmentNumber(index: number, obj: BulkEntity): any {
    return obj.consignmentProcessSummary.masterConsignment.consignmentNumber;
  }

  selectConsignments() {
    if (checkValidity(this.element)) {
      this.selectedConsignments = this.selected().map(c => <BulkItem>{
        crn: c.consignmentProcessSummary.crn,
        consignmentNumber: c.consignmentProcessSummary.masterConsignment.consignmentNumber,
        grossWeight: c.goodsItemEdited.grossWeight,
        netWeight: c.goodsItemEdited.netWeight,
        itemNumber: c.goodsItem.goodsItemNumber
      });
      this.reset();
    }
  }

  selected(): BulkEntity[] {
    return this.bulkEntities.filter(s => !!s['selected'] && !s['hidden']);
  }

  selectableConsignments(): BulkEntity[] {
    return this.bulkEntities
      .filter(c => this.isSelectableConsignment(c));
  }

  toggleSelectAll = () => {
    if (this.selected().length === this.selectableConsignments().length) {
      this.selectableConsignments().forEach(c => c['selected'] = false);
    } else {
      this.selectableConsignments().forEach(c => c['selected'] = true);
    }
  };

  reset() {
    this.searchTerm = '';
    this.bulkEntities = [];
  }

  hasSelectedConsignmentWithWarning(): boolean {
    return this.selected().map(bulkEntity => bulkEntity.consignmentProcessSummary.masterConsignment)
      .some(consignment => consignment.customsStatus || consignment.customsProcess);
  }

  isSelectableConsignment(bulkEntity: BulkEntity): boolean {
    const consignment = bulkEntity.consignmentProcessSummary.masterConsignment;

    return consignment.customsStatus !== IE3CustomsStatus.EU_COMMUNITY_GOODS_IN_TRANSHIPMENT
      && consignment.customsProcess !== IE3CustomsProcess.SEA_IN_SEA_OUT;
  }
}

export interface BulkEntity {
  consignmentProcessSummary: IE3ConsignmentProcessSummary;
  goodsItem: IE3GoodsItemSummary;
  goodsItemEdited: IE3GoodsItemSummary;
}

export function mapToBulkEntities(consignment: IE3ConsignmentProcessSummary): BulkEntity[] {
  return getGoodsItems(consignment.masterConsignment)
    .filter(g => g && g.placements && g.placements.every(p => !p.containerIdentificationNumber))
    .map((g: IE3GoodsItemSummary): BulkEntity => {
      return {
        consignmentProcessSummary: consignment,
        goodsItem: g,
        goodsItemEdited: cloneDeep(g)
      }
    });
}
