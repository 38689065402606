import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {VisitContext} from '../visit-context';
import moment from 'moment';

import {
  Berth,
  BerthVisit,
  BerthVisitInfo,
  BoatmenService,
  CancelVisit,
  Declaration,
  DeclarationType,
  DeclareTerminalPlanning,
  DeclareVisit,
  FinishTerminalPlanning,
  HinterlandEntry,
  IncomingOrder,
  IsAllowedToCancelVisit,
  LocalMovement,
  OrderCancellationReason,
  OrderStatus,
  PilotService,
  SaveNextVisitDeclaration,
  SaveVisit,
  SeaEntry,
  SyncVisitToWpcs,
  TugboatService,
  Visit,
  VisitDeclaration
} from '@portbase/bezoekschip-service-typescriptmodels';
import {
  checkValidity,
  clearValidation,
  openConfirmationModalWithCallback,
  scrollToTop,
  sendCommand,
  sendQuery,
  toTitleCase,
  uuid
} from '../../common/utils';
import {AppContext} from '../../app-context';
import {Alert} from "../../common/status-alert/alert";
import {ModalConfirmAutofocus, ModalConfirmAutofocusData} from 'src/app/common/modal/modal-confirm.component';
import {Router} from "@angular/router";
import lodash, {cloneDeep} from "lodash";
import {forkJoin, Observable, of} from "rxjs";
import {LoadDischargeInfo} from "./berth-visit/berth-visit-details/multi-berth/multi-berth.component";

@Component({
  selector: 'app-visit',
  templateUrl: './visit.component.html',
  styleUrls: ['./visit.component.css'],
})
export class VisitComponent implements OnInit {
  context = VisitContext;
  appContext = AppContext;
  sendWarnings: string[] = [];
  cancellingOrder: boolean = false;
  berthVisitsInChronologicalOrder: BerthVisit[];
  @ViewChild("berthVisitChronologicalOrder") berthVisitChronologicalRef: TemplateRef<any>;

  constructor(private router: Router, private element: ElementRef) {
  }

  ngOnInit(): void {
    const visit = this.context.visit;

    if (visit.visitDeclaration.portVisit.berthVisits.length === 0 && !visit.orderIncomingMovement) {
      visit.visitDeclaration.portVisit.berthVisits.push(<BerthVisit>{
        id: uuid(),
        nextMovement: {},
        visitPurposes: []
      });
    }
  }

  saveVisit = () => {
    clearValidation(this.element);

    this.processMultiBerths(VisitContext.visit.visitDeclaration).subscribe(s => {
      this.berthVisitsInChronologicalOrder = VisitContext.berthVisitsInChronologicalOrder(VisitContext.visit);
      if (VisitContext.visit.terminalPlanningEnabled && !lodash.isEqual(this.berthVisitsInChronologicalOrder, VisitContext.visit.visitDeclaration.portVisit.berthVisits)) {
        openConfirmationModalWithCallback((confirmed) => {
          if (confirmed === null) {
            return;
          }
          if (confirmed) {
            VisitContext.visit.visitDeclaration.portVisit.berthVisits = this.berthVisitsInChronologicalOrder;
          }
          sendSaveVisitCommand();
        }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
          type: "info",
          modalSize: 'xl',
          title: "Berth visits not in chronological order",
          confirmText: "Save suggested version",
          cancelText: "Save my version",
          body: this.berthVisitChronologicalRef
        }, 'static');
      } else {
        sendSaveVisitCommand();
      }
    });

    function sendSaveVisitCommand() {
      sendCommand('com.portbase.bezoekschip.common.api.visit.SaveVisit', <SaveVisit>{
        crn: VisitContext.visit.crn,
        visitDeclaration: VisitContext.visit.visitDeclaration,
      }, () => {
        VisitContext.replaceVisit(VisitContext.visit);
        AppContext.registerSuccess('The visit was saved successfully.');
      });
    }
  };

  declareTerminalPlanning = () => {

    if (checkValidity(this.element)) {
      if (this.validForTerminalPlanning(VisitContext.visit)) {
        this.processMultiBerths(VisitContext.visit.visitDeclaration).subscribe(s => {
          this.berthVisitsInChronologicalOrder = VisitContext.berthVisitsInChronologicalOrder(VisitContext.visit);
          if (VisitContext.visit.terminalPlanningEnabled && !lodash.isEqual(this.berthVisitsInChronologicalOrder, VisitContext.visit.visitDeclaration.portVisit.berthVisits)) {
            openConfirmationModalWithCallback((confirmed) => {
              if (confirmed === null) {
                return;
              }
              if (confirmed) {
                VisitContext.visit.visitDeclaration.portVisit.berthVisits = this.berthVisitsInChronologicalOrder;
              }
              sendDeclareTerminalPlanningCommand();
            }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
              type: "info",
              modalSize: 'xl',
              title: "Berth visits not in chronological order",
              confirmText: "Save and send suggested version",
              cancelText: "Save and send my version",
              body: this.berthVisitChronologicalRef
            }, 'static');
          } else {
            sendDeclareTerminalPlanningCommand();
          }
        });
      }
    }

    function sendDeclareTerminalPlanningCommand() {
      sendCommand('com.portbase.bezoekschip.common.api.visit.DeclareTerminalPlanning', <DeclareTerminalPlanning>{
        crn: VisitContext.visit.crn,
        vessel: VisitContext.visit.vessel,
        visitDeclaration: VisitContext.visit.visitDeclaration,
      }, () => {
        VisitContext.replaceVisit(VisitContext.visit);
        AppContext.registerSuccess('The terminal planning was declared successfully.');
      });
    }
  };

  validForTerminalPlanning = (visit: Visit) => {
    if (!visit.terminalPlanningEnabled) {
      return true;
    }
    const cancelledVisits = this.getCancelledTerminalPlannings(visit);
    if (cancelledVisits.length > 0) {
      const multipleCancelled = cancelledVisits.length > 1;
      AppContext.registerError(`Terminal planning${multipleCancelled ? 's' : ''} with callId (${cancelledVisits.map(bv => bv.callId).join(", ")})
          ${multipleCancelled ? 'are' : 'is'} cancelled and must be removed in order to save this visit.`);
    }
    return !AppContext.hasErrors();
  }

  getCancelledTerminalPlannings = (visit: Visit): BerthVisit[] =>
    visit.visitDeclaration.portVisit.berthVisits.filter(bv => {
      if (!bv.terminalPlanningEnabled) {
        return false;
      }
      const terminalVisit = VisitContext.getTerminalVisit(bv);
      return terminalVisit && terminalVisit.status?.cancelled;
    });

  trySendVisit = () => {
    this.sendWarnings = [];
    this.cancellingOrder = false;
    if (this.context.visit.orderIncomingMovement) {
      this.setEtaPort();
    }

    function servicesRequiredWithin12Hours(order: Order, now: moment.Moment) {
      return (order.pilotService?.required || order.tugboatAtDeparture?.required || order.boatmenAtDeparture?.required)
        && moment(order.etd).subtract(12, "hours").isBefore(now);
    }

    function getCurrentBerthVisitByid(orderId: string) {
      return VisitContext.visit.visitDeclaration.portVisit.berthVisits.find(value => value.id === orderId);
    }

    if (this.validateVisit() && this.validForTerminalPlanning(VisitContext.visit)) {
      //timing warnings
      const now = moment();
      const etaPort = moment(VisitContext.visit.visitDeclaration.portVisit.etaPort);
      const etdPort = moment(VisitContext.visit.visitDeclaration.portVisit.etdPort);
      const tidalGateDisclaimer = 'All tidal window information is provided \"as is,\" with no assurance or guarantee of correctness, completeness, accuracy or timeliness of the information, and without warranty of any kind, express or implied. Use of this information is at your own risk and the Port of Rotterdam Authority therefore accepts no liability whatsoever for any damage, loss or any other liabilities that result (direct or indirectly) from your and/or any third party’s use of the information provided.'
      const krveTerms = "The <a href=\"https://krve.nl/wp-content/uploads/NBC-ENG-2017.pdf\" target=\"_blank\">terms and conditions<\a> of KRVE apply.";
      let krveOrdered: boolean = false;
      let tidalRestricted: boolean = false;
      if (etaPort.isBefore(now) && VisitContext.getVisitStatus() === 'EXPECTED') {
        this.sendWarnings.push("Estimated time of arrival port is in the past");
      }
      if (etdPort.isAfter(moment().add(1, 'years'))) {
        this.sendWarnings.push("Estimated time of departure port is more than 12 months in the future");
      }
      if (VisitContext.visit.visitDeclaration.portVisit.berthVisits.find(
        v => v.ata && !!VisitContext.savedVisit.visitDeclaration.portVisit.berthVisits.find(sv => sv.id === v.id && !sv.ata))) {
        this.sendWarnings.push("Once a berth ATA is set you are not allowed to change the ATA anymore");
      }

      //incoming order warnings
      if (VisitContext.visit.orderIncomingMovement && VisitContext.isExpected()) {
        const incomingOrder: IncomingOrder = this.context.getIncomingOrder(VisitContext.visit);
        const savedIncomingOrder: IncomingOrder = this.context.getIncomingOrder(VisitContext.savedVisit);
        let portVisit = this.context.visit.visitDeclaration.portVisit;
        let dependency = portVisit.portEntry.origin === "SEA" ? (<SeaEntry>portVisit.portEntry).entryDependency : null;
        let dependentVesselHasNotBeenOrdered = incomingOrder.ordered && dependency
          && !this.isOrderedOrConfirmed(dependency.orderStatus);

        if (dependentVesselHasNotBeenOrdered) {
          if (dependency.dependencyType === "EXCHANGE") {
            this.sendWarnings.push("The movement of " + dependency.vesselName + " has not been ordered yet. Harbour master might reject your order of the incoming movement.");
          }
          if (dependency.dependencyType === "ENTRY_AFTER") {
            this.sendWarnings.push("The movement of " + dependency.vesselName + ", arriving before your vessel, has not been ordered yet.");
          }
        }
        if (this.etaPbpIsBeforeEtdBerth(incomingOrder)) {
          this.sendWarnings.push("The ETA pilot boarding place is before the departure of " + dependency.vesselName
            + ". The harbour master may reject your order.");
        }

        if (this.etaSecondVesselIsWithin1HourOfSecondVessel(incomingOrder)) {
          this.sendWarnings.push("The arrival at first berth is earlier than or within one hour of the arrival of " + dependency.vesselName
            + ". The harbour master may reject your order.");
        }
        if (incomingOrder.ordered && (!savedIncomingOrder || !savedIncomingOrder.ordered) && !(dependency && dependency.autoOrder)) {
          let krveOrderedInbound = !!incomingOrder && incomingOrder.boatmenAtArrival && incomingOrder.boatmenAtArrival.required && incomingOrder.boatmenAtArrival.serviceProvider.name === 'KRVE';
          let incomingTidalWindowStatus = this.context.visit.incomingTidalWindowStatus;
          let tidalRestrictedInbound = !!incomingTidalWindowStatus && incomingTidalWindowStatus.tidalShip;
          krveOrdered = krveOrderedInbound;
          tidalRestricted = tidalRestrictedInbound;

          this.sendWarnings.push("You are about to order the incoming movement.");
          if (!this.context.hasDeclaredOrAcceptedMdoh()) {
            if (moment(now).isBefore(moment(portVisit.portEntry.etaPilotBoardingPlace).subtract(6, "hours"))) {
              this.sendWarnings.push("Please note, you must submit a valid maritime declaration of health at least 6 hours before ETA pilot boarding place. Without this declaration, " +
                "the harbour master cannot approve your order.");
            } else {
              this.sendWarnings.push("Please note, you must submit a valid maritime declaration of health. Without this declaration, " +
                "the harbour master cannot approve your order.");
            }
          }
        }
        if (!incomingOrder.ordered && savedIncomingOrder.ordered) {
          this.cancellingOrder = true;
          this.sendWarnings.push("You are about to cancel the incoming movement");
        }
        if (incomingOrder.ordered && savedIncomingOrder.ordered && JSON.stringify(incomingOrder) !== JSON.stringify(savedIncomingOrder)) {
          this.sendWarnings.push("You have changed information regarding your order of the incoming movement");
        }
        if (portVisit.pilotStation && (portVisit.pilotStation.code === "MC" || portVisit.pilotStation.code === "KP")) {
          if (moment(portVisit.portEntry.etaPilotBoardingPlace).isBefore(moment((<SeaEntry>portVisit.portEntry).etaSeaBuoy))) {
            this.sendWarnings.push("The ETA pilot boarding place is before the ETA sea buoy");
          }
        }
        if (this.showFirstTimeAutoOrderMessage()) {
          if (dependency.dependencyType === 'EXCHANGE') {
            this.sendWarnings.push("You have chosen automatic ordering. As an agent, you remain responsible for submitting an order. " +
              "Your order will be sent when the departing ship with which you want to exchange is ordered. " +
              "Your ETA will be updated automatically when the ETD of the other vessel changes. " +
              "Your order will be automatically canceled if the order of the departing ship is canceled.");
          } else {
            this.sendWarnings.push("You have chosen automatic ordering. As an agent, you remain responsible for submitting an order. " +
              "Your order will be sent when the ship which you want to enter after is ordered. " +
              "Your ETA will be updated automatically when the ETA of the other vessel changes. " +
              "Your order will be automatically canceled if the order of the departing ship is canceled.");
          }
        }
      }

      if (VisitContext.visit.orderNauticalServices) {
        //order warnings
        const orders: Order[] = getOrders(VisitContext.visit);
        const savedOrders: Order[] = getOrders(VisitContext.savedVisit);
        let newOrder = orders.find(o => !savedOrders.find(so => so.id === o.id));
        if ((orders.length > 0 && !VisitContext.findLatestDeclaration(DeclarationType.VISIT)) || newOrder) {
          let krveOrderedShifting = !!newOrder && newOrder.boatmenAtDeparture.required && newOrder.boatmenAtDeparture.serviceProvider.name === 'KRVE';
          let tidalRestrictedShifting = !!newOrder && newOrder.id && !!this.context.visit.berthVisitInfos[newOrder.id]
            && this.context.visit.berthVisitInfos[newOrder.id].harbourMasterInfo
            && this.context.visit.berthVisitInfos[newOrder.id].harbourMasterInfo.tidalWindowStatus
            && this.context.visit.berthVisitInfos[newOrder.id].harbourMasterInfo.tidalWindowStatus.tidalShip;
          this.sendWarnings.push("You are about to order movement(s).");
          krveOrdered = krveOrdered || krveOrderedShifting;
          tidalRestricted = tidalRestricted || tidalRestrictedShifting;
        }
        if (savedOrders.find(so => !orders.find(o => so.id === o.id))) {
          this.cancellingOrder = true;
          this.sendWarnings.push("You are about to cancel previously ordered movement(s)");
        }
        if (orders.find(o => {
          const savedOrder = savedOrders.find(so => so.id === o.id);
          return savedOrder && JSON.stringify(savedOrder) !== JSON.stringify(o);
        })) {
          this.sendWarnings.push("You have changed information regarding your order of pilots/tugboats/boatmen");
        }

        orders.filter(value => value.atd == null)
          .filter(order => servicesRequiredWithin12Hours(order, now))
          .filter(order => this.context.visit.vessel.lngShip
            || getCurrentBerthVisitByid(order.id)?.nextMovement.vesselDraft > 17.4)
          .map(order => this.context.berthVisitById(order.id))
          .filter(berthVisit => berthVisit != null)
          .map(berthVisit => toTitleCase(berthVisit.berth.name))
          .forEach(berth =>
            this.sendWarnings.push("This ship needs to be ordered at least 12 hours before departure from " + berth));
      }

      //first movement warnings
      if (VisitContext.visit.visitDeclaration.portVisit.firstMovement) {
        if (VisitContext.visit.visitDeclaration.portVisit.firstMovement.numberOfCrew === 0) {
          this.sendWarnings.push("Number of crew on first movement is 0");
        }
      }

      //BerthVisit removed while there are containers being discharged
      const removedBerthVisitsWithCargo = this.getRemovedBerthVisitsWithCargo();
      if (removedBerthVisitsWithCargo.length > 0) {
        const removedBerthVisitsWarning = removedBerthVisitsWithCargo.map(bv => `<li>${bv.berth.terminalName}</li>`).join("\n");
        this.sendWarnings.push("The following removed berth visits have cargo declared, please move the cargo to another terminal." +
          `<ul>${removedBerthVisitsWarning}</ul>`);
      }

      if (krveOrdered) {
        this.sendWarnings.push(krveTerms);
      }
      if (tidalRestricted) {
        this.sendWarnings.push(tidalGateDisclaimer);
      }

      if (this.sendWarnings.length === 0) {
        this.doSendVisit();
      } else {
        this.showSendWarningsModal(this.cancellingOrder);
      }
    }
  };

  private isOrderedOrConfirmed(orderStatus: OrderStatus) {
    return ["ORDERED", "CONFIRMED", "ETD_CHANGED", "ETA_CHANGED"].indexOf(orderStatus) > -1;
  }

  private getRemovedBerthVisitsWithCargo = () => {
    const savedBerthVisits = VisitContext.savedVisit.visitDeclaration.portVisit.berthVisits;
    const berthVisits = VisitContext.visit.visitDeclaration.portVisit.berthVisits;
    return lodash.differenceWith(savedBerthVisits, berthVisits, (a, b) => a.id === b.id)
      .filter(bv => bv.berth?.terminalCode)
      .filter(bv => this.getConsignmentsByTerminal(VisitContext.visit, bv.berth.terminalCode)) || [];
  }

  private getConsignmentsByTerminal = (visit: Visit, terminalCode: string) =>
    lodash.flatMap(visit.importDeclarations || [], (i => i.consignments
      ?.filter(c => c.terminal?.terminalCode === terminalCode)));

  private showFirstTimeAutoOrderMessage() {
    let portEntry = this.context.visit.visitDeclaration.portVisit.portEntry;
    return portEntry.origin === 'SEA'
      && (<SeaEntry>portEntry).entryDependency && (<SeaEntry>portEntry).entryDependency.autoOrder
      && (((<SeaEntry>this.context.savedVisit.visitDeclaration.portVisit.portEntry).entryDependency
        && !(<SeaEntry>this.context.savedVisit.visitDeclaration.portVisit.portEntry).entryDependency.autoOrder) || !(<SeaEntry>this.context.savedVisit.visitDeclaration.portVisit.portEntry).entryDependency)
      && !this.context.visit.visitDeclaration.portVisit.firstMovement.order;
  }

  private etaPbpIsBeforeEtdBerth(incomingOrder: IncomingOrder) {
    let portVisit = this.context.visit.visitDeclaration.portVisit;
    let dependency = portVisit.portEntry.origin === "SEA" ? (<SeaEntry>portVisit.portEntry).entryDependency : null;
    let isExchange = incomingOrder.ordered && dependency && dependency.dependencyType === "EXCHANGE";

    return isExchange && this.isBerthInEuropoortORVesselLargerThan200Meters() && !dependency.autoOrder
      && dependency.estimatedTimeBerth && this.context.visit.visitDeclaration.portVisit.portEntry.etaPilotBoardingPlace
      && moment(this.context.visit.visitDeclaration.portVisit.portEntry.etaPilotBoardingPlace)
        .isBefore(moment(dependency.estimatedTimeBerth));
  }

  private etaSecondVesselIsWithin1HourOfSecondVessel(incomingOrder: IncomingOrder) {
    let portVisit = this.context.visit.visitDeclaration.portVisit;
    let dependency = portVisit.portEntry.origin === "SEA" ? (<SeaEntry>portVisit.portEntry).entryDependency : null;
    let isEntryAfter = incomingOrder.ordered && dependency && dependency.dependencyType === "ENTRY_AFTER";

    return isEntryAfter && !dependency.autoOrder && dependency.estimatedTimeBerth &&
      this.context.visit.visitDeclaration.portVisit.berthVisits[0].eta && moment(dependency.estimatedTimeBerth)
        .add(1, "hour").isAfter(moment(this.context.visit.visitDeclaration.portVisit.berthVisits[0].eta));
  }

  private isBerthInEuropoortORVesselLargerThan200Meters() {
    let hasFirstBerth = this.context.visit.visitDeclaration.portVisit.berthVisits[0] && this.context.visit.visitDeclaration.portVisit.berthVisits[0].berth != null;

    let isBerthInEuropoort = false;
    if (this.context.visit.vessel.fullLength >= 200) {
      return true;
    }
    if (hasFirstBerth) {
      sendQuery('com.portbase.bezoekschip.common.api.visit.IsBerthInEuroPoort', {
        berthCode:
        this.context.visit.visitDeclaration.portVisit.berthVisits[0].berth.code
      })
        .subscribe(result => {
          isBerthInEuropoort = result;
        });
    }
    return isBerthInEuropoort;
  }

  doSendVisit = () => {
    this.sendWarnings = [];
    if (this.context.visit.orderIncomingMovement) {
      this.setEtaPort();
    }

    if (this.validateVisit()) {
      this.syncTimesForOrders();

      this.processMultiBerths(VisitContext.visit.visitDeclaration)
        .subscribe(s => {
          this.berthVisitsInChronologicalOrder = VisitContext.berthVisitsInChronologicalOrder(VisitContext.visit);
          if (VisitContext.visit.terminalPlanningEnabled && !lodash.isEqual(this.berthVisitsInChronologicalOrder, VisitContext.visit.visitDeclaration.portVisit.berthVisits)) {
            openConfirmationModalWithCallback((confirmed) => {
              if (confirmed === null) {
                return;
              }
              if (confirmed) {
                VisitContext.visit.visitDeclaration.portVisit.berthVisits = this.berthVisitsInChronologicalOrder;
              }
              sendDeclareVisitCommand();
            }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
              type: "info",
              modalSize: 'xl',
              title: "Berth visits not in chronological order",
              confirmText: "Save and send suggested version",
              cancelText: "Save and send my version",
              body: this.berthVisitChronologicalRef
            }, 'static');
          } else {
            sendDeclareVisitCommand();
          }
        });
    }

    function sendDeclareVisitCommand() {
      sendCommand('com.portbase.bezoekschip.common.api.visit.DeclareVisit', <DeclareVisit>{
        crn: VisitContext.visit.crn,
        visitDeclaration: VisitContext.visit.visitDeclaration,
      }, () => {
        VisitContext.replaceVisit(VisitContext.visit);
        AppContext.registerSuccess('The visit was sent successfully.');
      });
    }
  };

  syncToWpcs = () => {
    if (checkValidity(this.element)) {
      sendCommand('com.portbase.bezoekschip.common.api.visit.SyncVisitToWpcs', <SyncVisitToWpcs>{
        crn: VisitContext.visit.crn,
        visitDeclaration: VisitContext.visit.visitDeclaration,
      }, () => {
        VisitContext.replaceVisit(VisitContext.visit);
        AppContext.registerSuccess('The visit was sent successfully.');
      });
    }
  };

  saveForNextDeclarant() {
    let componentNames = ['app-port-departure', '.validate-for-next-declarant', 'app-next-exit-point', 'app-next-next-ports'];
    if (!this.allComponentsAreValid(componentNames)) {
      return;
    }

    sendCommand('com.portbase.bezoekschip.common.api.visit.SaveNextVisitDeclaration', <SaveNextVisitDeclaration>{
      crn: VisitContext.visit.crn,
      nextVisitDeclaration: VisitContext.visit.nextVisitDeclaration,
    }, () => {
      VisitContext.replaceVisit(VisitContext.visit);
      AppContext.registerSuccess('The changes were stored successfully.');
    });
  }

  finishTerminalPlanning() {
    sendCommand('com.portbase.bezoekschip.common.api.visit.terminal.FinishTerminalPlanning', <FinishTerminalPlanning>{
      crn: VisitContext.visit.crn
    }, () => {
      VisitContext.replaceVisit(VisitContext.visit);
      AppContext.registerSuccess('Planning approved successfully.');
      this.router.navigate(['/details/' + VisitContext.visit.crn]);
    });
  }

  findLatestDeclaration = (): Declaration => this.context.findLatestDeclaration(
    this.context.visitInTerminalPlanning() ? DeclarationType.TERMINAL_PLANNING : DeclarationType.VISIT);

  latestVisitDeclarationsRejectReasons(): string[] {
    const type: DeclarationType[] = this.context.visitInTerminalPlanning()
      ? [DeclarationType.TERMINAL_PLANNING]
      : [DeclarationType.VISIT, DeclarationType.NOA, DeclarationType.NOD];
    return lodash.uniqBy(type.map(t => this.context.findLatestDeclaration(t))
      .filter(t => t)
      .map(t => t.rejectReasons)
      .filter(r => r), s => s);
  }

  trackByString = (index: number, s: string) => s;

  private allComponentsAreValid(selectors: string[]): boolean {
    AppContext.clearVisitAlerts();
    const nativeElement: HTMLElement = this.element['nativeElement'] || this.element;

    function isValidElement(selector) {
      let nodeListOf = nativeElement.querySelectorAll<HTMLElement>(selector);
      let isValid = true;
      nodeListOf.forEach(element => {
        let isValidComponent = checkValidity(element, false);
        isValid = isValid && isValidComponent;
      });
      return isValid;
    }

    let someAreNotValid = selectors.map(selector => {
      return isValidElement(selector);
    }).some(cb => {
      return !cb
    });

    return !someAreNotValid;
  }

  private syncTimesForOrders = () => {
    if (VisitContext.visit.orderIncomingMovement && VisitContext.isExpected()) {
      if (VisitContext.visit.visitDeclaration.portVisit.firstMovement.order) {
        VisitContext.visit.visitDeclaration.portVisit.portEntry.requestedEtaPilotBoardingPlace =
          VisitContext.visit.visitDeclaration.portVisit.portEntry.etaPilotBoardingPlace;
        if (VisitContext.visit.additionalIncomingMovementInfo &&
          VisitContext.visit.additionalIncomingMovementInfo.etaPilotBoardingPlace !==
          VisitContext.visit.visitDeclaration.portVisit.portEntry.requestedEtaPilotBoardingPlace) {
          VisitContext.visit.additionalIncomingMovementInfo.etaPilotBoardingPlace = null;
        }
      } else {
        VisitContext.visit.visitDeclaration.portVisit.portEntry.requestedEtaPilotBoardingPlace = null;
      }
    }
    VisitContext.visit.visitDeclaration.portVisit.berthVisits.forEach(v => {
      if (!v.atd) {
        v.requestedEtd = v.nextMovement.order ? v.etd : null;
        const berthVisitInfo = VisitContext.visit.berthVisitInfos[v.id];
        if (berthVisitInfo && berthVisitInfo.harbourMasterInfo &&
          berthVisitInfo.harbourMasterInfo.etd !== v.requestedEtd) {
          VisitContext.visit.berthVisitInfos[v.id].harbourMasterInfo.etd = null;
        }
      }
    })
  };

  private validateVisit(): boolean {
    const result = checkValidity(this.element);
    if (!result) {
      return false;
    }
    const now = moment();

    // incoming orders
    if (VisitContext.visit.orderIncomingMovement && VisitContext.isExpected()) {
      const incomingOrder: IncomingOrder = this.context.getIncomingOrder(VisitContext.visit);
      const savedIncomingOrder: IncomingOrder = this.context.getIncomingOrder(VisitContext.savedVisit);

      let newOrChangedOrder = incomingOrder.ordered && (!savedIncomingOrder || JSON.stringify(savedIncomingOrder) !== JSON.stringify(incomingOrder));
      if (newOrChangedOrder) {
        let hasTugBoatsOrPilotService = (incomingOrder.pilotService && incomingOrder.pilotService.required) ||
          (incomingOrder.passingThrough ? (incomingOrder.passingThroughTugboats && incomingOrder.passingThroughTugboats.required) :
            (incomingOrder.tugboatAtArrival && incomingOrder.tugboatAtArrival.required));
        let hasBoatMenOrdered = incomingOrder.boatmenAtArrival && incomingOrder.boatmenAtArrival.required;

        // ALLEEN VOOR ZEE
        const incomingMovementHarbourMasterInfo = this.context.visit.incomingMovementHarbourMasterInfo;
        if (!incomingMovementHarbourMasterInfo || !incomingMovementHarbourMasterInfo.pilotOnBoard) {
          if (incomingOrder.pilotStation.atSea) {
            if (incomingOrder.pilotStation.code === 'MC' || incomingOrder.pilotStation.code === 'KP' || incomingOrder.pilotStation.code === 'E13') {
              if (hasTugBoatsOrPilotService) {
                if (moment(incomingOrder.etaPilotBoardingPlace).subtract(3, "hours").isBefore(now)) {
                  AppContext.registerError("Incoming movement should be ordered at least three hours before arrival at pilot boarding place ("
                    + incomingOrder.pilotStation.name + ") when pilots or tugboats are required");
                }
              } else {
                if (hasBoatMenOrdered) {
                  if (moment(incomingOrder.etaPilotBoardingPlace).subtract(30, "minutes").isBefore(now)) {
                    AppContext.registerError("Incoming movement should be ordered at least 30 minutes before arrival at pilot boarding place ("
                      + incomingOrder.pilotStation.name + ") when only boatmen are required");
                  }
                } else if (moment(incomingOrder.etaPilotBoardingPlace).subtract(15, "minutes").isBefore(now)) {
                  AppContext.registerError("Incoming movement should be ordered at least 15 minutes before arrival at pilot boarding place ("
                    + incomingOrder.pilotStation.name + ") when no nautical services are required");
                }
              }
            } else if (moment(incomingOrder.etaPilotBoardingPlace).subtract(12, "hours").isBefore(now)) {
              AppContext.registerError("Incoming movement should be ordered at least twelve hours before arrival at pilot boarding place ("
                + incomingOrder.pilotStation.name + ")");
            }
          } else { // Achterland only
            if (hasTugBoatsOrPilotService && moment(incomingOrder.etaPilotBoardingPlace).subtract(2, "hours").isBefore(now)) {
              AppContext.registerError("Pilots and tugboats should be ordered at least two hours before arrival");
            } else {
              if (!incomingOrder.passingThrough && hasBoatMenOrdered
                && moment(incomingOrder.etaPilotBoardingPlace).subtract(30, "minutes").isBefore(now)) {
                AppContext.registerError("Boatmen should be ordered at least thirty minutes before arrival");
              }
              if (((!incomingOrder.passingThrough && !hasBoatMenOrdered) || incomingOrder.passingThrough)
                && moment(incomingOrder.etaPilotBoardingPlace).subtract(15, "minutes").isBefore(now)) {
                AppContext.registerError("Movement should be ordered at least fifteen minutes before arrival");
              }
            }
          }
        }

      }
    }

    // Storm Pilotage
    let visit = VisitContext.visit;
    let portvisit = visit.visitDeclaration.portVisit;

    let firstMovement = portvisit.firstMovement;
    if (firstMovement) {
      let isInboundForRotterdam = visit.portOfCall.port.locationUnCode === 'NLRTM'
        && visit.orderIncomingMovement
        && portvisit.portEntry.origin === 'SEA'
        && portvisit.portEntry.intention === 'REQUEST_FOR_ENTRY'
        && (!portvisit.ataPort && !portvisit.atdPort && !portvisit.berthVisits.find(v => !!v.ata));

      let stormPilotageInformationFirstMovement = firstMovement.stormPilotageInformation;
      let hasPECHolder = this.hasPECHolder(firstMovement);
      let isLoaRequired = VisitContext.isLoaRequired(firstMovement, visit.vessel.fullLength, true);
      let remotePilotageNotAcceptedFirstMovement = !stormPilotageInformationFirstMovement
        || stormPilotageInformationFirstMovement.remotePilotage !== true;
      let firstBirthEta = portvisit.berthVisits[0]?.ata;

      if (!firstBirthEta && isLoaRequired && !hasPECHolder && remotePilotageNotAcceptedFirstMovement
        && isInboundForRotterdam) {
        AppContext.registerError("Please note that during the storm pilotage, it is required to make use of the shore based pilotage. " +
          "If the captain doesn’t accept the shore based pilotage, it is not allowed to enter the Port of Rotterdam.")
      }
    }

    let lastMovement = VisitContext.getLastMovement();
    if (lastMovement) {
      let hasPECHolder = this.hasPECHolder(lastMovement);

      let isLoaRequired = VisitContext.isLoaRequired(lastMovement, visit.vessel.fullLength, false);
      let stormPilotageInformationLastMovement = lastMovement.stormPilotageInformation;
      let remotePilotageNotAcceptedLastMovement = !stormPilotageInformationLastMovement
        || stormPilotageInformationLastMovement.remotePilotage !== true;

      if (isLoaRequired && !hasPECHolder && remotePilotageNotAcceptedLastMovement
        && visit.portOfCall.port.locationUnCode === 'NLRTM') {
        AppContext.registerError("Please note that during the storm pilotage, it is required to make use of the shore based pilotage. " +
          "If the captain doesn’t accept the shore based pilotage, it is not allowed to exit the Port of Rotterdam.")
      }
    }

    if (VisitContext.visit.orderNauticalServices) {
      const orders: Order[] = getOrders(VisitContext.visit);
      const savedOrders: Order[] = getOrders(VisitContext.savedVisit);
      orders.filter(o => {
        const savedOrder = savedOrders.find(so => so.id === o.id);
        return !savedOrder || JSON.stringify(savedOrder) !== JSON.stringify(o);
      }).forEach(o => {
        function hasPilotOnBoard(berthVisitInfos: { [p: string]: BerthVisitInfo }, id: string) {
          return berthVisitInfos[id] && berthVisitInfos[id].harbourMasterInfo
            && berthVisitInfos[id].harbourMasterInfo.pilotOnBoard;
        }

        if (!o.atd && !hasPilotOnBoard(this.context.visit.berthVisitInfos, o.id)) {
          if ((o.pilotService.required || (o.tugboatAtDeparture && o.tugboatAtDeparture.required))
            && moment(o.etd).subtract(2, "hours").isBefore(now)) {
            AppContext.registerError("Pilots and tugboats should be ordered at least two hours before departure");
          }
          if (((o.boatmenAtDeparture && o.boatmenAtDeparture.required))
            && moment(o.etd).subtract(30, "minutes").isBefore(now)) {
            AppContext.registerError("Boatmen should be ordered at least thirty minutes before departure");
          }
          if (moment(o.etd).subtract(15, "minutes").isBefore(now)) {
            AppContext.registerError("An order should be placed at least fifteen minutes before departure");
          }
        }
      });
    }
    return !AppContext.hasErrors();
  }

  doCancelVisit = () => {
    sendQuery('com.portbase.bezoekschip.common.api.visit.IsAllowedToCancelVisit', <IsAllowedToCancelVisit>{
      crn: VisitContext.visit.crn
    }, {caching: false, showSpinner: true}).subscribe(mayBeCancelled => {
      if (mayBeCancelled) {
        sendCommand('com.portbase.bezoekschip.common.api.visit.CancelVisit', <CancelVisit>{
          crn: VisitContext.visit.crn
        }, () => {
          VisitContext.visit.cancelled = true;
          VisitContext.replaceVisit(VisitContext.visit);
          AppContext.registerSuccess('The visit was cancelled successfully.');
        });
      } else {
        AppContext.registerError('Visit cannot be cancelled because cargo is registered and/or reported for its berths or ports');
        scrollToTop();
      }
    });
  };

  cancelVisit = () => {
    openConfirmationModalWithCallback((confirmed) => {
      if (confirmed) {
        this.doCancelVisit();
      }
    }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
      type: "danger",
      title: "Danger",
      message: "Once a visit is cancelled it is no longer possible to make changes.",
      question: "Are you sure that you want to cancel this visit?",
      confirmText: "Yes",
      cancelText: "No"
    }, 'static');
  };

  getBerthCode(id: string): string {
    const berthVisit = VisitContext.visit.visitDeclaration.portVisit.berthVisits.find(bv => {
      return bv.id === id
    });
    if (berthVisit && berthVisit.berth) {
      return berthVisit.berth.code;
    }
    return null;
  }

  hasAtd(id: string): boolean {
    const berthVisit = VisitContext.visit.visitDeclaration.portVisit.berthVisits.find(bv => {
      return bv.id === id
    });
    return !!berthVisit?.atd;
  }

  departureCarrierRequired(): boolean {
    const berthVisits = VisitContext.visit.visitDeclaration.portVisit.berthVisits;
    return berthVisits.length > 0 ? !!berthVisits[berthVisits.length - 1].etd : false;
  }

  getShiftedOrders(): BerthVisit[] {
    return VisitContext.savedVisit.berthVisitInfos ? VisitContext.savedVisit.visitDeclaration.portVisit.berthVisits.filter(v => {
      const berthVisitInfo = VisitContext.savedVisit.berthVisitInfos[v.id];
      return berthVisitInfo && berthVisitInfo.harbourMasterInfo && berthVisitInfo.harbourMasterInfo.etd &&
        v.requestedEtd && !moment(berthVisitInfo.harbourMasterInfo.etd).isSame(v.requestedEtd);
    }) : [];
  }

  private setEtaPort() {
    if (VisitContext.visit.visitDeclaration.portVisit.portEntry.origin === "SEA") {
      VisitContext.visit.visitDeclaration.portVisit.etaPort = (<SeaEntry>VisitContext.visit.visitDeclaration.portVisit.portEntry).etaSeaBuoy;
    } else {
      VisitContext.visit.visitDeclaration.portVisit.etaPort = (<HinterlandEntry>VisitContext.visit.visitDeclaration.portVisit.portEntry).etaPilotBoardingPlace;
    }
  }

  get userIsNextDeclarantOrNextOwner(): boolean {
    return VisitContext.isOrganisationNextDeclarant();
  }

  userIsDeclarantOrOwner() {
    return VisitContext.isOrganisationCurrentVisitDeclarant()
  }


  userIsAllowedToSave() {
    const declarationType: DeclarationType = VisitContext.visit.portOfCall.paDeclarationRequired ?
      DeclarationType.VISIT : DeclarationType.WPCS;
    const declaration: Declaration = VisitContext.findLatestDeclaration(declarationType);
    const terminalPlanningDeclaration: Declaration =
      VisitContext.findLatestDeclaration(DeclarationType.TERMINAL_PLANNING);
    const hasNoDeclaration = (!declaration || Object.keys(declaration).length === 0) && (!terminalPlanningDeclaration ||
      Object.keys(terminalPlanningDeclaration).length === 0);

    return hasNoDeclaration && !this.userIsNextDeclarantOrNextOwner;
  }

  userIsAllowedToDeclareVisit() {
    let paDeclarationRequired = VisitContext.visit.portOfCall.paDeclarationRequired;
    return paDeclarationRequired && !this.userIsNextDeclarantOrNextOwner && this.userIsDeclarantOrOwner();
  }

  userIsAllowedToSyncToWpcs() {
    let paDeclarationRequired = VisitContext.visit.portOfCall.paDeclarationRequired;
    return !paDeclarationRequired
  }

  userIsNextOwnerOrDeclarant() {
    return VisitContext.isOrganisationNextDeclarant();
  }

  isDisabled() {
    const declaration = VisitContext.findLatestDeclaration(DeclarationType.VISIT);
    return (declaration && declaration.status === 'DECLARED') || VisitContext.isUserOnlyCargoDeclarant();
  }

  private userIsDeclarant(): boolean {
    return this.appContext.userProfile.organisation?.shortName === this.context.visit.declarant.shortName;
  }

  userIsOwner(): boolean {
    return this.context.visit.owner ?
      this.appContext.userProfile.organisation?.shortName === this.context.visit.owner.shortName : false;
  }

  isPermittedToAddBerth() {
    return this.userIsDeclarant() || this.userIsNextOwnerOrDeclarant() || this.appContext.isAdmin() || this.userIsOwner();
  }

  addBerth = () => {
    if (this.userIsDeclarant() || this.userIsOwner() || this.appContext.isAdmin()) {
      let berthVisits = this.context.visit.visitDeclaration.portVisit.berthVisits;
      let newBerthVisit = <BerthVisit>{
        id: uuid(),
        nextMovement: {},
        visitPurposes: []
      };
      if (this.context.visit.nextDeclarant) {
        berthVisits.splice(berthVisits.length - 1, 0, newBerthVisit);
      } else {
        berthVisits.push(newBerthVisit);
      }
      this.context.visit.visitDeclaration.portVisit.passingThroughTugboats = null;

    } else if (this.userIsNextOwnerOrDeclarant()) {
      this.context.visit.nextVisitDeclaration.nextBerthVisits = this.context.visit.nextVisitDeclaration.nextBerthVisits || [];
      this.context.visit.nextVisitDeclaration.nextBerthVisits.push(<BerthVisit>{
        id: uuid(),
        nextMovement: {},
        visitPurposes: []
      });
    } else {
      this.appContext.addAlert(<Alert>{
        content: "You are not allow to add a new berth visit", level: "warning", type: "visit"
      })
    }
  };

  hasPECHolder(movement: LocalMovement): boolean {
    return movement.pilotExemption && !!movement.pilotExemption.holderName;
  }

  private showSendWarningsModal(cancellingOrder: boolean) {
    function setCancellationReasonForCancelledOrders(context, reason: string) {
      const incomingOrder: IncomingOrder = VisitContext.getIncomingOrder(context.visit);
      const savedIncomingOrder: IncomingOrder = VisitContext.getIncomingOrder(context.savedVisit);
      if (!incomingOrder.ordered && savedIncomingOrder.ordered) {
        context.visit.visitDeclaration.portVisit.firstMovement.orderCancellationReason = <OrderCancellationReason>reason;
      }
      const orders: Order[] = getOrders(context.visit);
      const savedOrders: Order[] = getOrders(context.savedVisit);
      savedOrders.filter(so => !orders.find(o => so.id === o.id)).forEach((v: Order) => {
        const nextMovement = VisitContext.berthVisitInVisitById(context.visit, v.id)?.nextMovement;

        if (!!nextMovement) {
          nextMovement.orderCancellationReason = <OrderCancellationReason>reason;
        }
      });
    }

    openConfirmationModalWithCallback((confirmed, reason) => {
      if (confirmed) {
        if (reason) {
          setCancellationReasonForCancelledOrders(this.context, reason);
        }
        this.doSendVisit();
      }
      this.sendWarnings = [];
    }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
      type: "warning",
      title: "Warning",
      message: "Are you sure that you want to send the declaration?",
      beforePointsMessage: "Note the following:",
      points: this.sendWarnings,
      withChosableReason: cancellingOrder,
      chosableMessage: "Please provide a justification for cancelling orders:",
      chosableReasons: VisitComponent.cancelReasons,
      chosableReasonFormatter: VisitComponent.cancelReasonFormatter,
      confirmText: "Send"
    }, 'static');
  }

  static cancelReasons: OrderCancellationReason[] = [OrderCancellationReason.TERMINAL_NOT_READY, OrderCancellationReason.VESSEL_NOT_READY, OrderCancellationReason.BUNKERING_NOT_READY,
    OrderCancellationReason.PAPERWORK_NOT_READY, OrderCancellationReason.OTHER_CUSTOMER_ORDERS, OrderCancellationReason.SURVEYOR_NOT_READY];
  static cancelReasonFormatter = (value: OrderCancellationReason) => {
    switch (value) {
      case 'TERMINAL_NOT_READY':
        return 'The terminal is not ready';
      case 'VESSEL_NOT_READY':
        return 'The vessel is not ready';
      case 'BUNKERING_NOT_READY':
        return 'The bunkering is not ready';
      case 'PAPERWORK_NOT_READY':
        return 'The paperwork is not ready';
      case 'OTHER_CUSTOMER_ORDERS':
        return 'Other customer orders';
      case 'SURVEYOR_NOT_READY':
        return 'The surveyor is not ready';
      default:
        throw Error('Unknown order cancellation reason of value: ' + value);
    }
  };


  private processMultiBerths(visitDeclaration: VisitDeclaration): Observable<string[]> {
    const tasks: Observable<string>[] = [];
    VisitContext.multiBerthLoadDischargeInfo?.forEach((value, key) => {
      const berthVisit: BerthVisit = visitDeclaration.portVisit.berthVisits.find(bv => bv.id === key);
      if (VisitContext.isMultiBerthTerminal(berthVisit)) {
        let indexOfBerthVisit = visitDeclaration.portVisit.berthVisits.indexOf(berthVisit);
        value.filter(this.berthVisitWithLoadDischargeInfo).forEach((info, index) => {
          const berthVisitToUse: BerthVisit = index === 0 ? berthVisit : cloneDeep(berthVisit);
          berthVisitToUse.quay = info.quay;
          berthVisitToUse.load = info.load;
          berthVisitToUse.discharge = info.discharge;
          berthVisitToUse.restow = info.restow;
          if (index > 0) {
            berthVisitToUse.id = uuid();
            const nextCallId: Observable<string> = VisitContext.getNextCallId();
            tasks.push(nextCallId);
            nextCallId.subscribe(value => berthVisitToUse.callId = value.toString());
            visitDeclaration.portVisit.berthVisits.splice(indexOfBerthVisit + 1, 0, berthVisitToUse);
          }
        });
      }
    });
    return tasks.length > 0 ? forkJoin(tasks) : of([]);
  }

  private berthVisitWithLoadDischargeInfo = (v: LoadDischargeInfo) => v.discharge > 0 || v.load > 0 || v.restow > 0;
}

interface Order {
  id: string;
  etd: string;
  atd: string;
  tugboatAtDeparture: TugboatService;
  boatmenAtDeparture: BoatmenService;
  berth: Berth;
  pilotService: PilotService;
}

function getOrders(visit: Visit): Order[] {
  const result: Order[] = [];
  for (const v of visit.visitDeclaration.portVisit.berthVisits) {
    if (v.nextMovement.order) {
      result.push({
        id: v && v.id,
        etd: v && v.etd,
        atd: v && v.atd,
        tugboatAtDeparture: v && v.tugboatAtDeparture,
        boatmenAtDeparture: v && v.boatmenAtDeparture,
        pilotService: v.nextMovement.pilotService,
        berth: v.berth
      })
    }
  }
  return result;
}
