import {AfterViewInit, Component, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractValueAccessorComponent} from '../../../common/component/value-accessor.component';
import {
  BerthVisitInfo,
  CargoDescription,
  GetCurrentUserPreferences,
  LocalMovement,
  PilotExemption,
  UserPreferences
} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from '../../visit-context';
import {sendQuery} from '../../../common/utils';

@Component({
	selector: 'app-movement',
	templateUrl: './movement.component.html',
	styleUrls: ['./movement.component.css'],
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MovementComponent), multi: true}
	],
})
export class MovementComponent extends AbstractValueAccessorComponent<LocalMovement> implements OnInit, AfterViewInit {
	context = VisitContext;
	cargos: CargoDescription[] = [CargoDescription.BALLAST_CONDITION, CargoDescription.BREAKBULK_GENERAL_CARGO,
    CargoDescription.BULK_DRY, CargoDescription.BULK_DRY_FUMIGATED, CargoDescription.BULK_LIQUID_GAS,
    CargoDescription.CONTAINERIZED, CargoDescription.FROZEN_REFRIGERATED_CARGO, CargoDescription.PASSENGERS,
    CargoDescription.PROJECT_CARGO, CargoDescription.RORO_VEHICLES];
	cargoFormatter = (value: any) => {
		switch (value) {
			case "BALLAST_CONDITION": return "Ballast condition";
			case "BREAKBULK": return "Breakbulk";
			case "CASCO": return "Casco";
			case "CONTAINERIZED": return "Containerized cargo";
			case "ENVIRONMENTAL_POLLUTANT_CARGO": return "Environmental pollutant cargo";
			case "FROZEN_CARGO": return "Frozen cargo";
			case "FROZEN_REFRIGERATED_CARGO": return "Frozen or refrigerated cargo";
			case "GENERAL_CARGO": return "General cargo";
			case "HAZARDOUS_CARGO": return "Hazardous cargo";
			case "HEAVY_LOAD": return "Heavy load";
			case "LIQUID_CARGO": return "Liquid cargo";
			case "OUT_OF_GAUGE": return "Out of gauge";
			case "PALLETIZED": return "Palletized cargo";
			case "ROLL_ON_ROLL_OFF": return "Roll on, roll off";
			case "TEMPERATURE_CONTROLLED_CARGO": return "Temperature controlled cargo";
			case "VEHICLES": return "Vehicles";
			case "BREAKBULK_GENERAL_CARGO": return "Breakbulk or general cargo";
			case "BULK_DRY": return "Bulk dry cargo";
			case "BULK_DRY_FUMIGATED": return "Bulk dry fumigated cargo";
			case "PROJECT_CARGO": return "Project cargo";
			case "BULK_LIQUID_GAS": return "Bulk liquid or gas";
			case "RORO_VEHICLES": return "Roll on/roll off or vehicles";
			case "PASSENGERS": return "Passengers";
			default: throw Error('Unknown cargo value: ' + value);
		}
	};
	searchPilotExemptionHolder = term => sendQuery("com.portbase.bezoekschip.common.api.visit.FindPilotExemptions",
		{term: term, portUnCode: this.context.visit.portOfCall.port.locationUnCode});

	pilotExemptionInputFormatter = (pilotExemption: PilotExemption) => pilotExemption ? pilotExemption.holderName + ' - ' + pilotExemption.number : '';

	movement : LocalMovement;
	@Input() id;
	@Input() required: boolean;

	get value(): LocalMovement {
		return this.movement;
	}

	writeValue(value: LocalMovement): void {
		this.movement = value;
	}

	get isFirstMovement() : boolean {
		return VisitContext.isFirstMovement(this.movement);
	}

	get isLastMovement() : boolean {
    return VisitContext.isLastMovement(this.movement);
	}

  get berthVisitInfo(): BerthVisitInfo {
    const departFromBerthVisitId = this.getDepartFromBerthVisit()?.id;

    return !departFromBerthVisitId ? null : this.context.visit.berthVisitInfos[departFromBerthVisitId];
  }

  get harbourMasterRemarks(): String {
    return this.berthVisitInfo?.harbourMasterInfo?.remarks?.text;
  }

	get hasActiveOrder() : boolean {
    const indexOfDepartFromBerthVisit = this.context.visit.visitDeclaration.portVisit.berthVisits.indexOf(this.getDepartFromBerthVisit());
		const arriveAtBerthVisit = this.context.visit.visitDeclaration.portVisit.berthVisits[indexOfDepartFromBerthVisit + 1];
		return arriveAtBerthVisit ? this.movement.order && !arriveAtBerthVisit.ata : this.movement.order;
	}

  getPreviousBerthVisitId() :string {
    const id = this.context.visit.visitDeclaration.portVisit.berthVisits.find(value => value.nextMovement === this.movement)?.id;
    return id;
  }

  getNextBerthVisitId() :string {
    const currentBerthVisit =
      this.context.visit.visitDeclaration.portVisit.berthVisits.find(value => value.nextMovement === this.movement);
    const indexOfNextBerthVisit = this.context.visit.visitDeclaration.portVisit.berthVisits.indexOf(currentBerthVisit);
    const id = this.context.visit.visitDeclaration.portVisit.berthVisits[indexOfNextBerthVisit + 1]?.id;
    return id;
  }

  private getDepartFromBerthVisit() {
    return this.context.visit.visitDeclaration.portVisit.berthVisits.find(value => value.nextMovement === this.movement);
  }

  ngOnInit(): void {
		if (!this.id) {
			throw new Error('Attribute "id" is required for app-movement component');
		}
	}

	private preferencesEmailAddress: string;
  private preferencesSmsNumber: string;
  ngAfterViewInit(): void {
    sendQuery("com.portbase.bezoekschip.common.api.authorisation.GetCurrentUserPreferences", <GetCurrentUserPreferences>{})
      .subscribe(result => {
        this.preferencesEmailAddress = (<UserPreferences>result).orderEmail;
        this.preferencesSmsNumber = (<UserPreferences>result).orderSms;
      });
  }

	get emailAddressFromPreferences(): string {
    return this.preferencesEmailAddress;
  }

  get smsNumberFromPreferences(): string {
    return this.preferencesSmsNumber;
  }

	onVesselDraftChanged() {
		if(this.isFirstMovement && this.context.visit.orderIncomingMovement){
			this.context.updateCalculatedEtas();
		}
	}

	onBeamValueChange($event: any) {
		if (!$event) {
			this.movement.offStandardBeam = null;
		} else {
			this.movement.offStandardBeam = {
				value: $event,
				remarks: this.movement.offStandardBeam ? this.movement.offStandardBeam.remarks : ""
			};
		}
	}

	vesselDraftTooHigh() {
		return this.movement.vesselDraft && this.movement.vesselDraft >= 100;
	}
}
