<div>
  <div class="declaration-block my-4">
    <h2>Transit documents</h2>
  </div>
</div>

<div *ngFor="let document of item.producedDocuments" class="my-2">
  <app-collapse [collapse]="placementTemplate" [showOnLoad]="!document.documentNumber"
                [deleteHandle]="isEditable ? removeItem(document) : null" [validationModel]="document">
    <div class="row pe-3 g-0 align-items-center">
      <div class="col-auto mx-2 p-2">
        <span class="fa fa-fw fa-file-alt text-dark"></span>
      </div>
      <div class="col text-dark">
        <span class="d-inline-block align-top text-truncate">{{document.type?.description || 'New document'}}</span>
      </div>
      <div class="col text-dark">
        <span class="d-inline-block align-top text-truncate">{{document.documentNumber || ''}}</span>
      </div>
    </div>
  </app-collapse>

  <ng-template #placementTemplate>
    <div class="collapse">
      <div class="mx-3 pt-3">
        <div class="row">
          <div class="col-md">
            <app-form-group label="Type">
              <app-select [(ngModel)]="document.type" dataKey="code" placeholder="– Select Document type –"
                          [optionsProvider]="refData.getTransitProducedDocumentTypes"
                          [formatter]="refData.transitDocumentTypesFormatter" required>
              </app-select>
            </app-form-group>
            <app-form-group label="Document number">
              <input required [(ngModel)]="document.documentNumber" class="form-control"  maxlength="35">
            </app-form-group>
          </div>
          <div class="col-md">
            <app-form-group label="Description">
              <textarea [(ngModel)]="document.description" class="form-control" style="min-height: 5.75rem;"
                        maxlength="26"></textarea>
            </app-form-group>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<div *ngIf="isEditable" class="row align-items-center">
  <div class="col-auto ms-auto">
    <ul class="nav">
      <li class="nav-item">
        <a href="#" class="nav-link p-0" (click)="$event.preventDefault(); addItem()">
          <span class="pe-2 fa fa-plus-circle"></span>Add transit document</a>
      </li>
    </ul>
  </div>
</div>
